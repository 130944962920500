var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "focus" },
    [
      Object.keys(_vm.TreeSelect.data).length > 0
        ? _c("treeSelect", {
            attrs: { keywords: [], parent: "ana" },
            on: { search: _vm.pushFilterCondition },
            model: {
              value: _vm.TreeSelect,
              callback: function ($$v) {
                _vm.TreeSelect = $$v
              },
              expression: "TreeSelect",
            },
          })
        : _vm._e(),
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: "选择争议焦点", fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: { click: _vm.back },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
          _c(
            "div",
            { attrs: { slot: "right" }, slot: "right" },
            [
              _c(
                "van-button",
                {
                  attrs: {
                    type: "info",
                    disabled: _vm.selectList.length > 0 ? false : true,
                    size: "mini",
                  },
                  on: { click: _vm.openFile },
                },
                [_vm._v("下一步编写代理词")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", [
        _c("div", { staticClass: "focus-header" }, [
          _c(
            "div",
            { staticStyle: { display: "flex", "align-items": "center" } },
            [
              _c("div", { staticClass: "cell-checkbox" }, [
                _vm.selectList.length > 0 && _vm.selectList.length < _vm.len
                  ? _c("i", {
                      staticClass: "iconfont icon-minus",
                      on: {
                        click: function ($event) {
                          return _vm.checkedAll(false)
                        },
                      },
                    })
                  : _vm._e(),
                _vm.selectList.length > 0 && _vm.selectList.length === _vm.len
                  ? _c("i", {
                      staticClass: "iconfont icon-check-2",
                      on: {
                        click: function ($event) {
                          return _vm.checkedAll(false)
                        },
                      },
                    })
                  : _vm._e(),
                _vm.selectList.length === 0
                  ? _c("span", {
                      staticClass: "span",
                      on: {
                        click: function ($event) {
                          return _vm.checkedAll(true)
                        },
                      },
                    })
                  : _vm._e(),
              ]),
              _c("span", { staticStyle: { "margin-left": "10px" } }, [
                _vm._v("已选" + _vm._s(_vm.selectList.length)),
              ]),
            ]
          ),
          _c(
            "div",
            { staticStyle: { display: "flex", "align-items": "center" } },
            [
              _c("div", { staticClass: "keyword-input-box" }, [
                _c("i", { staticClass: "van-icon van-icon-search" }),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.keyword,
                      expression: "keyword",
                    },
                  ],
                  attrs: { type: "text", placeholder: "搜索" },
                  domProps: { value: _vm.keyword },
                  on: {
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.keyword = $event.target.value
                      },
                      _vm.keywordChange,
                    ],
                  },
                }),
              ]),
              _c("i", {
                staticClass: "iconfont icon-shaixuan21",
                on: { click: _vm.showTreeSelect },
              }),
            ]
          ),
        ]),
        _c("div", { staticClass: "focus-body" }, [
          _c(
            "div",
            { staticClass: "focus-list" },
            _vm._l(_vm.list, function (item, index) {
              return _c(
                "div",
                { key: index },
                _vm._l(item.case, function (childItem, i) {
                  return _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: childItem.show,
                          expression: "childItem.show",
                        },
                      ],
                      key: i,
                      staticClass: "focus-box",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "focus-box-checkbox" },
                        [
                          _c("van-checkbox", {
                            attrs: { shape: "square" },
                            on: {
                              click: function ($event) {
                                return _vm.chooseItem(index, i)
                              },
                            },
                            model: {
                              value: childItem.flag,
                              callback: function ($$v) {
                                _vm.$set(childItem, "flag", $$v)
                              },
                              expression: "childItem.flag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "focus-box-content" }, [
                        _c(
                          "div",
                          {
                            staticClass: "focus-box-content_ellipsis",
                            on: {
                              click: function ($event) {
                                return _vm.openAll(index, i)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(childItem.text) + " ")]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: childItem.isAll,
                                expression: "childItem.isAll",
                              },
                            ],
                            staticClass: "focus-box-content_all",
                          },
                          [
                            _vm._v(" " + _vm._s(childItem.text) + " "),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "margin-left": "10px",
                                  color: "#1989fa",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.closeAll(index, i)
                                  },
                                },
                              },
                              [_vm._v("收起")]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  )
                }),
                0
              )
            }),
            0
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }