var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "litigation", staticClass: "litigation" },
    [
      _vm.status === 1
        ? _c(
            "div",
            { staticClass: "time" },
            [
              _c("div", { staticClass: "type" }, [_vm._v("时间")]),
              _vm.litigationdata && _vm.litigationdata.datas
                ? _c("ve-histogram", {
                    attrs: {
                      width: _vm.width,
                      height: _vm.height,
                      series: _vm.test.series,
                      xAxis: _vm.test.xAxis,
                      yAxis: _vm.test.yAxis,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.status === 1
        ? _c("div", { staticClass: "type" }, [_vm._v("年度")])
        : _vm._e(),
      _vm.litigationdata && _vm.litigationdata.datas
        ? _c("ve-histogram", {
            attrs: {
              width: _vm.width,
              height: _vm.height,
              series: _vm.test2.series,
              xAxis: _vm.test2.xAxis,
              yAxis: _vm.test2.yAxis,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }