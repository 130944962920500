<template>
  <div class="law-item-comp">
    <div class="law-card">
      <div class="law-card__cell">
        <div class="cell-checkbox" :class="{ active: hasChildSelect || meSelect }" @click="select">
          <i :class="iconString"></i>
        </div>
        <div class="cell-title van-ellipsis-3" @click="handleLawTitleClick">
          <!-- 点击判例列表 -->
          {{ card.name }}
        </div>
        <div class="cell-pr" @click="jumpToList(1, '')">
          {{ card.percent }}
        </div>
      </div>
      <div v-if="hasLawItem">
        <div v-for="(item, num) in lawChildren" :key="num">
          <law-item :law="item" :index="num" :parentIndex="index" @select="handleChildSelect" @toLawDetail="handleChildToLawDetail" @jumpToList="jumpToList" @more="more" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import LawItem from './lawItem'
export default {
  name: 'law-card',
  props: {
    filterType: String,
    card: Object,
    index: Number,
    selectLawIndex: [Number, Boolean],
    selectActionHash: Number,
    lawKey: String,
    currentReportArr: Array,
  },
  components: {
    LawItem,
  },
  data() {
    return {
      lawChildren: [],
      selection: [],
      meSelect: false,
    }
  },
  computed: {
    ...mapState('searchReport', ['anaReports']),
    hasLawItem() {
      const flag = this.filterType === '全部' || this.lawKey === 'entity'
      return flag
    },
    noChildren() {
      return this.lawChildren.length === 0
    },
    // 法律最外层选择状态
    selectState() {
      if (this.allChildSelect) {
        return 'all'
      } else if (this.hasChildSelect) {
        return 'some'
      } else {
        return 'none'
      }
    },
    // 是否有子级选中
    hasChildSelect() {
      let s = false
      this.lawChildren.forEach((item) => {
        if (item.checked) {
          s = true
        }
      })
      return s
    },
    // 所有子级都选中
    allChildSelect() {
      let s = true
      if (this.noChildren) {
        return false
      } else {
        this.lawChildren.forEach((item) => {
          if (!item.checked) {
            s = false
          }
        })
        return s
      }
    },
    // 图标样式
    iconString() {
      if (this.allChildSelect) {
        return 'iconfont icon-check-2 active'
      } else if (this.hasChildSelect) {
        return 'iconfont icon-minus active'
      } else {
        return 'iconfont icon-check-2'
      }
    },
    selectedChildList() {
      return this.lawChildren.filter((item) => item.checked)
    },
  },
  methods: {
    ...mapActions('searchReport', ['setStampReport']),
    more(relevancyKey, flag) {
      console.log('relevancyKey, flag', relevancyKey, flag)
      this.lawChildren.forEach((item) => {
        if (item.relevancyKey === relevancyKey) {
          item.show = flag
        }
      })
    },
    //  初始化子级选中状态 - 回显数据
    initState(select) {
      this.meSelect = false
      this.clearSelect()
      const currentLawId = this.card.law_id
      this.currentReportArr.forEach((item) => {
        if (item.lawid === currentLawId) {
          item.anaArr.forEach((ite) => {
            this.lawChildren.forEach((v) => {
              if (v.title === ite.title) {
                v.checked = true
              }
            })
          })
        }
        // if (item.plawId === currentLawId) {
        //   console.log(item, 'plawId')
        //   item.anaArr.forEach(ite => {
        //     this.lawChildren.forEach(v => {
        //       if (v.title2 === ite.title) {
        //         v.checked = true
        //       }
        //     })
        //   })
        // }
      })
    },
    // 选中
    select() {
      console.log(this.card, this.selectState, this.lawChildren, '选中 card')
      if (this.noChildren) {
        this.meSelect = !this.meSelect
      } else {
        if (this.selectState === 'all') {
          this.clearSelect()
          this.checkAllReport(false)
          return
        } else {
          this.selectAll()
          this.checkAllReport(true)
        }
      }
      // this.handleChildSelect()
      this.$nextTick(() => {
        this.emitSelect()
      })
    },
    // 向父组件传递选择事件
    emitSelect() {
      const checked = this.noChildren ? this.meSelect : !!this.selectedChildList.length
      const payload = {
        ...this.card,
        index: this.index,
        checked,
        selectedChildren: this.selectedChildList,
      }
      this.$emit('select', payload)
    },
    // 清除所有选中
    clearSelect() {
      this.makeSelect(false)
    },
    checkAllReport(flag) {
      const otherArr = this.anaReports.filter((v) => v.type !== 'law')
      this.lawArr = JSON.parse(JSON.stringify(this.anaReports.filter((v) => v.type === 'law')))
      this.lawChildren.forEach((item) => {
        this.dealSigleCheck(item, flag, 'all')
      })
      this.lawArr = this.lawArr.filter((v) => v.anaArr.length > 0)
      console.log(this.lawArr)
      this.setStampReport({
        userId: this.$store.state.user.userInfo.id,
        type: 'anaReports',
        reportData: [...otherArr, ...this.lawArr],
      })
    },
    // 选中所有
    selectAll() {
      this.makeSelect(true)
    },
    makeSelect(select = false) {
      if (this.filterType === '筛选有效法条') {
        // this.lawChildren = this.card.data2.map(item => {
        //   return {
        //     ...item,
        //     checked: select,
        //     pid: this.index
        //   }
        // })
        const list = []
        this.card.data2.forEach((item, index) => {
          // 数据拍平
          list.push({
            ...item,
            checked: select,
            pid: this.index,
            showOldTag: item.child_list && item.child_list.length,
          })
          if (item.child_list) {
            item.child_list.forEach((item1) => {
              if (item1.child) {
                item1.child.forEach((item2) => {
                  let title = item1.law_title
                  if (item2.title) {
                    title = title + item2.title
                  }
                  var obj = {
                    law_id: item1.law_id,
                    title: title,
                    plawId: item.law_id,
                    lawTitle: item1.law_title,
                    title2: item2.title,
                    text: item2.text ? item2.text : '',
                    colorType: true,
                    checked: select,
                    pid: this.index,
                  }
                  list.push(obj)
                })
              }
            })
          }
          if (item.law_relevancy) {
            const key = index + '_' + 'relevancy'
            let len = 0
            item.law_relevancy.forEach((item1) => {
              if (item1.child) {
                item1.child.forEach((item2) => {
                  len++
                })
              }
            })
            let i = 0
            item.law_relevancy.forEach((item1) => {
              if (item1.child) {
                item1.child.forEach((item2) => {
                  let title = '关联' + ' ' + item1.law_title
                  if (item2.title) {
                    title = title + item2.title
                  }
                  var obj = {
                    law_id: item1.law_id,
                    plawId: item1.law_id,
                    lawTitle: item1.law_title,
                    title: title,
                    title2: item2.title,
                    text: item2.text ? item2.text : '',
                    colorType: true,
                    show: false,
                    relevancyKey: key,
                    len: len,
                    i: i,
                    checked: select,
                    pid: this.index,
                  }
                  list.push(obj)
                  i++
                })
              }
            })
          }
        })
        this.lawChildren = list
      } else if (this.filterType === '全部') {
        const list = []
        this.card.data.forEach((item, index) => {
          // 数据拍平
          list.push({
            ...item,
            checked: select,
            pid: this.index,
            showOldTag: item.child_list && item.child_list.length,
          })
          if (item.child_list) {
            item.child_list.forEach((item1) => {
              if (item1.child) {
                item1.child.forEach((item2) => {
                  let title = item1.law_title
                  if (item2.title) {
                    title = title + item2.title
                  }
                  var obj = {
                    law_id: item1.law_id,
                    title: title,
                    plawId: item.law_id,
                    lawTitle: item1.law_title,
                    text: item2.text ? item2.text : '',
                    colorType: true,
                    checked: select,
                    pid: this.index,
                  }
                  list.push(obj)
                })
              }
            })
          }
          if (item.law_relevancy) {
            const key = index + '_' + 'relevancy'
            let len = 0
            item.law_relevancy.forEach((item1) => {
              if (item1.child) {
                item1.child.forEach((item2) => {
                  len++
                })
              }
            })
            let i = 0
            item.law_relevancy.forEach((item1) => {
              if (item1.child) {
                item1.child.forEach((item2) => {
                  let title = '关联' + ' ' + item1.law_title
                  if (item2.title) {
                    title = title + item2.title
                  }
                  var obj = {
                    law_id: item1.law_id,
                    plawId: item.law_id,
                    lawTitle: item1.law_title,
                    title: title,
                    text: item2.text ? item2.text : '',
                    colorType: true,
                    show: false,
                    relevancyKey: key,
                    len: len,
                    i: i,
                    checked: select,
                    pid: this.index,
                  }
                  list.push(obj)
                  i++
                })
              }
            })
          }
        })
        console.log(this.card.data, list, '-----------data--------')
        this.lawChildren = list
      }
      this.$nextTick(() => {
        this.emitSelect()
      })
    },
    // 是否选中
    isSelect(index) {
      return this.selectedIndex === index
    },
    // 跳转到列表页
    jumpToList(offset = 1, title) {
      const payload = {
        offset,
        text: this.card.name + title,
        lawTitle: this.card.name,
        lawText: title,
      }
      this.$emit('jumpToList', payload)
    },
    // 子级 跳转法律详情页
    handleChildToLawDetail(payload) {
      this.$emit('toLawDetail', {
        ...payload,
        catlog: `《${this.card.name}》${payload.title}`,
        topic: this.card.name,
      })
    },
    // 处理单个选择
    dealSigleCheck(item, flag, type) {
      const { text, law_id: lawid, title2, lawTitle, title, colorType } = item
      const currentObj = {
        type: 'law',
        anaArr: [],
        lawid: colorType ? lawid : this.card.law_id,
        id: `law-${lawid || this.card.law_id}`,
        title: colorType ? lawTitle : this.card.name,
      }
      const anaObj = { name: title2 || title, text, title: title2 || title }

      const lawIndex = this.lawArr.findIndex((v) => v.lawid === currentObj.lawid)
      if (lawIndex === -1) {
        if (flag) {
          currentObj.anaArr.push(anaObj)
          this.lawArr.push(currentObj)
        }
      } else {
        // const oldObj = this.lawArr[lawIndex]
        // const oldAnaArr = this.lawArr[lawIndex].anaArr
        const innerIndex = this.lawArr[lawIndex].anaArr.findIndex((v) => v.name === anaObj.name)
        if (innerIndex !== -1) {
          if (!flag) {
            this.lawArr[lawIndex].anaArr.splice(innerIndex, 1)
          } else {
            if (!type) {
              this.$notify('您已经收集过相同的内容')
            } else {
              console.log('您收集的内容有重复的，已为您去重')
            }
          }
        } else {
          if (flag) {
            this.lawArr[lawIndex].anaArr.push(anaObj)
          }
        }
      }
    },
    // 子级选择事件
    handleChildSelect(payload) {
      console.log('子级选择事件', payload, this.lawChildren, this.card)
      const flag = payload.checked
      if (payload) {
        const { index } = payload
        this.lawChildren = this.lawChildren.map((law, i) => {
          if (i === index) {
            const checked = !law.checked
            return {
              ...law,
              checked,
            }
          } else {
            return law
          }
        })
      }
      const otherArr = this.anaReports.filter((v) => v.type !== 'law')
      this.lawArr = JSON.parse(JSON.stringify(this.anaReports.filter((v) => v.type === 'law')))
      this.dealSigleCheck(this.lawChildren[payload.index], flag)
      this.lawArr = this.lawArr.filter((v) => v.anaArr.length > 0)
      this.setStampReport({
        userId: this.$store.state.user.userInfo.id,
        type: 'anaReports',
        reportData: [...otherArr, ...this.lawArr],
      })
      this.emitSelect()
    },
    // 法条是否选中
    isChecked(index) {
      return this.selection[index]
    },
    // 标题点击事件
    onClickTitle() {
      this.$emit('toLaw', this.card)
    },
    // 没有子级的数据 点击时功能相当于点击子级的百分比
    handleLawTitleClick() {
      // if (this.noChildren) {
      //   const payload = {
      //     offset: 1,
      //     text: this.card.name,
      //     lawTitle: this.card.name,
      //     lawText: ''
      //   }
      //   this.$emit('jumpToList', payload)
      // }
      const payload = { id: this.card.law_id, title: '', topic: this.card.name }
      this.$emit('toLawDetail', payload)
    },
  },
  created() {
    setTimeout(() => {
      console.log('card', this.filterType, this.card.timeliness)
    })
  },
  mounted() {
    this.initState()
  },
  watch: {
    filterType(val) {
      this.initState()
    },
    card(nv) {
      this.initState()
    },
    // 监听选中hash以清空选中状态
    selectActionHash(nv) {
      if (nv) {
        this.clearSelect()
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.law-item-comp
  position relative
  margin-top 10px
  padding 10px
  border-radius 4px
  margin-bottom 10px
  border 1px solid #ebeef5
  background-color #fff
  transition 0.3s
  box-shadow 0 2px 8px 0 rgba(0, 0, 0, 0.1)
  overflow hidden
  & .law-card
    color #303133
    & .law-card__cell
      position relative
      display flex
      align-items center
      justify-content baseline
      & .cell-title
        font-weight bold
        flex 1
        font-size 13px
      & .cell-checkbox
        margin-right 6px
        background #ffffff
        border-radius 2px
        border 1px solid #eeeeee
        color #ffffff
        width 18px
        height 18px
        display inline-block
        text-align center
        &.active
          background #409eff
          border 1px solid #409eff
        & i
          font-size 14px
      & .cell-pr
        font-size 12px
</style>
