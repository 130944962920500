<template>
  <div class="spend">
    <div class="close">
      <div @click.stop="close()" class="close_button">x</div>
    </div>
    <div class="spend_content">
      <div>当前使用的手机号为****{{ phoneId }},并不是会员</div>
      <van-button type="warning" class="spend_IOS" round v-show="buttonFlag">请打开电脑端输入网址tool.farbun.com进行充值</van-button>
      <van-button v-show="!buttonFlag" type="warning" class="spend_button" round @click="account()">成为会员</van-button>
    </div>
    <div class="spend_footer" @click="PhoneDian(servicePhone)">
      <span>联系客服:</span><span>客服 :{{ servicePhone }}</span>
      <van-icon name="phone" class="phone_icon" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'spendDialog',
  created() {
    if (!sessionStorage.getItem('thirdParty')) {
      this.getUserInfo()
      this.getUserKeFu()
    }
  },
  data() {
    return {
      phoneId: '',
      servicePhone: '',
      buttonFlag: false,
      show: false,
    }
  },
  computed: {
    ...mapState('user', ['userInfo', 'userinfo']),
    ...mapState('nonmemberLimit', ['inviteList', 'promotionRecordId', 'hasGetGroupinfo', 'groupInfo', 'miniQRCode', 'exclusive']),
  },
  mounted() {},
  methods: {
    close() {
      this.$emit('closeDialog')
    },
    PhoneDian(phone) {
      this.$dialog
        .alert({
          title: '是否需要拨打电话？',
          message: `<h1 style="color:#4B9EFB;font-size:18px">${phone}</h1>`,
          confirmButtonText: '拨打电话',
          closeOnClickOverlay: true,
        })
        .then(() => {
          try {
            const u = navigator.userAgent
            if (u === 'fb-Android-Webview') {
              // eslint-disable-next-line
              AND2JS.call(phone)
            } else if (u === 'fb-iOS-Webview') {
              // eslint-disable-next-line
              window.webkit.messageHandlers.backToTool.postMessage({
                takePhoneNumber: phone,
              })
            } else if (u === 'fb-flutter-Webview') {
              // eslint-disable-next-line
              try {
                window.backToTool.postMessage(JSON.stringify({ takePhoneNumber: phone }))
              } catch (error) {
                console.log('backToTool.postMessage', error)
              }
            } else {
              this.$toast('非原生环境,返回原生指定页')
            }
          } catch (err) {
            // console.log(err)
          }
        })
    },
    account() {
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        // eslint-disable-next-line
        AND2JS.toVipRechargeView()
      } else if (ua === 'fb-flutter-Webview') {
        // eslint-disable-next-line
        console.log('ua...flutter')
        window.toVipRechargeView.postMessage('{}')
      } else {
        this.$notify('请在律呗电脑端进行购买')
      }
    },
    async getUserInfo() {
      this.$axios.get(`${this.$base}/management/users/status/${this.userInfo.id}`).then(({ data: res }) => {
        if (res.code === 200) {
          // window.console.log('send data', res)
          this.$store.commit('user/setUserinfo', res.data)
          this.initDialog()
        }
      })
    },
    initDialog() {
      this.phoneId = this.userinfo.account.substr(this.userinfo.account.length - 4)
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        this.buttonFlag = false
      } else if (ua === this.$ua.i) {
        this.buttonFlag = true
      }
      // window.console.log('dialog', this.userinfo.memberStatus)
      if (this.userinfo.memberStatus !== 1) {
        this.show = true
      } else {
        this.show = false
      }
    },
    async getUserKeFu() {
      this.$axios.post(`${this.$base}/fission/customerService/queryExclusive`).then(({ data: res }) => {
        if (res.code === '200') {
          this.servicePhone = res.data.phone
        }
      })
    },
  },
}
</script>

<style scoped>
.spend {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
}
.close {
  height: 20%;
  font-size: 30px;
  padding-right: 20px;
}
.close_button {
  text-align: center;
  width: 30px;
  float: right;
}
.spend_button {
  width: 100%;
  border-radius: 3%;
  margin-top: 15%;
  color: #fff;
  background-color: #ff9900;
}
.spend_content {
  height: 60%;
  padding: 0 20%;
}
.spend_footer {
  height: 20%;
  padding: 0 20%;
}
.spend_footer :nth-child(1) {
  margin-right: 10px;
}
.spend_IOS {
  width: 100%;
  border-radius: 3%;
  margin-top: 15%;
  color: #ff9900;
  background-color: #fff;
}
.van-icon-phone::before {
  color: palegreen;
}
</style>
