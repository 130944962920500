<template>
  <div class="analysis" :style="inScrollMenu ? 'padding-top:0px' : ''">
    <!-- 回退 -->
    <div class="back" v-if="!inScrollMenu && !isIOS">
      <i class="iconfont icon-back" @click="backToSearch"></i>
    </div>
    <!-- 头部 -->
    <div class="ana-header" v-if="!inScrollMenu" ref="header" id="header">
      <div class="text-caontainer">
        <van-field
          id="textareaId"
          ref="focusTextarea"
          class="text"
          style="height: 100px"
          placeholder="请详细描述你的基本案情，可以使用完整的句子，也可以使用一个或多个词语。"
          v-model="analysisData.textData"
          type="textarea"
          autosize
          :border="false"
        />
        <!-- <textarea
          id="textareaId"
          ref="focusTextarea"
          class="text"
          style="height: 100px"
          placeholder="请详细描述你的基本案情，可以使用完整的句子，也可以使用一个或多个词语。"
          @input="textInput"
          v-model="analysisData.textData"
        >
        </textarea> -->
        <!-- 点击调用接口          图标修改 -->
        <!-- <div class="xiangji"
             style="float:left;"
             v-show="hid">
          <i class="iconfont icon-iconfontyuyin2"
             @click="openCaseVoice"></i>
        </div> -->
        <div class="xiangji" style="text-align: right">
          <span class="numTotal">{{ analysisData.textData.length }}字</span>
          <van-button size="mini" class="buttonDefault" v-show="analysisData.textData.trim().length > 0" @click="emptyData" type="default">清空</van-button>
          <i @click="openCaseCamera" v-show="!analysisData.textData.trim()" class="iconfont icon-xiangji1"></i>
          <i @click="openFile" v-show="!analysisData.textData.trim()" class="iconfont icon-wordpdf" style="margin-left: 10px"></i>
          <!-- class="iconfont icon-wordpdf" -->
        </div>
      </div>
      <div class="button-container">
        <!-- <span class="analysis-button"
              @click="texAanalysis">诉讼策略分析</span> -->
        <van-button class="analysis-button" :disabled="loading" @click="texAanalysisTwo" type="default"
          >案情分析机器人<span v-if="userInfo && userInfo.memberStatus !== 1 && memberNum !== -1 && isWechat == false">（剩余{{ memberNum || 0 }}次）</span></van-button
        >
      </div>
    </div>
    <div v-if="!isAnalysisIng">
      <div class="history_title">历史记录 <van-icon name="delete-o" @click="deleteTheme(true, '')" /></div>
      <div>
        <!-- <div style="height: 700px; overflow: auto"> -->
        <!-- <van-list v-model="historyLoading" :finished="finished" finished-text="没有更多了" @load="onLoad"> -->
        <van-list>
          <van-cell v-for="(item, index) in themeList" :key="index">
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title>
              <span class="custom-title">{{ item.date }}</span>
              <van-icon name="delete-o" @click="deleteTheme(false, item.text_token)" />
            </template>
            <!-- 使用 right-icon 插槽来自定义右侧图标 -->
            <template #right-icon>
              <div class="re_analysis" @click="reAnalysis(item.text)">重新分析</div>
            </template>
            <template #label> {{ item.text }} </template>
          </van-cell>
        </van-list>
      </div>
    </div>
    <div style="text-align: center" v-if="showRefresh" @click="texAanalysisTwo">加载超时，<span style="color: #409eff">点击刷新</span>。</div>
    <!-- 展示内容 -->
    <div class="container" ref="container" style="position: relative">
      <fb-member :left="-15" v-if="false" />
      <!-- one 图表    -->
      <one
        v-show="step === 1"
        ref="one"
        :memberNum="memberNum"
        :analysisData="analysisData"
        :showIndex.sync="oneShowIndex"
        @caseCellClick="caseCellClick"
        :pageInfo="pageInfo"
        :token="analysisData.token"
        :inScrollMenu="inScrollMenu"
        :scrollDocument="scrollDocument"
        :is_type="is_type"
        :isWechat="isWechat"
        @emptyData="emptyData"
        @resetFinishedText="resetFinishedText"
        @showHeader="showHeader"
        @changeIsType="changeIsType"
      />
      <!-- two 内容 -->
      <two
        v-if="step === 2"
        ref="two"
        :is_type="is_type"
        :analysisData="analysisData"
        :selectedIndex.sync="oneSelectedIndex"
        :tabData="analysisData.tabData"
        :updataTitleIndex="updataTitleIndex"
        :showIndex.sync="twoShowIndex"
        @resetScrollDocument="resetScrollDocument"
        @resetLoading="resetLoading"
        @resetFinished="$emit('resetFinished', false)"
        :pageInfo="pageInfo"
        :inScrollMenu="inScrollMenu"
        :scrollDocument="scrollDocument"
        :causeName="causeName"
        @sliderSide="sliderSide"
        @showHeader="showHeader"
        @show="isShow = true"
        :fileId="fileId"
        :memberNum="memberNum"
      />
    </div>
    <!-- 遮罩层 -->
    <myWarp v-if="userinfo.id" v-show="isShow && userinfo && userinfo.memberStatus !== 1 && memberNum < 0" @closeDialog="isShow = false" />
  </div>
</template>
<script>
/* eslint-disable */
import { mapState, mapMutations } from 'vuex'

import { pydataBase, qiniuBase } from '~api-config'
import FbMember from '@/components/fb-member'
import myWarp from './components/myWarp.vue'
// import qs from 'qs'
import one from './steps/one'
import two from './steps/two'
import { slider } from './steps/slider'
export default {
  name: 'analysis',
  components: {
    one,
    two,
    FbMember,
    myWarp,
  },
  // step用来记录操作位置
  props: ['step', 'scrollDocument', 'pageInfo', 'inScrollMenu'],
  data() {
    return {
      isShow: false,
      memberNum: -1,
      showRefresh: false,
      loading: false,
      hid: false,
      analysisData: {
        //  数据
        textData: '',
        caseList: [], // 案例数据
        token: '',
        tabData: [],
      },
      causeName: '',
      oneShowIndex: 0, //
      oneSelectedIndex: null,
      twoShowIndex: 4,
      // 高度
      headerHeight: 0,
      keyWordData: null, // keword传的参数暂存
      updataTitleIndex: 0, // 修改子组件titleindex下标
      isIOS: false,
      fileId: null,
      is_type: 0,
      pageChangeCaseData: [], // 页面上对应的changeCaseData
      themeList: [],
      historyLoading: false,
      finished: false,
      isAnalysisIng: false, // 是否正在分析中
      isWechat: false, // 是否来自小程序
    }
  },
  computed: {
    ...mapState('editor', ['time', 'anaText']),
    //  输入框值 semantic数据 筛选选中 关键字数据
    ...mapState('ana', ['changeCaseData', 'semanticData', 'anaTwoKeyDown']),
    ...mapState('user', ['userInfo', 'userinfo']),
    inputText() {
      return this.analysisData.textData
    },
  },
  methods: {
    ...mapMutations('ana', ['SET_CHANGECASEDATA', 'SET_SEMANTICDATA', 'SET_TEXT']),
    // 改变案件类型
    changeIsType(isType) {
      this.is_type = isType
      this.texAanalysisTwo()
    },
    calcArray(array) {
      return array.filter((element, index) => {
        return index < this.oneShowIndex
      })
    },
    backToSearch() {
      // eslint-disable-next-line no-undef
      const ua = navigator.userAgent
      console.log(ua)
      if (ua === 'fb-Android-Webview') {
        // eslint-disable-next-line
        AND2JS.back()
      } else if (ua === 'fb-iOS-Webview') {
        window.webkit.messageHandlers.back.postMessage({})
        window.webkit.messageHandlers.dismissVicoce.postMessage({})
        // eslint-disable-next-line
      } else if (ua === 'fb-flutter-Webview') {
        try {
          window.back.postMessage('{}')
          window.dismissVicoce.postMessage('{}')
        } catch (error) {
          console.log(error, 'back.postMessage', 'dismissVicoce.postMessage')
        }
      } else if (ua.includes('miniProgram')) {
        const ind = this.$route.fullPath.indexOf('url=')
        if (ind != -1) {
          wx.miniProgram.redirectTo({
            url: decodeURIComponent(this.$route.fullPath.substring(ind + 4).replace('~', 'data')),
          })
        } else if (ind == -1) {
          wx.miniProgram.switchTab({
            url: '/pages/office/index',
          })
        }
      } else {
        this.$toast('非原生环境,返回原生指定页')
      }
    },
    emptyData() {
      this.analysisData.textData = ''
      this.is_type = 0

      // this.isWechat = false
    },
    // 点击textarea框关闭语音框
    // clickTextarea() {
    //   console.log(2222)
    //   this.$refs['focusTextarea'].focus()
    //   this.closeVicoce()
    // },
    // 关闭语音框
    closeVicoce() {
      const ua = navigator.userAgent
      if (ua === 'fb-Android-Webview') {
        // eslint-disable-next-line
        AND2JS.dismissVicoce()
        // AND2JS.back()
      } else if (ua === 'fb-iOS-Webview') {
        window.webkit.messageHandlers.dismissVicoce.postMessage({})
        // eslint-disable-next-line
      } else if (ua === 'fb-flutter-Webview') {
        try {
          window.dismissVicoce.postMessage('{}')
        } catch (error) {
          console.log(error, 'dismissVicoce.postMessage')
        }
      } else {
        this.$toast('非原生环境,返回原生指定页')
      }
    },
    // iOS完成聚焦问题方法  不可用
    // changfouce() {
    //   Element.prototype.trigger = function(eventName) {
    //     this.dispatchEvent(new Event(eventName))
    //   }
    //   const textareaId = document.getElementById('textareaId')
    //   textareaId.addEventListener('click', () => {
    //     textareaId.focus()
    //   })
    //   setTimeout(function() {
    //     textareaId.trigger('click')
    //   }, 100)
    // },
    // 语音切换
    ocrCallbackShwotTypeView(data) {
      console.log('ocrCallbackShwotTypeView')
      try {
        const u = navigator.userAgent
        if (u === 'fb-Android-Webview') {
          // eslint-disable-next-line
          AND2JS.showVioce()
        } else if (u === 'fb-iOS-Webview') {
          if (data) {
            // window.webkit.messageHandlers.dismissVicoce.postMessage({})
            this.$refs.focusTextarea.focus()
          } else {
            this.$refs.focusTextarea.blur()
            // window.webkit.messageHandlers.showVioce.postMessage({})
          }
          // eslint-disable-next-line
        } else if (u === 'fb-flutter-Webview') {
          if (data) {
            this.$refs.focusTextarea.focus()
          } else {
            this.$refs.focusTextarea.blur()
          }
          // eslint-disable-next-line
        } else {
          this.$toast('非原生环境,返回原生指定页')
        }
      } catch (err) {
        this.$notify(JSON.stringify(err))
        console.log(err, '组件操作')
      }
    },
    // 点击语音icon调用iOS语音方法
    openCaseVoice(cellClick) {
      try {
        const u = navigator.userAgent
        if (u === 'fb-Android-Webview') {
          // eslint-disable-next-line
          AND2JS.showVioce()
        } else if (u === 'fb-iOS-Webview') {
          this.$refs.focusTextarea.blur()
          window.webkit.messageHandlers.showVioce.postMessage({})
          // eslint-disable-next-line
        } else if (u === 'fb-flutter-Webview') {
          this.$refs.focusTextarea.blur()
          window.showVioce.postMessage('{}')
          // eslint-disable-next-line
        } else {
          this.$toast('非原生环境,返回原生指定页')
        }
      } catch (err) {
        this.$notify(JSON.stringify(err))
        console.log(err, 'showVioce.postMessage')
      }
    },
    // ocr回调  语音回调
    // keyboardType:   0正常 ，1删除键 ，2空格键 ，3换行键
    ocrCallbackVoiceInfos(data) {
      console.log('ocrCallbackVoiceInfos')
      const key = data.keyboardType
      if (key === '0') {
        this.analysisData.textData += data.voicekey
      } else if (key === '1') {
        this.analysisData.textData = this.analysisData.textData.substring(0, this.analysisData.textData.length - 1)
      } else if (key === '2') {
        this.analysisData.textData += ' '
      } else {
        this.analysisData.textData += '\n'
      }
    },
    // 上传word和pdf
    openFile() {
      const ua = navigator.userAgent
      console.log(123, ua)
      if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.getLocalOfficeFileChar()
      } else if (ua === 'fb-flutter-Webview') {
        window.getLocalOfficeFileChar.postMessage('{}')
      } else {
        if (document.getElementById('myIpt')) {
          document.getElementById('myIpt').remove()
        }
        const that = this
        console.log(document, '上传word和pdf')
        const ipt = document.createElement('input')
        console.log(ipt)
        ipt.accept = '.doc,.dot,.pdf,.docx'
        ipt.type = 'file'
        ipt.id = 'myIpt'
        document.body.appendChild(ipt)
        ipt.click()
        ipt.onchange = () => {
          this.$toast.loading({
            message: '解析中...',
            forbidClick: true,
          })
          this.analysisData.textData = ''
          const file = ipt.files[0]
          const qiniu = new this.$qiniu()
          qiniu.UploadFile(
            'DEFAULT',
            file,
            {
              next: (file) => {},
              complete: (res, file) => {},
              error: () => {
                that.$toast.clear()
                this.$notify('上传失败，请重新操作')
              },
            },
            (fileopt, key, file) => {
              console.log(fileopt, key, file)
              let fileType = 'word'
              if (fileopt.type === 'application/pdf') {
                fileType = 'pdf'
              }
              const url = 'http:' + qiniuBase + '/' + key
              this.$axios
                .post(
                  `${this.$base}/document/convertoffice/getText?url=${url}&type=${fileType}`
                  // `http://192.168.1.153:10007/document/convertoffice/getText?url=${url}&type=${fileType}`
                )
                .then((res) => {
                  that.$toast.clear()
                  if (res.data.code === '200' && res.data.data) {
                    // if (fileType === 'word') {
                    //   that.analysisData.textData = res.data.data
                    // }
                    let tempText = ''
                    if (res.data.data instanceof Array) {
                      res.data.data.map((item) => {
                        tempText = tempText + item
                      })
                    } else if (typeof res.data.data === 'string') {
                      tempText = res.data.data
                    }
                    that.analysisData.textData = tempText
                  } else {
                    this.$notify('解析失败，请重新操作')
                  }
                })
                .catch((err) => {
                  that.$toast.clear()
                  console.log(err)
                  this.$notify('解析失败，请重新操作')
                })
            }
          )
          ipt.remove()
        }
      }
    },
    officeFileCharResult(data) {
      console.log('officeFileCharResult的原生端回调===>', data)
      this.analysisData.textData = data
      console.log('analysisData', this.analysisData)
    },
    // 打开相机
    openCaseCamera() {
      // this.closeVicoce()
      window.SetText = (val) => {
        console.log('打开相机', val)
        this.analysisData.textData = val
      }
      try {
        const u = navigator.userAgent
        if (u === 'fb-Android-Webview') {
          // eslint-disable-next-line
          AND2JS.showAnalyseCamera()
        } else if (u === 'fb-iOS-Webview') {
          // window.webkit.messageHandlers.showCaseCameraV2.postMessage({})
          window.webkit.messageHandlers.showCaseCameraLIN.postMessage({})
        } else if (u === 'fb-flutter-Webview') {
          // window.webkit.messageHandlers.showCaseCameraV2.postMessage({})
          window.showCaseCameraV2.postMessage('{}')
        } else {
          this.$toast('非原生环境,返回原生指定页')
        }
      } catch (err) {
        this.$notify(JSON.stringify(err))
        console.log(err, 'showCaseCameraLIN1.postMessage')
      }
    },
    // 去重
    easyDuplicate(arr) {
      const res = []
      const obj = {}
      for (let i = 0; i < arr.length; i++) {
        if (!obj[arr[i].text]) {
          res.push(arr[i])
          obj[arr[i].text] = true
        }
      }
      return res
    },
    // ocr回调
    ocrCallback(data) {
      // this.$notify(JSON.stringify(data))
      this.analysisData.textData = data
      console.log(111, this.analysisData)
    },
    // ocr回调。
    ocrCaseResultCallback(data) {
      console.log('ocr回调0', data)
      if (data && data !== '') {
        // this.parseHTML(data.split('#!&&&!#')[0])
        // ocrFiles包含了多个id，以英文的逗号隔开。
        const tempList = data.split('#!&&&!#')[1].split(',')
        this.fileId = tempList.map((item) => {
          return parseInt(item)
        })
        console.log('ocr回调1')
      }
      // else {
      //   this.$notify('当前内容为空 无法进行解析')
      // }
      console.log('ocr回调结束')
    },
    // 减少检索次数
    reduceCount() {
      if (this.memberNum > 0) {
        this.$axios
          .post(`${this.$base}/management/user/residue/search/reduce`, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          })
          .then((res) => {
            if (res.data.code === 200 && !this.isWechat) {
              this.memberNum--
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    ...mapMutations('user', ['setUserInfo', 'setToken', 'setUserinfo']),
    // 点击检索机器人清除历史
    async texAanalysisTwo() {
      console.log('点击了检索机器人=====> userInfo ', this.userInfo)
      try {
        const res = await this.$axios.get(`${this.$base}/management/users/${this.userInfo.id}`)
        if (res.data && res.data.data && res.data.data.memberStatus.toString()) {
          this.userInfo.memberStatus = res.data.data.memberStatus
          this.setUserInfo(this.userInfo)
          console.log(res.data.data, '<<==== 获取用户信息接口中返回的数据')
        }
        this.isAnalysisIng = true
        this.reduceCount()
        if (this.userInfo && this.userInfo.memberStatus !== 1) {
          if (this.memberNum > 0) {
            this.reduceCount()
          } else {
            this.$notify('请开通会员或联系客服后再使用分析功能')
            this.$bus.$emit('fb-member', {
              dialogVisible: true,
              kind: 'fenxi',
              secKind: '',
            })
            return
          }
        } else if (this.userInfo && this.userInfo.memberStatus === 1) {
          const fbMember = document.getElementsByClassName('fb-member')[0]
          if (fbMember) {
            fbMember.style.display = 'none'
          }
        }
        if (this.analysisData.textData) {
          this.SET_CHANGECASEDATA([])
          this.texAanalysis()
        } else {
          this.$notify({
            message: '描述不能为空',
            duration: 1000,
          })
        }
      } catch (e) {
        console.warn(e)
      }
    },
    // 点击 诉讼策略分析 按钮
    async texAanalysis() {
      if (this.analysisData.textData.trim()) {
        this.showRefresh = false
        this.$notify({
          message: '分析中...',
          duration: 0,
          background: '#1989fa',
        })
        // let sentence
        // if (this.inScrollMenu) {
        //   console.log(
        //     'this.$store.state.ana.caseName',
        //     this.$store.state.ana.caseName
        //   )
        //   sentence = this.$store.state.ana.caseName
        // } else {
        //   sentence = this.analysisData.textData.trim()
        // }
        this.$store.state.ana.caseName = this.analysisData.textData.trim()
        this.$emit('resetLoading', true)
        this.$emit('resetFinished', false)
        this.loading = true
        // let res = await this.$http.post(`${pydataBase}/api/app/tree/segment`,
        //   qs.stringify({ sentence: this.analysisData.textData.trim() }), {
        //   headers: {
        //     'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        //   }
        // })
        // const keyword = res.data.result.join('x') + 'x' + this.analysisData.textData.trim()
        let sentence = ''
        if (this.inScrollMenu) {
          if (this.$store.state.caseDetail.tabAnaSummary === '') {
            sentence = this.analysisData.textData.trim()
          } else {
            console.log(this.analysisData.textData)
            if (this.analysisData.textData.split('x').length <= 1) {
              this.analysisData.textData = this.analysisData.textData.trim() + 'x' + this.$store.state.caseDetail.tabAnaSummary + 'x' + this.$store.state.caseDetail.tabAnaSuSongQingQiu
              sentence = this.analysisData.textData
            }
          }
        } else {
          sentence = this.analysisData.textData.trim()
        }
        try {
          // res = await this.$http.post(`${pydataBase}/api/app/cal_similitude`, qs.stringify({ origin: 1, keyword, 'user_id': this.$store.state.user.userInfo.id }), { timeout: 1000 * 60 })
          // let resed = await this.$http.post(
          //   `${pydataBase}/api/cal_similitude`,
          //   qs.stringify({
          //     offset: 1,
          //     sentence,
          //     user_id: this.$store.state.user.userInfo.id
          //   }),
          //   { timeout: 1000 * 60 }
          // )
          // console.log(resed)
          // if (Number(this.is_type) === 2) {
          //   this.$emit('resetLoading', false)
          //   this.$emit('resetFinished', true)
          //   this.loading = false
          //   return this.$notify({
          //     message: '行政案由暂不支持分析',
          //     background: 'red',
          //   })
          // }

          const data = {
            input_keyword: this.anaTwoKeyDown || [], // 关键字
            sentence: sentence, // 文本
            user_id: this.$store.state.user.userInfo.id,
            is_type: this.is_type,
          }
          this.$axios
            .post(`${pydataBase}/api/ai/semantic`, data)
            .then((res) => {
              console.log(res.data, '-------------- 分析数据3333333333333')
              this.$emit('resetLoading', false)
              this.$emit('resetFinished', true)
              if (res.data.result.length === 0) {
                this.restltIsNo = false
                // setTimeout(() => {
                //   this.$notify('暂无数据')
                // }, 1000)
              }
              // 修改api 处理数据结构
              // let graph = res.data.graph // 取出grph
              // console.log(graph)
              const newresult = [] // 对应老数据的result
              res.data.graph.forEach((item, index) => {
                const arr = {
                  summary_name: item.name,
                  id: item.name,
                  title: item.title,
                  datas: item.num,
                }
                newresult.push(arr)
              })
              // res.data.result = newresult
              // console.log(res)

              this.analysisData.caseList = newresult
              if (this.pageChangeCaseData && this.pageChangeCaseData.length) {
                // console.log(this.changeCaseData)
                // console.log(this.analysisData.caseList)
                // 添加的在末尾   拷贝末尾  去重 合并之后   将末尾与数据判断
                const ccd = this.pageChangeCaseData
                const presentData = ccd[ccd.length - 1] // 保存当前添加的

                // console.log('presentdata----------------------')
                // console.log(presentData)
                // 去重
                // console.log(this.changeCaseData)
                const pageChangeCaseData = this.easyDuplicate(this.changeCaseData)
                // console.log(changeCaseData)
                this.SET_CHANGECASEDATA(pageChangeCaseData) // 保存
                // console.log('changecasedata', changeCaseData)
                // 判断是否存在数组中  是  修改下标  不是 添加
                // 如果与接口返回的案例相同 直接修改下标 去除store末尾
                let tstate = 0 // 状态  与接口返回是否重复
                // console.log(429, this.analysisData)
                this.analysisData.caseList.forEach((item, index) => {
                  // console.log('item------------------------------------------')
                  // console.log(presentData.text)
                  // console.log(item.id === presentData.text)
                  if (item.id === presentData.text) {
                    // 表示相同
                    // console.log('与接口返回重复')
                    this.updataTitleIndex = index
                    pageChangeCaseData.pop()
                    this.SET_CHANGECASEDATA(pageChangeCaseData)
                    tstate = 1
                  }
                })
                // 不与接口返回重复
                if (tstate === 0) {
                  // console.log('不与接口返回重复')
                  // 添加  一个或多个数据
                  for (var i of this.pageChangeCaseData) {
                    const data = {
                      datas: [609935, 234488, 74411],
                      id: i.text,
                      summary_name: i.text,
                      title: ['胜诉', '败诉', '部分'],
                    }
                    this.analysisData.caseList.unshift(data)
                  }
                  // 添加完成 判断下标
                  this.analysisData.caseList.forEach((item, index) => {
                    console.log('判断')
                    if (item.id === presentData.text) {
                      this.$bus.$emit('ana-updata-titleIndex', index)
                    }
                  })
                }
              } else {
                console.log('pageChangeCaseData 参数不存在')
                // console.log(this.changeCaseData)
                // console.log(46800, this.analysisData.caseList)
              }

              this.analysisData.token = res.data.token
              this.oneShowIndex = res.data.result.length
              this.analysisData.tabData = res.data.category
              this.loading = false
              this.$notify.clear()
              if (!this.inScrollMenu) {
                // 动画
                // this.$refs.header.style.overflow = 'hidden'
                // this.$refs.header.style.height = '0'
                // this.$refs.header.style.transform = `translateY(${-this.headerHeight}px)`
                this.$emit('update:step', 1)
                // this.$emit('resetLoading', true)
                // this.$emit('resetFinished', false)
              } else {
                this.$emit('update:step', 2)
                console.log(this.$refs)
                // this.caseCellClick(-1)
              }
            })
            .catch((err) => {
              this.showRefresh = true
              this.loading = false
              this.$emit('resetLoading', false)
              this.$emit('resetFinished', true)
              if (err.response) {
                const code = err.response.status && parseInt(err.response.status)
                if (code === 500) {
                  this.$notify({
                    message: '9无数据更新',
                    background: '#999',
                  })
                }
              } else {
                this.$notify({
                  message: '9无数据更新',
                  background: '#999',
                })
              }
            })
        } catch (e) {
          this.$notify('分析失败')
          this.showRefresh = true
          this.loading = false
          this.$emit('resetLoading', false)
          this.$emit('resetFinished', true)
        }
      } else {
        this.loading = false
        this.$notify({
          message: '描述不能为空',
          duration: 1000,
        })
      }
    },
    // 点击任意一个案列
    caseCellClick(index) {
      this.$emit('resetLoading', true) // loading
      this.$emit('resetFinished', false) // 改dom
      console.log('cse cell click index', index)
      this.oneSelectedIndex = index === -1 ? 0 : index // 会变成 -1
      this.$emit('update:step', 2)
      this.$emit('resetLoading', false)
    },
    // 重置scrollDocument高度
    resetScrollDocument(type, pagetype) {
      this.scrollDocument.scrollTop = 0
      this.$emit('resetLoading', false)
      this.$emit('resetFinished', type)
      if (type) {
        this.$emit('resetFinishedText', '没有更多了', pagetype)
      }
    },
    //
    resetFinishedText(value) {
      this.$emit('resetFinishedText', value)
    },
    resetLoading(value) {
      this.$emit('resetLoading', value)
      this.$emit('resetFinished', !value)
    },
    sliderSide(type) {
      this.$emit('sliderSide', type)
    },
    //
    showHeader() {
      // this.$refs.header.style.overflow = 'visible'
      // this.$refs.header.style.height = `${this.headerHeight}px`
      // this.$refs.header.style.transform = `translateY(0)`
    },
    sliderHandle(type) {
      if (type === 'top' && !this.scrollDocument.scrollTop) {
        this.showHeader()
      }
    },
    analysisText(data) {
      console.log('analysisText99998888')
      console.log(data)
      this.analysisData.textData = data
      // this.texAanalysis()
    },
    SetValue(value) {
      console.log('SetValue')
      this.analysisData.textData = value
      this.texAanalysisTwo()
      window.SetValue = null
    },
    // 添加查询数据
    // 查询非会员次数
    getNoMemberNum() {
      if (this.isWechat) {
        this.memberNum = 1
      } else {
        this.$axios
          .get(`${this.$base}/management/user/residue/search`)
          .then((res) => {
            if (res.data.code === 200 || res.data.code === '200') {
              this.memberNum = res.data.data.restNum
            } else {
              this.memberNum = -1
            }
          })
          .catch(() => {
            this.memberNum = -1
          })
      }
    },
    /**
     * @description: 获取主题分析历史记录
     */
    getThemeHistory() {
      this.$axios
        .post(`${pydataBase}/api/user/analyse_his?user_id=${this.userInfo.id}&offset=1`)
        .then((res) => {
          if (res.data.code === 200 || res.data.code === '200') {
            this.themeList = res.data.result
          }
        })
        .catch((err) => {
          console.error('err', err)
        })
    },
    /**
     * @description: 滚动加载更多数据
     */
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      // setTimeout(() => {
      //   for (let i = 0; i < 10; i++) {
      //     this.list.push(this.list.length + 1)
      //   }
      //   // 加载状态结束
      //   this.historyLoading = false
      //   // 数据全部加载完成
      //   if (this.list.length >= 40) {
      //     this.finished = true
      //   }
      // }, 1000)
    },
    /**
     * @description: 删除主题的历史记录
     * @param {flag,token} flag: true: 删除全部 false: 删除单个;token: 删除单个时传入的token
     */
    deleteTheme(flag, token) {
      if (!this.themeList.length) return
      const url = flag ? `${pydataBase}/api/user/analyse_his?user_id=${this.userInfo.id}&flag=1` : `${pydataBase}/api/user/analyse_his?user_id=${this.userInfo.id}&text_token=${token}`
      this.$axios.delete(url).then((res) => {
        if (res.data.code === 200 || res.data.code === '200') {
          this.$notify({ type: 'success', message: '删除成功' })
          this.getThemeHistory()
        }
      })
    },
    /**
     * @description: 重新进行分析
     * @param {value} value: 重新分析的内容
     */
    reAnalysis(value) {
      this.analysisData.textData = value
      this.texAanalysisTwo()
    },
  },
  mounted() {
    console.log('mounted')

    if (this.$route.query.brief) {
      const time = setInterval(() => {
        this.calcArray(this.analysisData.caseList).map((i, idx) => {
          console.log(i.summary_name, '|', this.$route.query.brief)
          if (i.summary_name == this.$route.query.brief) {
            this.caseCellClick(idx)
            clearInterval(time)
          }
        })
      }, 1000)
    }
    // if (!this.inScrollMenu) {
    //   setTimeout(() => {
    //     this.$refs.header.style.height = this.$refs.header.offsetHeight + 'px'
    //   }, 500)
    // } else {
    //   console.log(123789)
    // }
    // this.texAanalysis()
    if (this.$store.state.ana.caseName) {
      this.analysisData.textData = this.$store.state.ana.caseName
      this.$store.state.ana.caseName = ''
      this.texAanalysisTwo()
    }
    if (this.changeCaseData && this.changeCaseData.length) {
      this.pageChangeCaseData = this.changeCaseData
    }
  },
  created() {
    console.log(this.$route)

    console.log('进入created')
    // 判断是否是从uniapp小程序进入的该页面，如果是则将uniapp传来的userId和token存入浏览器本地
    if (this.$route.query.id) {
      this.isWechat = true
      this.analysisData.textData = this.$route.query.data
      this.is_type = this.$route.query.case
      this.$axios.get(`${this.$base}/management/users/${this.$route.query.id}`).then((res) => {
        if (res.data && res.data.data && res.data.data.memberStatus.toString()) {
          this.userInfo = res.data.data
          this.setUserInfo(res.data.data)
          this.setUserinfo(res.data.data)
          this.setToken(this.$route.query.token)
          window.sessionStorage.setItem('userInfo', JSON.stringify(res.data.data))
          if (this.analysisData.textData) {
            this.texAanalysisTwo()
          }
        }
      })
    }

    if (this.userInfo && this.userInfo.memberStatus !== 1) {
      this.getNoMemberNum()
    }
    this.getThemeHistory()
    // 安卓展示无改功能判让然他不显示
    try {
      const u = navigator.userAgent
      if (u === 'fb-Android-Webview') {
        this.hid = false
        this.isIOS = false
      } else if (u === 'fb-flutter-Webview') {
        this.hid = false
        this.isIOS = false
      } else if (u === 'fb-iOS-Webview') {
        this.hid = true
        this.isIOS = true
      }
    } catch (err) {
      this.$notify(JSON.stringify(err))
    }
    // 关联组件内方法ocrCaseResultCallback 至 JSBridge/officeFileCharResult
    this.$hook(this.$bridge.officeFileCharResult, this.officeFileCharResult)
    // // 组件销毁的时候释放桥接方法
    this.$once('hook:destroyed', () => {
      this.$unhook(this.$bridge.officeFileCharResult)
    })
    window.analyzeFromNative = this.analysisText
    window.ocrAnalysis = this.ocrCallback
    window.callbackVoiceInfos = this.ocrCallbackVoiceInfos
    window.callbackShwotTypeView = this.ocrCallbackShwotTypeView
    /* 自动搜索(SetValue)
     * params:
     *        value : String (需要搜索的字符串)
     */
    window.SetValue = this.SetValue
    if (this.anaText !== '') {
      this.analysisData.textData = this.anaText
      // this.texAanalysis()
    }
    this.$bus.$on('change-case-add-caselist', (res) => {
      console.log(this.analysisData.caseList)
      console.log(res)
      this.keyWordData = {
        datas: [],
        id: res.id,
        summary_name: res.text,
        title: [],
        total_num: null,
      }
    })
    // if (this.$route.query.arr) {
    //   const arr = JSON.parse(this.$route.query.arr)
    //   console.log(arr, this.changeCaseData, 'created - -- --- arr')
    //   this.SET_CHANGECASEDATA(arr)
    //   console.log(this.changeCaseData, 'created - -- --- ===>> arr')
    // }
  },
  watch: {
    inputText(value) {
      console.log('watch中inputText', value)
      this.SET_TEXT(value.trim())
      window.analysisText = value
    },
    step(value) {
      console.log(value)
      if (value === 1) {
        this.$nextTick(() => {
          slider.init(this.$refs.container, this.sliderHandle)
        })
      }
    },
    memberNum(val) {
      // console.log('val changge >>>>>>>', val)
      if (val < 0 && this.userinfo.memberStatus !== 1 && this.userinfo.id) {
        document.querySelector('#fb-ana').style = 'overflow-y: hidden'
      }
    },
    analysisData: {
      handler(valnew, valOld) {
        if (this.analysisData.textData === '') {
          // this.isWechat = false
          this.is_type = 0
        }
      },
      deep: true,
    },
  },
}
</script>
<style scoped lang="stylus">
.van-cell--borderless
 height auto !important
.analysis
  font-size 13px
  padding 15px
  .back
    width 100%
    height 25px
    line-height 25px
    margin -10px auto 20px
  .ana-header
    transition all 1s
  .text-caontainer
    border-radius 5px
    box-shadow 0px 0px 8px 1px rgba(64, 180, 233, 0.23)
    padding 15px
    padding-bottom 5px
    background-color white
    .xiangji
      height 20px
      .numTotal
        color #ccc
        margin-right 10px
        font-size 12px
      .van-button--mini
        border none
        min-width auto
    .text
      width 100%
      border none
      outline none
      resize none
  .button-container
    padding 15px 0
    text-align center
    .analysis-button
      background-color #3EB3F0
      color white
      padding 7px 30px
      border-radius 5px
      font-size 13px
      height auto
      line-height 110%
  .history_title
    font-size 16px
    margin-bottom 10px
  .custom-title
    margin-right 5px
  .re_analysis
    color rgb(0, 102, 255)
.buttonDefault
  font-size 12px
</style>
