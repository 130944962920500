<template>
  <div class="court"
       v-if="Array.isArray(renderArray) && renderArray[1] && renderArray[2]">
    <!-- :legend-visible="false" -->
    <!-- height='0' -->
    <div class="source">
      <!-- <div class="team">
        <span>于唐国兴与环荣公司、</span>
        <span><i class="iconfont icon-child-off"
             style="font-size:.6rem;display:inline-block;margin-top:-3px"
             :style="{'transition':'all .5s','transform':flag.curts?'rotate(90deg)':''}"
             @click="flag.curts=!flag.curts"></i></span>
      </div> -->
      <label v-show="flag.curts">
        <div class="ctn"
             ref="container">
          <ve-ring :data="chartData"
                   :settings="chartSettings"
                   :tooltip="tooltip"
                   :legend-visible="false"
                   :colors="['#4B9EFB','skyblue','#2D43E8','#2DC3E9']"></ve-ring>
        </div>
        <div class="courts">
          <div class="court_item"
               v-for="(item,index) in flag.moreshow?renderArray[1].court:renderArray[1].court.slice(0,3)"
               :key="index">
            <span>{{item.num}}</span>
            <span>{{item.name}}</span>
          </div>
          <div v-if="renderArray[1].court.length>3"
               class="more"
               @click="flag.moreshow=!flag.moreshow">{{flag.moreshow?'收起':'查看更多'}}</div>
        </div>
      </label>
    </div>
    <div class="source">
      <div class="team">
        <span style="font-weight:700;">承办法官</span>
        <!-- <span><i class="iconfont icon-child-off"
             style="font-size:.6rem;display:inline-block;margin-top:-3px"
             :style="{'transition':'all .5s','transform':flag.curts?'rotate(90deg)':''}"
             @click="flag.curts=!flag.curts"></i></span> -->
      </div>
      <label v-show="flag.curts">
        <div class="courts faguan">
          <div class="court_item"
               v-for="(item,index) in flag.faguanflag?renderArray[2].judge:renderArray[2].judge.slice(0,3)"
               :key="index">
            <span>{{item.num}}</span>
            <span>{{item.judge}}</span>
            <span>{{item.name}}</span>
          </div>
          <div v-if="renderArray[1].court.length>3"
               class="more"
               @click="flag.faguanflag=!flag.faguanflag">{{flag.faguanflag?'收起':'查看更多'}}</div>
        </div>
      </label>
    </div>
  </div>
</template>
<script>
import VeRing from 'v-charts/lib/ring.common'
export default {
  name: 'court',
  components: {
    VeRing
  },
  props: ['selectedArray', 'viewMoreFlag', 'currentKey', 'selfKey', 'pageInfo'],
  data () {
    return {
      showIndex: 4,
      tabIndex: 0,
      chartData: {
        columns: ['name', 'value'],
        rows: []
      },
      chartSettings: null,
      tooltip: null,
      flag: {
        curts: true,
        moreshow: false,
        faguanflag: false
      }
    }
  },
  computed: {
    renderArray () {
      if (this.selectedArray.length) {
        if (this.selectedArray.length > 0) {
          this.give_settings()
        }
        return this.selectedArray.filter((item, index) => true)
      }
      return []
    }
  },
  methods: {
    async viewMore () {
      if (this.showIndex < this.selectedArray.length) {
        this.showIndex = this.showIndex + 4
        return true
      } else if (this.selectedArray.length) {
        return false
      }
    },
    give_settings () {
      // if (Array.isArray(this.selectedArray) && this.selectedArray[0].graph) {
      //   this.selectedArray[0].graph.names.forEach((item, index) => {
      //     console.log(index)
      //     this.chartData.rows.push({
      //       'name': item,
      //       'value': this.selectedArray[0].graph.datas[index]
      //     })
      //   })
      //   console.log(this.chartData)
      // }

      this.chartSettings = {
        radius: ['65%', '85%'],
        offsetY: 140,
        label: {
          position: 'center',
          fontSize: 15,
          formatter: '{b}: {d}%'
          // padding: [-15, 20, 0, 0]
        },
        labelLine: {
          smooth: true,
          length: 10,
          length2: 7
        }
      }
      this.tooltip = {
        position: function (point, params, dom, rect, size) {
          if (point[0] < size.viewSize[0] / 2) {
            // 在左侧
            return [point[0] + 10, point[1] + 10]
          } else {
            // 在右侧
            return [point[0] - dom.clientWidth - 10, point[1] + 10]
          }
        }
      }
    }
    // getPosition () {
    //   // 获取容器宽度
    //   const width = this.$refs.container.clientWidth / 3
    //   // 容器高度
    //   this.height = width + 100 + 'px'
    //   // 环形图表偏移量
    //   this.chartSettings.offsetY = width / 2 + 100
    //   // 图表上的字体大小
    //   this.chartSettings.label.fontSize = width / 1
    //   // 一键生成按键轴的字体大小
    //   this.generateCaseAxisSize = width / 9 + 'px'
    // }
    // 获取v-charts图标位置
    // getPosition () {
    //   const width = this.$el.clientWidth * 1.1
    //   this.height = width * (0.5) + 'px'
    //   this.chartSettings.offsetY = width * (0.285)
    //   this.chartSettings.label.fontSize = width / 30
    // },
  },
  created () {
    // this.$nextTick(() => {
    //   this.getPosition()
    // })
  },
  watch: {
    selectedArray (val) {
      if (val.length > 0) {
        for (const item in Object.keys(val[0].graph.names)) {
          this.chartData.rows.push({
            name: val[0].graph.names[item],
            value: val[0].graph.datas[item]
          })
        }
      }
      this.chartSettings = {
        radius: ['65%', '85%'],
        offsetY: 140,
        label: {
          position: 'center',
          fontSize: 15,
          formatter: '{b}: {d}%'
          // padding: [-15, 20, 0, 0]
        },
        labelLine: {
          smooth: true,
          length: 10,
          length2: 7
        }
      }
      this.tooltip = {
        position: function (point, params, dom, rect, size) {
          if (point[0] < size.viewSize[0] / 2) {
            // 在左侧
            return [point[0] + 10, point[1] + 10]
          } else {
            // 在右侧
            return [point[0] - dom.clientWidth - 10, point[1] + 10]
          }
        }
      }
      // this.flag.curts = false
    }
  }
}
</script>
<style scoped lang="stylus">
.court
  .source
    width 95%
    border 1px solid #CCCCCC
    border-radius 5px
    box-shadow 0px 0px 17px 1px rgba(204, 204, 204, 0.25)
    overflow hidden
    margin 20px auto 0px
    .team
      width 100%
      overflow hidden
      padding 10px
      color #333333
      display flex
      justify-content space-between
      font-size 14px
      font-weight 700
        span:nth-child(1)
          float left
          font-weight 700
    .ctn
      width 300px
      height 300px
      margin 0px auto
      overflow hidden
      box-sizing border-box
    .court_item
      box-sizing border-box
      padding-left 25px
      font-size 14px
      margin-bottom 5px
      span:nth-child(1)
        color #4B9EFB
        margin-right 15px
        display inline-block
        width 50px
    .more
      width 100%
      color #999999
      text-align center
      font-size 16px
      margin-bottom 10px
    .faguan
      .court_item
        box-sizing border-box
        padding-left 25px
        font-size 14px
        margin-bottom 5px
        span:nth-child(1)
          color #4B9EFB
          margin-right 15px
          display inline-block
          width 50px
        span:nth-child(2)
          color #4B9EFB
          margin-right 15px
          display inline-block
          width 50px
</style>
