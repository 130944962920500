<template>
  <div class="litigation" ref="litigation">
    <div class="time" v-if="status === 1">
      <div class="type">时间</div>
      <ve-histogram v-if="litigationdata && litigationdata.datas" :width="width" :height="height" :series="test.series" :xAxis="test.xAxis" :yAxis="test.yAxis" />
    </div>
    <div class="type" v-if="status === 1">年度</div>
    <ve-histogram v-if="litigationdata && litigationdata.datas" :width="width" :height="height" :series="test2.series" :xAxis="test2.xAxis" :yAxis="test2.yAxis" />
  </div>
</template>
<script>
import VeHistogram from 'v-charts/lib/bar.common'
export default {
  name: 'litigation',
  components: {
    VeHistogram,
  },
  props: ['selectedArray', 'viewMoreFlag', 'currentKey', 'selfKey', 'pageInfo', 'litigationdata'],
  data() {
    return {
      height: 0,
      showIndex: 4,
      textxdata: [],
      status: 0,
      textxseriesdata: [],
      test: {
        xAxis: [
          {
            axisLabel: {
              rotate: -40,
            },
            axisTick: { alignWithLabel: true },
            // 修改dta
            data: [],
            type: 'category',
          },
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              formatter: '{value} 篇',
            },
          },
        ],
      },
      test2: {
        xAxis: [
          {
            axisLabel: { rotate: -40 },
            axisTick: { alignWithLabel: true },
            // 修改dta
            data: [],
            type: 'category',
          },
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              formatter: '{value} 篇',
            },
          },
        ],
      },
    }
  },
  computed: {
    renderArray() {
      return this.selectedArray.filter((item, index) => {
        return true
      })
    },
  },
  methods: {
    async viewMore() {
      if (this.showIndex < this.selectedArray.length) {
        this.showIndex = this.showIndex + 4
        return true
      } else if (this.selectedArray.length) {
        return false
      }
    },
    getPosition() {
      const width = document.body.clientWidth - 40
      this.width = width + 'px'
      this.height = width * 0.7 + 'px'
    },
    calcXAxis(item) {
      if (item) {
        return item.map((element) => ({
          ...element,
          axisLabel: {
            rotate: -30,
          },
        }))
      }
    },
  },
  watch: {
    litigationdata: function (val) {
      if (val && val.datas) {
        this.test.xAxis = [
          {
            axisLabel: {
              rotate: -40,
              rich: {
                labelText: {
                  lineHeight: 18,
                },
              },
            },
            axisTick: { alignWithLabel: true },
            // 修改dta
            data: val.datas[0].time.month.title,
            type: 'category',
          },
        ]
        this.test.series = [
          {
            barWidth: '60%',
            // 修改data
            data: val.datas[0].time.month.data,
            type: 'bar',
          },
        ]
        // 图表2
        // this.text2xdata = val.datas[0].year.year.title
        this.test2.xAxis = [
          {
            axisLabel: {
              rotate: -40,
              rich: {
                labelText: {
                  lineHeight: 18,
                },
              },
            },
            axisTick: { alignWithLabel: true },
            // 修改dta
            data: val.datas[0].year.year.title,
            type: 'category',
          },
        ]
        this.test2.series = [
          {
            barWidth: '60%',
            // 修改data
            data: val.datas[0].year.year.data,
            type: 'bar',
          },
        ]
        this.status = 1
      }
    },
  },
  created() {
    this.getPosition()
    // this.textxdata = ['1月', '2月', '3月']
    // this.test.series = [
    //   {
    //     // 修改data
    //     data: [1, 2, 3],
    //     type: 'bar'
    //   }
    // ]
  },
}
</script>
<style scoped lang="stylus">
.cell
  box-shadow 0 0 5px 0 #CCCCCC
  border-radius 3px
  margin 15px 0
  padding-top 10px
  .type
    margin-left 15px
</style>
