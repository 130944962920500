<template>
  <div ref="two">
    <treeSelect v-model="TreeSelect" v-if="Object.keys(TreeSelect.data).length > 0" :keywords="save_keyword" @search="pushFilterCondition" parent="ana"></treeSelect>
    <div class="two">
      <!-- <van-tabs @click="changeTitle"
              color="#3eb3f0"
              v-model="titleIndex">
      <van-tab v-for="(item,index) in analysisData.caseList"
               :title="item.option.title.text"
               :key="'tab1'+index">
      </van-tab>
    </van-tabs> -->
      <div class="first-tab" ref="slider" v-if="!inScrollMenu">
        <!-- <div class="mask"
           v-if="showDrawMenu"
           @click="showDrawMenu=false"
           @touchmove.stop.prevent></div> -->
        <div class="addCase-box">
          <!-- <span class="addCase"
              @click.stop="showDrawMenu=true">生成案件</span> -->
          <van-icon name="ellipsis" class="menuEllipsis" size="0.6rem" color="#CCCCCC" @click.stop="showDrawMenu = true" />
          <div class="drawMenu" v-if="showDrawMenu">
            <ul>
              <!-- <div class="drawMenu-ul-div" @click="openFbDocument">
                <div>智能起草</div>
                <div>法律文书</div>
              </div> -->
              <li @click.stop="commitDrawMene('qisu')">
                {{ is_type === 0 ? '写起诉书' : '刑事辩护词' }}
              </li>
              <li v-for="(item, index) in drawMenuArray" :key="index" @click.stop="commitDrawMene(item.key)">
                {{ item.name }}
              </li>
            </ul>
          </div>
        </div>

        <div class="title-tab">
          <!-- {{analysisData.caseList[titleIndex].option.title.text}}123 -->
          <!-- analysisdata 数据  当前下标的name -->
          {{ analysisData.caseList[titleIndex].summary_name }}
        </div>
        <div class="dot-container">
          <div
            class="dot-cell"
            v-for="(item, index) in analysisData.caseList"
            :key="index"
            :style="{
              margin: `0 ${analysisData.caseList.length <= 10 ? 7 : 3}px`,
            }"
          >
            <!-- 小圆添加背景颜色 -->
            <span
              class="dot"
              :style="{
                background: dotBackGroundIndex === index ? '#3399FF' : '',
              }"
              :class="{ selectedDot: index === titleIndex }"
              @click="changeTitle(index)"
            ></span>
          </div>
        </div>
      </div>
      <!-- <div v-else
         class="caseAna">
      <div class="drawMenu"
           v-if="showDrawMenu">
        <ul>
          <li v-for="(item,index) in drawMenuArrayInCase"
              :key="index"
              @click.stop="commitDrawMene(item.key)">{{item.name}}</li>
        </ul>
      </div>
      <div class="min-title">{{analysisData.caseList[titleIndex].option.title.text}}</div>
      <van-icon name="arrow-left"
                class="changeLeft"
                size="0.5rem"
                :color="titleIndex===0?'#D5D5D5':'#333333'"
                @click="changeTitle(titleIndex===0?titleIndex:titleIndex-1)" />
      <van-icon name="arrow"
                class="changeRight"
                size="0.5rem"
                :color="titleIndex===analysisData.caseList.length-1?'#D5D5D5':'#333333'"
                @click="changeTitle(titleIndex===analysisData.caseList.length-1?titleIndex:titleIndex+1)" />
      <van-icon name="ellipsis"
                class="menuEllipsis"
                size="0.6rem"
                color="#CCCCCC"
                @click.stop="showDrawMenu=true" />
    </div> -->

      <van-tabs
        v-model="tabIndex"
        @change="tabChange"
        :swipeable="!inScrollMenu"
        color="#3399ff "
        title-active-color="#3399ff"
        class="touchEventStop setVanTabs"
        style="position: relative"
        @click="noVip(tabIndex)"
      >
        <div class="warp" @click.stop="noVip" v-if="userinfo.id && userinfo.memberStatus !== 1 && memberNum < 0"></div>

        <!-- 第二层tab -->
        <div ref="component" class="component">
          <!-- 内容  -->
          <van-tab v-for="(item, index) in tabData" :title="item.category" :key="'tab2' + index" class="chart-pane">
            <div class="scroll" ref="scroll" @click="pull">
              <keep-alive :include="cacheArray">
                <component
                  v-show="results[item.key + '_results']"
                  :viewMoreFlag="viewMoreFlag"
                  :selectedArray="results[item.key + '_results'] ? results[item.key + '_results'] : []"
                  :currentKey="tabData[tabIndex].key"
                  :currentReportArr="currentReportArr"
                  :selfKey="item.key"
                  :is="precedentComponent"
                  :moreParam="moreParam"
                  :summary="summary"
                  :token="analysisData.token"
                  :tabIndex="tabIndex"
                  :pageInfo="pageInfo"
                  :litigationdata="litigationdata[tabIndex]"
                  @preDetail="preDetail"
                  @Source="SetSource"
                  @lawDetail="lawDetail"
                  @resetScroll="$emit('resetScrollDocument', false)"
                  @saveOptions="save"
                  ref="tabs"
                />
              </keep-alive>
              <div v-show="!results[item.key + '_results'] && semanticCategoryLoading === false && semanticCategoryError === false">暂未统计到相应数据。</div>
              <div v-show="!results[item.key + '_results'] && semanticCategoryLoading === false && semanticCategoryError">请求超时，请稍后重试。</div>
              <!-- <div class="load"
                   @click="nextpage"
                   v-if="precedentComponent!=='court'&&precedentComponent!=='litigation'&&loading===3&&precedentComponent!=='lawyer'">加载更多</div> -->
            </div>
          </van-tab>
        </div>
      </van-tabs>
      <div class="loading" v-if="loading === 0">
        <span>加载中</span>
        <van-loading />
      </div>
      <div class="loading" v-else-if="loading === 1">
        <span></span>
      </div>
      <div class="loading" v-else-if="loading === 2">
        <span>没有更多了</span>
      </div>
      <!-- <div class="detail_area" v-if="lawflag">
        <law-detail
          :id="lawOptions.law_id"
          :title="lawOptions.title"
          :words="lawOptions.words"
          :catlog="lawOptions.catlog"
          :lawContent="lawOptions.data"
          @changeflag="lawflag = false"
        ></law-detail>
      </div> -->
      <div class="detail_area" v-if="precedentflag">
        <precedent-detail
          :id="precedentOptions.doc_id"
          :words="precedentOptions.words"
          :titleName="precedentOptions.titleName"
          :forword="precedentOptions.forword"
          :toZYJD="true"
          @changeflag="CloseDetail"
        ></precedent-detail>
      </div>
    </div>
    <div v-if="skyDrive" class="skyDrive">
      <skyDrive :listid="arret" :Type="'LEGAL_PRECEDENT_FRAGMENT'" @CancelCollect="close" :listname="1" :summary="summary" :modulename="modulename" @getId="getId"></skyDrive>
    </div>
    <div v-if="preview" class="pre">
      <preview :id="numid" @clearpl="clearpl" :userId="$store.state.user.userInfo.id"></preview>
    </div>
    <div v-if="showReport" class="collect-report">
      <searchReport @close="closeReport" from="ana" />
    </div>
    <van-popup v-model="reportPopupVisible" position="right" :style="{ height: '100%', width: '100%' }">
      <ana-report :isType="is_type" @back="reportPopupVisible = false" />
    </van-popup>
    <van-popup v-model="fbDocumentDialogVisible" position="top" :style="{ height: '100%', width: '100%' }">
      <fb-document :summary="summary" :sentence="analysisData.textData" :input_keyword="anaTwoKeyDown || []" :condition="screenSelect || []" @close="fbDocumentDialogVisible = false" />
    </van-popup>
    <van-popup v-model="lawDetailPopupVisible" get-container="#outter" position="right" :style="{ height: '100%' }">
      <law-detail :entry="lawDetailProp" @back="closeLawDetailPopup" />
    </van-popup>
    <div class="collect" @click="goCollect" v-if="reportCount > 0">
      <transition name="slide-fade">
        <span class="badge-box" v-show="showAdd"> +1 </span>
      </transition>
      <transition name="slide-fade">
        <span class="badge-box" v-show="showReduce"> -1 </span>
      </transition>
      <span class="collect-text">集</span>
      <span class="count">{{ this.reportCount }}</span>
    </div>
  </div>
</template>
<script>
/* eslint-disable */

import { mapMutations, mapState, mapGetters, mapActions } from 'vuex'

import preview from '@/pages/preview/index'
import { caseBase, pydataBase } from '~api-config'
import appeal from '../components/appeal'
import twoDetail from '../components/twoDetail'
import deem from '../components/deem'
import lawyer from '../components/lawyer'
import law from '../components/law'
import litigation from '../components/litigation'
import court from '../components/court'
import { slider } from './slider'
import precedentDetail from '@/pages/precedentDetail/index'
import LawDetail from '@/pages/BigData/pages/law'
import AnaReport from '../pages/anaReport'
import treeSelect from '@/pages/Search/compoments/treeSelect.vue'
import fbDocument from '../components/fb-document'
import spendDialog from '../components/spendDialog'

export default {
  name: 'two',
  props: ['analysisData', 'selectedIndex', 'showIndex', 'pageInfo', 'tabData', 'inScrollMenu', 'scrollDocument', 'updataTitleIndex', 'causeName', 'resetLoading', 'fileId', 'is_type', 'memberNum'],
  components: {
    skyDrive: () => import('@/pages/skyDrive/index'),
    searchReport: () => import('@/pages/transform/searchReport'),
    appeal,
    twoDetail,
    deem,
    lawyer,
    law,
    litigation,
    court,
    precedentDetail,
    LawDetail,
    preview,
    AnaReport,
    treeSelect,
    fbDocument,
    spendDialog,
  },
  data() {
    return {
      showAdd: false, // 是否显示+1
      showReduce: false, // 是否显示-1
      lastCount: 0, // 检索报告的数值
      showReport: false,
      semanticCategoryLoading: '',
      TreeSelect: {
        flag: false,
        data: {},
        title: '',
      },
      save_keyword: [],
      cacheArray: ['appeal', 'twoDetail', 'deem', 'lawyer', 'litigation', 'court'],
      arret: [],
      numid: 0,
      preview: false,
      skyDrive: false,
      lawflag: false,
      precedentflag: false,
      lawOptions: {},
      precedentOptions: {},
      modulename: '',
      names: {
        appeal: 0,
        twoDetail: 0,
        deem: 0,
        lawyer: 0,
        law: 0,
        litigation: 0,
        court: 0,
      },
      loading: 0,
      summary: '',
      // 第一层tab选中状态索引
      titleIndex: 0,
      // 返回结果
      results: {},
      // 第二层tab数据
      // tabData: [],
      // 第二层tab选中状态太索引
      tabIndex: 0,
      // 更改查看更多在子组件中watch变化来做出操作
      viewMoreFlag: false,
      // 已获取信息列表
      postArray: [],
      // 显示生成案件的下拉菜单
      showDrawMenu: false,
      // 下拉菜单数组
      drawMenuArray: [
        // { name: '写起诉书', key: 'qisu' },
        // { name: '写起诉书', key: 'qisu' },
        //  { name: '新建项目', key: 'anjian' },
        // { name: '生成报告', key: 'create' }
        { name: '新建项目', key: 'anjian' },
        { name: '分析报告', key: 'analysis' },
        { name: '添加案由', key: 'changeCase' },
        { name: '筛选', key: 'screen' },
        // { name: '添加关键词', key: 'addKeywords' }
      ],
      drawMenuArrayInCase: [
        { name: '发起审批', key: 'shenpi' },
        { name: '写起诉书', key: 'qisu' },
      ],
      move: null,
      showflag: false,
      pages: {
        appeal: 1,
        twoDetail: 1,
        deem: 1,
        lawyer: 1,
        law: 1,
        litigation: 1,
        court: 1,
      },
      Source: null, // 传过来的 dom 判例列表
      litigationdata: [], // 传给表的数据
      // 当前页码,
      dotBackGroundIndex: 0,
      // 分析报告组件显示开关
      reportPopupVisible: false,
      // 文书组件开关
      fbDocumentDialogVisible: false,
      // 法律详情页弹框
      lawDetailPopupVisible: false,
      // 法律详情页入口参数
      lawDetailProp: {},
      currentReportArr: [], // 当前页面报告数据
      vipFlag: true, // 判断是否vip
    }
  },
  computed: {
    ...mapGetters('searchReport', ['reportCount']),
    ...mapState('searchReport', ['anaReports']),
    // 计算二级标题改变时的数据
    precedentComponent() {
      this.loadingchange(this.names[this.tabData[this.tabIndex].key])
      if (this.tabData[this.tabIndex].key === 'appeal') {
        // 诉请裁判
        return 'appeal'
      } else if (this.tabData[this.tabIndex].key === 'deem') {
        // 本院认为
        return 'deem'
      } else if (this.tabData[this.tabIndex].key === 'lawyer') {
        return 'lawyer'
      } else if (this.tabData[this.tabIndex].key === 'law') {
        // 法律依据
        return 'law'
      } else if (this.tabData[this.tabIndex].key === 'litigation') {
        // 诉讼时间
        return 'litigation'
      } else if (this.tabData[this.tabIndex].key === 'court') {
        return 'court'
      } else {
        this.loadingchange(this.names.twoDetail)
        // 争议焦点
        return 'twoDetail'
      }
    },
    moreParam() {
      return {
        summary: this.summary, //  案由名称
        sentence: this.analysisData.textData, //  识别文本  离婚
        is_type: this.is_type, // 案件类型
        input_keyword: this.anaTwoKeyDown || [], // 关键字
        condition: this.screenSelect || [], // 筛选条件
        offset: this.pages[this.precedentComponent], // 页码
      }
    },
    currentTab() {
      return this.viewMoreIndex()
    },
    firstOrEndTabInAna() {
      if (this.inScrollMenu && this.tabIndex === 0) {
        return 'leftSide'
      } else if (this.inScrollMenu && this.tabIndex === 7) {
        return 'rightSide'
      } else {
        return false
      }
    },
    // 是否可以滑动
    isSwipeable() {
      if (this.$store.state.caseDetail.currrentTabName === 'AnalyseTab') {
        return true
      } else {
        return false
      }
    },
    ...mapState('ana', ['screenConditionData', 'screenSelect', 'anaTwoKeyDown']),
    ...mapState('user', ['userInfo', 'userinfo']),
    screenConditionData: {
      get() {
        return this.$store.state.screenConditionData
      },
      set(val) {
        this.$store.state.screenConditionData = val
      },
    },
  },
  methods: {
    ...mapMutations('user', ['setUserCurrentObj']),
    ...mapMutations('ana', ['SET_SUMMARY', 'SET_SCREENSELECT']),
    ...mapActions('searchReport', ['getStampReport']),
    ...mapMutations('searchReport', ['SET_SEARCH_COLLECTION_ID']),
    pull(e) {
      this.$emit('show')
      // if (
      //   (e.target.innerText === '程序法' && this.userInfo.memberStatus !== 1) ||
      //   (e.target.innerText === '探索法' && this.userInfo.memberStatus !== 1)
      // ) {
      //   this.$router.push({ path: '/spendDialog' })
      // }
    },
    // 跳转开通VIP页面
    noVip(index) {
      this.$emit('show')
      // window.console.log(this.userInfo.memberStatus, ',,')
      // if (this.userInfo.memberStatus !== 1 && index > 0) {
      //   this.$router.push({ path: '/spendDialog' })
      // }
    },
    goCollect() {
      this.showReport = true
    },
    closeReport() {
      this.showReport = false
    },
    openFbDocument() {
      this.fbDocumentDialogVisible = true
    },
    CloseDetail() {
      this.precedentflag = false
      if (this.precedentComponent === 'law') {
        this.setUserCurrentObj(this.Source)
      } else {
        this.setUserCurrentObj(this)
        this.cancel = function () {
          const ua = navigator.userAgent
          if (ua === 'fb-Android-Webview') {
            // eslint-disable-next-line
            AND2JS.back()
          } else if (ua === 'fb-flutter-Webview') {
            // eslint-disable-next-line
            try {
              window.back.postMessage('{}')
            } catch (error) {
              console.log('back.postMessage', error)
            }
          } else {
            window.webkit.messageHandlers.back.postMessage({})
          }
        }
      }
    },
    SetSource(_) {
      this.Source = _
    },
    clear() {
      const arr = this.cacheArray
      this.cacheArray = []
      setTimeout(() => {
        this.cacheArray = arr
      })
    },
    notify(message) {
      this.$notify({
        message: message,
        background: '#07c160',
        className: 'TOSAVELIST',
      })
      return {
        addEventListener(callback) {
          setTimeout(() => {
            document.querySelector('.TOSAVELIST').onclick = callback
          })
        },
      }
    },
    save(params, modulename) {
      this.arret = params
      this.modulename = modulename
      this.skyDrive = true
    },
    clearpl() {
      this.preview = false
    },
    getId(id) {
      this.$refs.tabs[0].cancel()
      this.$refs.tabs[1].cancel()
      this.notify('收藏成功,点击查看收藏').addEventListener(() => {
        this.preview = true
        this.numid = id
      })
    },
    close(flag) {
      this.skyDrive = false
      if (flag) {
        setTimeout(() => {
          document.querySelector('.van-notify').style.zIndex = '10000000'
        }, 100)
      } else {
        this.$notify({
          background: 'rgb(25, 137, 250)',
          duration: 1000,
          message: '已取消收藏',
        })
        setTimeout(() => {
          document.querySelector('.van-notify').style.zIndex = '10000000'
        }, 100)
        //
      }
    },
    // 打开法律详情页
    openLawDetailPopup() {
      setTimeout(() => {
        this.lawDetailPopupVisible = true
      }, 301)
    },
    // 关闭法律详情弹窗
    closeLawDetailPopup() {
      this.lawDetailPopupVisible = false
      this.lawDetailProp = {}
    },
    // 设置法律详情入口参数
    lawDetail(id, text, catlog, title, topic) {
      const lawDetailProp = { id, catalog: title, topic }
      this.lawDetailProp = lawDetailProp
      this.openLawDetailPopup()
      // this.lawOptions.law_id = id
      // this.lawOptions.title = title
      // this.lawOptions.words = [text]
      // this.lawOptions.catlog = catlog
      // this.lawflag = true
    },
    preDetail(id, words, forword) {
      this.precedentOptions.doc_id = id
      this.precedentOptions.words = words
      this.precedentOptions.titleName = words
      this.precedentOptions.forword = forword
      this.precedentflag = true
      // console.log('id=========>')
      // console.log(id)
      // this.$router.push({
      //   name: 'searchPrecedentDetail',
      //   query: {
      //     docid: id,
      //     back: true
      //   }
      // })
    },
    jump() {
      // let her = document.getElementById('header')
      // her.style.overflow = ''
      // her.style.height = '150px'
      // her.style.transform = `translateY(0px)`
      // her.querySelector('textarea').focus()
    },
    // 获取数据
    loadingchange(num) {
      this.loading = num
    },
    // 未使用的方法
    nextpage() {
      this.$toast({
        mask: true,
        type: 'loading',
        message: '加载中...',
      })
      this.pages[this.precedentComponent]++
      this.$http
        .post(
          `${pydataBase}/api/cal_similitude/item?summary=${this.summary}&key=${this.tabData[this.tabIndex].key}&offset=${this.pages[this.precedentComponent]}&token=${
            this.analysisData.token
          }&user_id=${this.$store.state.user.userInfo.id}`
        )
        .then((res) => {
          this.$toast.clear()
          setTimeout(() => {
            // console.log(this.tabData[this.tabIndex].key)
            if (this.tabData[this.tabIndex].key === 'law') {
              res.data.result.datas.forEach((item, index) => {
                // eslint-disable-next-line no-unexpected-multiline
                // eslint-disable-next-line standard/computed-property-even-spacing
                this.results[this.tabData[this.tabIndex].key + '_results'][
                  index
                  // eslint-disable-next-line standard/computed-property-even-spacing
                ].values = this.results[this.tabData[this.tabIndex].key + '_results'][index].values.concat(item.values)
              })
              this.$set(this.results, this.tabData[this.tabIndex].key + '_results', this.results[this.tabData[this.tabIndex].key + '_results'])
            } else {
              this.$set(this.results, this.tabData[this.tabIndex].key + '_results', res.data.result.datas)
            }
            if (res.data.result.datas.length === 0) {
              this.names[name] = name === 'appeal' ? 1 : 2
              // this.$emit('resetScrollDocument', true, this.precedentComponent)
            } else {
              this.names[name] = 3
              // this.$emit('resetScrollDocument', false)
            }
            // console.log(this.results)
          }, 0)
        })
        .catch((err) => {
          this.$notify('连接超时')
          Promise.reject(err)
          // this.$emit('resetScrollDocument', true)
        })
    },
    ...mapMutations('ana', ['SET_SCREENCONDITIONDATA']), // 修改筛选数据
    async getInfo() {
      const key = this.tabData[this.tabIndex].key
      if (this.inScrollMenu) {
        this.summary = this.$store.state.ana.caseName.split('x')[0]
      } else {
        this.summary = this.analysisData.caseList[this.titleIndex].summary_name
      }
      // console.log(this.summary)
      // this.summary = this.analysisData.caseList[this.titleIndex].option.title.text
      const name = this.precedentComponent
      // console.log(name)
      // this.$store.state.ana.token = this.analysisData.token
      this.SET_SUMMARY(this.summary)

      // console.log(this.summary)
      // this.$store.state.ana.token = this.analysisData.token;
      ;((summary) => {
        // this.$http.post(`${pydataBase}/api/app/cal_similitude/item?summary=${this.summary}&key=${this.tabData[this.tabIndex].key}&offset=${this.pages[this.precedentComponent]}&token=${this.analysisData.token}&user_id=${this.$store.state.user.userInfo.id}`)
        // 获取案由
        // 更换新api
        // this.$http
        //   .post(
        //     `${pydataBase}/api/cal_similitude/item?summary=${this.summary}
        //     &key=${this.tabData[this.tabIndex].key}
        //     &offset=${this.pages[this.precedentComponent]}
        //     &sentence=${this.analysisData.textData}`
        //   )
        // console.log('筛选')
        // console.log(JSON.stringify(this.screenSelect))
        const data = {
          summary: this.summary, //  案由名称
          key: this.tabData[this.tabIndex].key, // 分类key  law
          sentence: this.analysisData.textData, //  识别文本  离婚
          is_type: this.is_type, // 案件类型
          input_keyword: this.anaTwoKeyDown || [], // 关键字
          condition: this.screenSelect || [], // 筛选条件
          offset: this.pages[this.precedentComponent], // 页码
        }
        // console.log(data)
        this.semanticCategoryError = false
        this.semanticCategoryLoading = true
        this.$emit('resetLoading', true)
        this.$axios
          .post(`${pydataBase}/api/ai/semantic/category`, data)
          .then((res) => {
            this.$emit('resetLoading', false)
            if (res.data.code === 200) {
              this.semanticCategoryError = false
              this.loading = 1
              this.$toast.clear()

              // if (key !== this.tabData[this.tabIndex].key || summary !== this.analysisData.caseList[this.titleIndex].option.title.text) {
              if (!this.inScrollMenu) {
                if (key !== this.tabData[this.tabIndex].key || summary !== this.analysisData.caseList[this.titleIndex].summary_name) {
                  return
                }
              }
              setTimeout(() => {
                this.$set(this.results, this.tabData[this.tabIndex].key + '_results', res.data.result.datas)
                if (res.data.result.datas.length === 0) {
                  this.names[name] = name === 'appeal' ? 1 : 2
                  // this.$emit('resetScrollDocument', true, this.precedentComponent)
                } else {
                  this.names[name] = 3
                  // this.$emit('resetScrollDocument', false)
                }
              }, 0)
              this.litigationdata[this.tabIndex] = res.data.result
            } else {
              this.semanticCategoryError = true
            }
            this.semanticCategoryLoading = false
          })
          .catch((err) => {
            this.$emit('resetLoading', false)
            this.semanticCategoryLoading = false
            this.semanticCategoryError = true
            this.$notify('连接超时')
            Promise.reject(err)
          })
        this.$axios.post(`${this.$pydataBase}/api/ai/semanticTree`, data).then((res) => {
          this.screenConditionData = res.data.result
          this.SET_SCREENCONDITIONDATA(res.data.result)
        })
      })(this.summary)
    },
    // 更换一级标题
    async changeTitle(index, reset) {
      console.log('更换一级标题')
      if (this.userInfo.memberStatus !== 1) {
        const res = await this.$axios.get(`${this.$base}/management/users/${this.userInfo.id}`)
        const { code, data } = res.data
        if (code === 200) {
          const temp = this.userInfo
          temp.memberStatus = data.memberStatus
          this.$store.commit('user/resumeLogin', {
            userInfo: temp,
          })
          if (data.memberStatus !== 1) {
            this.$bus.$emit('fb-member', {
              dialogVisible: true,
              kind: 'fenxi',
              secKind: '',
            })
          }
        }
      }
      if (this.titleIndex === index && !reset) {
        return
      }
      // 清空数据
      this.results = {}
      // this.$emit('update:selectedIndex', index)
      this.titleIndex = index
      this.postArray = []
      this.clear()
      setTimeout(() => {
        this.loading = 0
      })
      this.tabIndex = 0
      this.$nextTick(() => {
        this.getInfo()
        this.loading = 1
      })
      // 更改当前标题下标
      this.dotBackGroundIndex = index
    },
    // 更换二级标题
    async tabChange() {
      console.log('更换二级标题')
      // this.$emit('resetFinished')
      if (this.userInfo.memberStatus !== 1) {
        const res = await this.$axios.get(`${this.$base}/management/users/${this.userInfo.id}`)
        const { code, data } = res.data
        if (code === 200) {
          const temp = this.userInfo
          temp.memberStatus = data.memberStatus
          this.$store.commit('user/resumeLogin', {
            userInfo: temp,
          })
          if (data.memberStatus !== 1) {
            this.$bus.$emit('fb-member', {
              dialogVisible: true,
              kind: 'fenxi',
              secKind: '',
            })
          }
        }
      }
      this.getInfo()
      this.initAnaReport()
    },
    // 筛选选中的tab VueComponent
    isPost(key) {
      let flag = false
      this.postArray.forEach((element) => {
        if (element === key) {
          flag = true
        }
      })
      if (flag) {
        return true
      } else {
        this.postArray.push(key)
        return false
      }
    },
    // 查看更多
    viewMoreIndex() {
      // this.viewMoreFlag = !this.viewMoreFlag
      // console.log(this.tabData[this.tabIndex].key, this.$refs.tabs)
      let index = null
      this.$refs.tabs.forEach((item, i) => {
        if (item.selfKey === this.tabData[this.tabIndex].key) {
          index = i
        }
      })
      return this.$refs.tabs[index]
    },
    //
    sliderTab(type) {
      let index = this.titleIndex
      if (type === 'left') {
        index = index === 0 ? this.analysisData.caseList.length - 1 : index - 1
        this.changeTitle(index)
        this.loading = 1
      } else if (type === 'right') {
        index = index === this.analysisData.caseList.length - 1 ? 0 : index + 1
        this.changeTitle(index)
        this.loading = 1
      }
    },
    //     { name: '新建项目', key: 'anjian' },
    //     { name: '分析报告', key: 'analysis' },
    //     { name: '更换案由', key: 'changeCase' },
    //     { name: '添加关键词', key: 'addKeywords' }
    commitDrawMene(key) {
      if (key === 'shenpi') {
        // 审批
        // console.log('发起审批')
      } else if (key === 'qisu') {
        this.$axios
          .post(caseBase + '/lts/case/defaultCreateCase2', {
            briefCaseText: window.analysisText,
            causeName: this.analysisData.caseList[this.titleIndex].summary_name,
          })
          .then((response) => {
            const { code } = response.data
            if (code && (code === 1003 || code === '1003')) {
              this.$router.push({
                path: '/lawyerAuth',
              })
              return
            }
            this.$store.state.ana.click = this.tabIndex
            let path = `/editor/${response.data.data}?caseDocumentId=1&fromType=NOCASE&fromAna=true`
            if (this.is_type === 1) {
              path = `/editor/${response.data.data}?caseDocumentId=263&groupId=296&fromAna=true`
            }
            this.$router.push({ path })
          })
        // 起诉
        // console.log('写起诉书')
      } else if (key === 'analysis') {
        // this.$store.state.ana.click = this.tabIndex
        // this.$router.push({
        //   name: 'createAna',
        //   query: {
        //     is_create: 1
        //   }
        // })
        this.reportPopupVisible = true
      } else if (key === 'anjian') {
        // 新建项目
        // onsole.log(this.analysisData.caseList[this.titleIndex].summary_name)
        this.$router.push({
          name: 'createcase',
          query: {
            reasonText: this.analysisData.caseList[this.titleIndex].summary_name,
            briefCaseText: this.analysisData.textData.trim(),
            fileId: this.fileId,
            fromAna: true,
          },
        })
      } else if (key === 'changeCase') {
        // 更换案由
        this.$router.push({
          name: 'changeCase',
          query: {},
        })
      } else if (key === 'addKeywords') {
        // 添加关键词
        this.$router.push({
          name: 'anatwokeyword',
          query: {},
        })
      } else if (key === 'screen') {
        // 筛选 传递筛选数据
        // this.$router.push({
        //   name: 'screenCondition',
        //   query: {}
        // })
        const title = []
        this.screenConditionData.map((item) => {
          title.push(item.title)
          this.screenConditionDataFomt(item.data)
        })
        this.TreeSelect.flag = true
        this.TreeSelect.data = this.screenConditionData
        this.TreeSelect.title = title
      }
      this.showDrawMenu = false
    },
    pushFilterCondition(payload) {
      var list = []
      payload.map((item) => {
        list.push({
          key: item.key,
          value: item.value,
        })
      })
      this.SET_SCREENSELECT(list)
      this.$parent.texAanalysisTwo()
    },
    screenConditionDataFomt(data) {
      data.map((item) => {
        if (item.value) {
          item.label = item.value
        }
        if (item.child && item.child.length > 0) {
          this.screenConditionDataFomt(item.child)
        }
      })
    },
    closeMenu() {
      this.showDrawMenu = false
    },
    sliderSide(type) {
      if (!this.isSwipeable) {
      } else if (type === 'right' && this.firstOrEndTabInAna === 'rightSide') {
        this.$emit('sliderSide', 'RIGHT')
      } else if (type === 'left' && this.firstOrEndTabInAna === 'leftSide') {
        this.$emit('sliderSide', 'LEFT')
      } else if (type === 'right') {
        this.tabIndex++
      } else if (type === 'left') {
        this.tabIndex--
      } else {
      }
    },
    option(event) {
      let node = event.target
      while (!node.className.includes('analyse-tab')) {
        if (node.className.includes('van-tabs__wrap')) {
          // van-tabs__wrap van-tabs__wrap--scrollable van-hairline--top-bottom
          return true
        }
        node = node.parentNode
      }
      return false
    },
    showHeader(type) {
      // if (type === 'top' && !this.scrollDocument.scrollTop) {
      //   this.$emit('showHeader')
      // }
    },
    finishDownload(num) {
      switch (num) {
        case 7: // 失败
          this.$notify('下载失败')
          break
        case 8: // 成功
          this.notify('下载成功，点击查看').addEventListener(() => {})
          break
      }
    },
    addTabdata() {
      const arr = JSON.parse(this.$route.query)
      console.log('添加数据', arr)
      // console.log(this.analysisData)
    },
    initAnaReport() {
      this.currentReportArr = []
      const currentKey = this.tabData[this.tabIndex].key
      this.anaReports.forEach((v) => {
        if (v.type === currentKey) {
          this.currentReportArr.push(v)
        }
      })
    },
    // // ocr回调。
    // ocrCaseResultCallback(data) {
    //   console.log('ocr回调0', data)
    //   if (data && data !== '') {
    //     // this.parseHTML(data.split('#!&&&!#')[0])
    //     // ocrFiles包含了多个id，以英文的逗号隔开。
    //     const tempList = data.split('#!&&&!#')[1].split(',')
    //     this.fileId = tempList.map(item => {
    //       return parseInt(item)
    //     })
    //     console.log('ocr回调1')
    //   }
    //   // else {
    //   //   this.$notify('当前内容为空 无法进行解析')
    //   // }
    //   console.log('ocr回调结束')
    // }
  },
  created() {
    this.$emit('show')
    // // 关联组件内方法ocrCaseResultCallback 至 JSBridge/createCaseOcr
    // this.$hook(this.$bridge.createCaseOcr, this.ocrCaseResultCallback)
    // // 组件销毁的时候释放桥接方法
    // this.$once('hook:destroyed', () => {
    //   this.$unhook(this.$bridge.createCaseOcr)
    // })
    this.titleIndex = this.updataTitleIndex || 0
    window.finishDownload = this.finishDownload
    // 添加滚动事件
    if (!this.inScrollMenu) {
      this.$nextTick(() => {
        slider.init(this.$refs.slider, this.sliderTab)
        slider.init(this.$refs.component, this.showHeader)
      })
    } else {
      this.$nextTick(() => {
        slider.init(this.scrollDocument, this.sliderSide, this.option)
      })
    }
    if (this.$store.state.ana.click) {
      this.tabIndex = this.$store.state.ana.click
      this.$store.state.ana.click = null
    }
    // 添加事件监听
    window.addEventListener('click', this.closeMenu)
  },
  mounted() {
    this.$axios
      .get(`${this.$base}/management/users/${this.$store.state.user.userInfo.id}`)
      .then(async (res) => {
        const searchCollectionId = res.data.data.searchCollectionId
        this.SET_SEARCH_COLLECTION_ID(searchCollectionId)
        await this.getStampReport({
          userId: this.$store.state.user.userInfo.id,
          parentId: searchCollectionId,
          callback: () => {
            console.log('==== mounted')
            this.initAnaReport()
          },
        })
      })
      .catch((err) => {
        Promise(err)
      })
    // console.log(this.analysisData.caseList[this.titleIndex])
    if (document.querySelector('.van-list__loading')) {
      document.querySelector('.van-list__loading').remove()
    }
    this.titleIndex = this.updataTitleIndex || this.selectedIndex
    this.dotBackGroundIndex = this.updataTitleIndex || this.selectedIndex
    this.$set({})
    // console.log('判断路由参数')
    // console.log(this.$route.query[0])
    if (this.$route.query[0]) {
      this.addTabdata()
    }
    // console.log('two挂载完成')
    this.getInfo()
    this.$bus.$emit('ana-close-load', false)
    // console.clear()
    // let start = 0; let end = 0; let canSlide = true; let element = document.getElementById('header'); let controlHgt = 170
    // element.style.overflow = ''
    // element.style.height = '150px'
    // element.style.transform = `translateY(0px)`
    // controlHgt = 170
    // element.nextElementSibling.style.transition = 'all .5s'
    // this.start = function (e) {
    //   if (document.querySelector('.van-list__loading')) {
    //     document.querySelector('.van-list__loading').remove()
    //   }
    //   if (e.changedTouches[0].pageY > controlHgt) { canSlide = false; return false }
    //   start = e.changedTouches[0].pageY
    // }
    // this.move = function (e) {
    //   if (!canSlide) { canSlide = true; return false }
    //   end = e.changedTouches[0].pageY
    // if (end - start > 100 && element.style.overflow === 'hidden') {
    //   element.style.overflow = ''
    //   element.style.height = '150px'
    //   element.style.transform = `translateY(0px)`
    //   document.getElementsByClassName('container')[1].style.transform = ''
    //   controlHgt = 120
    // } else if (end - start < -90 && element.style.overflow === '') {
    //   element.style.overflow = 'hidden'
    //   element.style.height = '0px'
    //   element.style.transform = `translateY(-150px)`
    //   controlHgt = 70
    // }
    // start = 0
    // }
    // window.addEventListener('touchstart', this.start)
    // window.addEventListener('touchmove', this.move)
  },
  destroyed() {
    // window.removeEventListener('click', this.closeMenu)
    // window.removeEventListener('touchstart', this.start)
    // window.removeEventListener('touchmove', this.move)
  },
  watch: {
    reportCount(nv, ov) {
      this.lastCount = ov
      if (nv === ov + 1) {
        this.showAdd = true
        setTimeout(() => {
          this.showAdd = false
        }, 600)
      } else if (nv === ov - 1) {
        this.showReduce = true
        setTimeout(() => {
          this.showReduce = false
        }, 600)
      }
      this.initAnaReport()
    },
    skyDrive(val) {
      if (val) {
        document.querySelectorAll('*').forEach((item) => {
          item.style['-webkit-overflow-scrolling'] = 'auto'
        })
      }
    },
    // $route(val) {
    //   console.log('路由变化')
    //   console.log(val)
    // },
    // titleIndex(val) {
    //   console.log('warch ---- titleIndex ')
    //   console.log(val)
    // }
  },
}
</script>
<style scoped lang="stylus">
.pre
  position fixed
  top 0px
  background #ffffff
  z-index 10000000
  width 100%
  left 0px
  height 100%
  overflow hidden
.skyDrive
  position fixed
  top 0px
  background #ffffff
  z-index 10000000
  width 100%
  left 0px
  height 100%
  overflow hidden
.collect-report
  position fixed
  top 0px
  background #ffffff
  z-index 1000
  width 100%
  left 0px
  height 100%
  overflow hidden
.detail_area
  width 100%
  height 100%
  position fixed
  top 0px
  left 0px
  background-color #ffffff
  z-index 10000
.cuirle
  width 40px
  height 40px
  border-radius 50%
  background-color #4B9EFB
  position fixed
  bottom 50px
  right 30px
.collect
  width 44px
  height 44px
  border-radius 50%
  display flex
  align-items center
  justify-content center
  background #b6b6b6
  text-align center
  line-height 1.2rem
  margin-bottom 10px
  color #ffffff
  font-size 0.64rem
  position fixed
  right 40px
  bottom 40%
  z-index 99
  & .count
    font-size 16px
    line-height 16px
    position absolute
    color red
    right 3px
    top 3px
  & .badge-box
    position absolute
    top -10px
    right 0
    text-align right
    color red !important
    z-index 999
    font-weight bold !important
    font-size 14px !important
  &::-webkit-scrollbar
    display none
  & .count
    font-size 16px
    line-height 16px
    position absolute
    color red
    right 3px
    top 3px
  & .collect-text
      font-size 20px
.two
  position relative
.first-tab
  text-align center
  padding 5px 0
  display flex
  align-items center
  flex-direction column
  position relative
  overflow visible
  .mask
    position fixed
    top 0
    left 0
    width 100vw
    height 100vh
    background-color rgba(0, 0, 0, 0.4)
    z-index 100
  .addCase-box
    position absolute
    right 10px
    top 50%
    transform translateY(-50%)
    z-index 110
    .addCase
      color #569af9
      padding 3px 10px
      border-radius 5px
      border 1px solid #569af9
      position relative
      background-color white
    .drawMenu
      position absolute
      right 0
      top 150%
      ul
        background #fff
        border-radius 6px
        overflow hidden
        color #c7c8c9
        border 1px solid #eee
        width 100px
        &:after
          position absolute
          right 20px
          top -5px
          content ''
          display inline-block
          width 10px
          height 10px
          background #fff
          transform rotate(45deg)
          border-top 1px solid #eee
          border-left 1px solid #eee
        li
          border-top 1px solid #eee
          margin-top -1px
          height 35px
          color #333
          line-height 35px
        & .drawMenu-ul-div
          color #333
          font-weight bold
          padding: 5px 0
          box-sizing: border-box
  .title-tab
    text-align center
  .dot-container
    display flex
    justify-content center
    align-items center
    margin-top 5px
    .dot
      display inline-block
      width 10px
      height 10px
      border-radius 50%
      border 1px solid gray
      background-color #fff
      &.selectedDot
        border-color #3eb3f0
.caseAna
  font-size 15px
  text-align center
  margin-bottom 5px
  position relative
  overflow visible
  .changeLeft
    position absolute
    top 50%
    left 45px
    transform translateY(-50%)
  .changeRight
    position absolute
    top 50%
    right 45px
    transform translateY(-50%)
  .menuEllipsis
    position absolute
    top 50%
    right 5px
    transform translateY(-50%)
  .drawMenu
    position absolute
    right 0
    top 150%
    z-index 110
    ul
      background #fff
      border-radius 6px
      overflow hidden
      color #c7c8c9
      border 1px solid #eee
      &:after
        position absolute
        right 20px
        top -5px
        content ''
        display inline-block
        width 10px
        height 10px
        background #fff
        transform rotate(45deg)
        border-top 1px solid #eee
        border-left 1px solid #eee
      li
        border-top 1px solid #eee
        margin-top -1px
        height 35px
        padding 0 20px
        color #333
        line-height 35px
.loading
  display flex
  width 100px
  justify-content space-around
  position absolute
  top 25%
  left calc(50% - 50px)
  overflow hidden
  z-index 1000
  span
    font-size 14px
    color #666666
    vertical-align middle
    display inline-block
    line-height 30px
.scroll
  width 100%
  height calc(100% - 100px)
  position relative
  overflow auto
  min-height 400px
.load
  text-align center
  color #666666
</style>

<style scope>
.van-tabs__line {
  width: 177px;
}

.van-tabs__nav {
  margin: 0;
}

.warp {
  z-index: 99;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: red;
}
</style>
<style lang="stylus">
.slide-fade-enter-active {
  transition: all 1s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translate(-30px, -10px) scale(3);
  opacity: 0;
}
.setVanTabs
  & .van-tabs__wrap
    & .van-tabs__line
      width 40px !important
</style>
