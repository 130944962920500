var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value:
            (_vm.law.relevancyKey && _vm.law.show) ||
            (_vm.law.relevancyKey && _vm.law.i === 0) ||
            !_vm.law.relevancyKey,
          expression:
            "\n    (law.relevancyKey && law.show) ||\n    (law.relevancyKey && law.i === 0) ||\n    !law.relevancyKey\n  ",
        },
      ],
      staticClass: "law-item",
    },
    [
      _c("div", { staticClass: "law-item__cell" }, [
        _c(
          "div",
          {
            staticClass: "cell-checkbox",
            on: {
              touchend: function ($event) {
                $event.stopPropagation()
                return _vm.select(_vm.index, $event)
              },
            },
          },
          [_c("check-box", { attrs: { value: _vm.law.checked } })],
          1
        ),
        _c("div", { staticClass: "cell-title van-multi-ellipsis--l3" }, [
          _c(
            "span",
            {
              style: _vm.law.colorType ? "color: rgb(255, 153, 0) " : "",
              on: {
                touchend: function ($event) {
                  $event.stopPropagation()
                  return _vm.jumpToLawDetail.apply(null, arguments)
                },
              },
            },
            [_vm._v(_vm._s(_vm.law.title))]
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "cell-pr",
            on: {
              touchend: function ($event) {
                $event.stopPropagation()
                return _vm.jumpToList.apply(null, arguments)
              },
            },
          },
          [_vm._v(_vm._s(_vm.law.percent))]
        ),
      ]),
      _c(
        "div",
        {
          class: { "van-multi-ellipsis--l3": _vm.ellipsis },
          on: {
            click: function ($event) {
              _vm.ellipsis = false
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.law.text) + " ")]
      ),
      _vm.law.relevancyKey &&
      _vm.law.i === 0 &&
      _vm.law.len > 1 &&
      !_vm.law.show
        ? _c(
            "div",
            {
              staticClass: "name",
              staticStyle: {
                color: "rgb(255, 153, 0)",
                "margin-left": "10px",
                cursor: "pointer",
              },
              on: {
                click: function ($event) {
                  return _vm.more(_vm.law.relevancyKey, true)
                },
              },
            },
            [_vm._v(" 更多关联法条 ")]
          )
        : _vm._e(),
      _vm.law.relevancyKey &&
      _vm.law.i === _vm.law.len - 1 &&
      _vm.law.len > 1 &&
      _vm.law.show
        ? _c(
            "div",
            {
              staticClass: "name",
              staticStyle: {
                color: "rgb(255, 153, 0)",
                "margin-left": "10px",
                cursor: "pointer",
              },
              on: {
                click: function ($event) {
                  return _vm.more(_vm.law.relevancyKey, false)
                },
              },
            },
            [_vm._v(" 收起关联法条 ")]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }