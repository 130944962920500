/* eslint-disable */
export const slider = {
  // 判断设备是否支持touch事件
  touch:
    'ontouchstart' in window ||
    (window.DocumentTouch && document instanceof DocumentTouch),
  slider: null,

  // 事件
  events: {
    slider: null, // this为slider对象
    startPos: {},
    endPos: {},
    isScrolling: null,
    handle: null,
    event: null,
    option: null,
    handleEvent: function(event) {
      var self = this // this指events对象
      if (event.type == 'touchstart') {
        self.start(event)
      } else if (event.type == 'touchmove') {
        self.move(event)
      } else if (event.type == 'touchend') {
        self.end(event)
      }
    },
    // 滑动开始
    start: function(event) {
      this.event = event
      if (this.option && this.option(event)) {
        return
      }
      var touch = event.targetTouches[0] // touches数组对象获得屏幕上所有的touch，取第一个touch
      this.startPos = { x: touch.pageX, y: touch.pageY, time: +new Date() } // 取第一个touch的坐标值
      this.isScrolling = 0 // 这个参数判断是垂直滚动还是水平滚动
      this.slider.addEventListener('touchmove', this, false)
      this.slider.addEventListener('touchend', this, false)
    },
    // 移动
    move: function(event) {
      // 当屏幕有多个touch或者页面被缩放过，就不执行move操作
      if (
        event.targetTouches.length > 1 ||
        (event.scale && event.scale !== 1)
      ) {
        return
      }
      var touch = event.targetTouches[0]
      this.endPos = {
        x: touch.pageX - this.startPos.x,
        y: touch.pageY - this.startPos.y
      }
      this.isScrolling =
        Math.abs(this.endPos.x) < Math.abs(this.endPos.y) ? 1 : 0 // isScrolling为1时，表示纵向滑动，0为横向滑动
      if (this.isScrolling === 0) {
        event.preventDefault() // 阻止触摸事件的默认行为，即阻止滚屏
        // event.cancelBubble=true
        // this.slider.style.left = -this.index * 600 + this.endPos.x + 'px'
      }
    },
    // 滑动释放
    end: function(event) {
      var duration = +new Date() - this.startPos.time // 滑动的持续时间
      if (this.isScrolling === 0) {
        // 当为水平滚动时
        if (Number(duration) > 20) {
          // 判断是左移还是右移，当偏移量大于10时执行
          if (this.endPos.x > 10) {
            this.handle('left', this.event)
          } else if (this.endPos.x < -10) {
            this.handle('right', this.event)
          }
        }
      }else{
        if (Number(duration) > 20) {
          // 判断是左移还是右移，当偏移量大于10时执行
          if (this.endPos.y > 10) {
            this.handle('top', this.event)
          } else if (this.endPos.y < -10) {
            this.handle('bottom', this.event)
          }
        }
      }
      this.endPos = {}
      // 解绑事件
      this.slider.removeEventListener('touchmove', this, false)
      this.slider.removeEventListener('touchend', this, false)
    }
  },

  // 初始化
  init: function(dom, handle, option) {
    var self = this // this指slider对象
    self.slider = dom
    self.events.slider = dom
    self.events.handle = handle
    self.events.option = option
    if (self.touch) {
      self.slider.addEventListener('touchstart', self.events, false)
    } // addEventListener第二个参数可以传一个对象，会调用该对象的handleEvent属性
  },
  outIt: function() {
    this.slider.removeEventListener('touchstart', self.events, false)
  }
}
