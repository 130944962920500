var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "transition",
        { attrs: { name: "van-slide-down" } },
        [
          _c("fb-notify", {
            attrs: {
              msg: _vm.actionNotifyMsg,
              visible: _vm.actionNotifyVisible,
            },
            on: { linkClick: _vm.onNotifyLinkClick },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "law" },
        [
          _vm.selectedArray.length && _vm.youxiaoData.length
            ? _c(
                "van-tabs",
                {
                  attrs: {
                    type: "card",
                    color: "#3eb3f0",
                    "lazy-render": true,
                  },
                  on: { change: _vm.changeTab },
                  model: {
                    value: _vm.tabIndex,
                    callback: function ($$v) {
                      _vm.tabIndex = $$v
                    },
                    expression: "tabIndex",
                  },
                },
                _vm._l(
                  _vm.filterType === "筛选有效法条"
                    ? _vm.youxiaoData
                    : _vm.selectedArray,
                  function (items, index) {
                    return _c(
                      "van-tab",
                      { key: index, attrs: { title: items.name } },
                      [
                        _c("div", { staticClass: "law-filter" }, [
                          _c(
                            "span",
                            {
                              style:
                                _vm.filterType === "筛选有效法条"
                                  ? "color:#FF9A02"
                                  : "",
                              on: {
                                click: function ($event) {
                                  return _vm.changeFilterType("筛选有效法条")
                                },
                              },
                            },
                            [_vm._v("筛选有效法条")]
                          ),
                          _c("span", [_vm._v(" | ")]),
                          _c(
                            "span",
                            {
                              style:
                                _vm.filterType === "全部"
                                  ? "color:#FF9A02"
                                  : "",
                              on: {
                                click: function ($event) {
                                  return _vm.changeFilterType("全部")
                                },
                              },
                            },
                            [_vm._v("全部")]
                          ),
                        ]),
                        _vm._l(items.values, function (item, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c("law-card", {
                                attrs: {
                                  "filter-type": _vm.filterType,
                                  card: item,
                                  "current-report-arr": _vm.currentReportArr,
                                  index: index,
                                  "select-law-index": _vm.selectLawIndex,
                                  "select-action-hash": _vm.selectActionHash,
                                  "law-key": items.key,
                                },
                                on: {
                                  select: _vm.handleLawCardSelect,
                                  toLaw: _vm.showLawStart,
                                  toLawDetail: _vm.showLawDetail,
                                  jumpToList: _vm.jumpToList,
                                },
                              }),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    )
                  }
                ),
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "list" },
            [
              _c("appeal-items", {
                ref: "appeal_detail",
                attrs: {
                  options: _vm.options,
                  "law-text": _vm.lawText,
                  "law-title": _vm.lawTitle,
                },
                on: {
                  showDetail: _vm.showDetail,
                  HideAppealDetail: _vm.hideList,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectLawLength > 0,
                  expression: "selectLawLength > 0",
                },
              ],
              staticClass: "ana-top-actions",
            },
            [
              _c("div", { staticClass: "wrap" }, [
                _c(
                  "div",
                  { staticClass: "special", on: { click: _vm.actionCancel } },
                  [_vm._v("取消(" + _vm._s(_vm.selectLawLength) + ")")]
                ),
                _c(
                  "div",
                  { staticClass: "action-btn", on: { click: _vm.actionFav } },
                  [_vm._v("收藏")]
                ),
                _c(
                  "div",
                  { staticClass: "action-btn", on: { click: _vm.actionShare } },
                  [_vm._v("分享")]
                ),
                !_vm.$route.query.isMiniApp
                  ? _c(
                      "div",
                      {
                        staticClass: "action-btn",
                        on: { click: _vm.actionInsert },
                      },
                      [_vm._v("插入")]
                    )
                  : _vm._e(),
                !_vm.$route.query.isMiniApp
                  ? _c(
                      "div",
                      {
                        staticClass: "action-btn",
                        on: { click: _vm.actionDownload },
                      },
                      [_vm._v("下载")]
                    )
                  : _vm._e(),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm.skyDriveVisible
        ? _c(
            "div",
            { staticClass: "skyDrive" },
            [
              _c("skyDrive", {
                attrs: {
                  listid:
                    _vm.selectLawForAction.length > 0
                      ? _vm.selectLawForAction
                      : null,
                  type: "LAW_FRAGMENT",
                  modulename: "判决依据",
                  listname: 1,
                  summary: _vm.$store.state.ana.summary,
                },
                on: {
                  CancelCollect: _vm.cancelFav,
                  getId: _vm.openActionNotify,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.preview
        ? _c(
            "div",
            { staticClass: "pre" },
            [
              _c("preview", {
                attrs: {
                  id: _vm.numid,
                  "user-id": _vm.$store.state.user.userInfo.id,
                },
                on: { clearpl: _vm.clearpl },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }