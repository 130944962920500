<template>
  <div class="topCase">
    <div class="cell"
         v-for="(item,index) in array"
         :key="index"
         @click="goDetail(item.doc_id)">
      <div class="title">
        {{item.case_name}}
      </div>
      <div class="content">
        {{item.result}}
      </div>
    </div>
  </div>
</template>
<script>
import { pydataBase } from '~api-config'
export default {
  name: 'topCase',
  props: ['pageInfo'],
  data () {
    return {
      // 100调数据
      array: [],
      showDetail: false
    }
  },
  methods: {
    goDetail (id) {
      this.pageInfo.page = 'detailInTopCase'
      this.pageInfo.id = id
    }
  },
  mounted () {
    this.$emit('resetLoading', true)
    this.$http.post(`${pydataBase}/api/app/similar?token=${this.pageInfo.token}`)
      .then(res => {
        if (res.data.code === 200) {
          this.array = res.data.result
          this.$emit('resetFinished', false)
          this.$emit('resetLoading', false)
        }
      })
  }
}
</script>
<style scoped lang="stylus">
.topCase
  padding 15px
  font-size 13px
  .cell
    box-shadow 0 0 5px 0 #40B4E9
    margin-bottom 15px
    padding 10px
    .title
      font-size 1.3em
      color #666666
    .content
      color #828282
      margin-top 10px
</style>
