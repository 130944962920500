/* eslint-disable */
<template>
  <div class="appeal">
    <div class="cell" v-for="(item, index) in this.selectlist" :key="index" :div="index">
      <div class="box">
        <el-checkBox v-model="item.flag" @change="chooseItem($event, item)" />
      </div>
      <!-- 名字 和案件号 -->
      <div class="text" @click="yuanwen(item.doc_id, item.text)" :style="{ display: item.showflag ? '' : '-webkit-box' }" v-html="item.text"></div>
      <div class="footer">
        <span class="see" @click="slide(index)">{{ item.showflag ? '收起' : '展开' }}</span>
      </div>
      <div class="deem-tit-div">
        <span class="deem-tit-casename">{{ item.case_name }}</span>
        <span class="deem-tit-caseid">{{ item.case_id }}</span>
      </div>
    </div>
    <div class="options" :style="{ top: optflag ? '0px' : '-100%' }">
      <div @click="cancel" class="speciall">取消({{ length }})</div>
      <div @click="save">收藏</div>
      <div @click="download">分享</div>
      <div @click="give" v-if="!$route.query.isMiniApp">插入</div>
      <div @click="loadet" v-if="!$route.query.isMiniApp">下载</div>
    </div>
  </div>
</template>
<script>
import { caseBase } from '~api-config'
import { mapMutations, mapState, mapActions } from 'vuex'
import elCheckBox from '@/pages/precedentDetail/tabs/checkBox'
import qs from 'qs'
export default {
  name: 'deem',
  components: { elCheckBox },
  props: ['selectedArray', 'viewMoreFlag', 'currentKey', 'selfKey', 'pageInfo', 'currentReportArr'],
  data() {
    return {
      showIndex: 4,
      selectlist: [],
      selectArr: [],
      optflag: false,
      length: 0,
    }
  },
  computed: {
    ...mapState('searchReport', ['anaReports']),
  },
  methods: {
    ...mapMutations('user', ['setUserCurrentObj']),
    ...mapMutations('searchReport', ['SET_ANA_REPORT']),
    ...mapActions('searchReport', ['setStampReport']),
    loadet() {
      const date = new Date()
      const data = {
        name: `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} 《本院认为》`,
        children: this.selectArr,
      }
      this.$axios
        .post(`${caseBase}/document/user/${this.$store.state.user.userInfo.id}/fragment`, data)
        .then((res) => {
          const url = res.data.data.path
          const name = url.substring(url.lastIndexOf('/') + 1)
          const pathUrl = url.substring(0, url.lastIndexOf('/'))
          console.log(res.data, '====>>> 接口返参02')
          const ua = navigator.userAgent
          if (ua === 'fb-Android-Webview') {
            // eslint-disable-next-line
            AND2JS.showFilePreview(res.data.data.path)
          } else if (ua === 'fb-iOS-Webview') {
            // ios环境
            // eslint-disable-next-line
            window.webkit.messageHandlers.fbDownloadFile.postMessage(res.data.data.path)
          } else if (ua === 'fb-flutter-Webview') {
            // eslint-disable-next-line
            try {
              // 接口请求返回数据的
              // 之前的方法
              // window.fbDownloadFile.postMessage(res.data.data.path)
              // 新改的方法
              window.showFilePreview.postMessage(
                JSON.stringify({
                  filePath: pathUrl,
                  fileName: name,
                })
              )
            } catch (error) {
              console.log(error, 'fbDownloadFile.postMessage')
            }
          } else {
            this.$toast('网址为：' + res.data.data.path)
          }
        })
        .catch((err) => {
          console.log(err)
          this.$notify('下载失败')
          Promise.reject(err)
        })
    },
    give() {
      const ua = navigator.userAgent
      let content = ''
      this.selectArr.forEach((item) => {
        content += item.text + ' '
        content += item.case_name + ' ' + item.case_id + '，'
      })
      content = content.substring(0, content.length - 1)
      if (ua === 'fb-Android-Webview') {
        // eslint-disable-next-line
        AND2JS.toWrite([content])
      } else if (ua === 'fb-flutter-Webview') {
        // eslint-disable-next-line
        try {
          window.toWrite.postMessage(content)
        } catch (error) {
          console.log(error, 'fbDownloadFile.postMessage')
        }
      } else {
        window.webkit.messageHandlers.JumpToDocument.postMessage(this.Iter(this.selectArr))
      }
    },
    Iter(arr) {
      let str = ''
      arr.forEach((item) => {
        if (item.name) {
          str += item.name
          str += '\n'
        }
        if (item.text) {
          str += item.text
          str += '\n'
        }
        if (item.children && item.children.length > 0) {
          str += this.Iter(item.children)
        }
      })
      return str
    },
    map(arr) {
      arr.forEach((item, index) => {
        this.$set(item, 'flag', false)
      })
    },
    cancel() {
      this.Loaded = true
      this.map(this.selectlist)
      this.lengh = 0
      setTimeout(() => {
        this.Loaded = false
        this.optflag = false
        // eslint-disable-next-line no-undef
        AND2JS.showBottomTab('display')
      })
    },
    save() {
      this.$emit('saveOptions', this.selectArr, '本院认为')
    },
    download() {
      const date = new Date()
      const name = `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} 《本院认为》`
      const data = {
        name,
        children: this.selectArr,
      }
      this.$axios
        .post(`${caseBase}/document/user/${this.$store.state.user.userInfo.id}/fragment`, data)
        .then((res) => {
          console.log(res.data, '====>>> 分享的接口返参')
          const ua = navigator.userAgent
          if (ua === 'fb-Android-Webview') {
            // eslint-disable-next-line no-undef
            AND2JS.shareCaseFile(
              `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} 《本院认为》`,
              window.encodeURI(`https://view.officeapps.live.com/op/view.aspx?src=${res.data.data.path}`)
            )
          } else if (ua === 'fb-iOS-Webview') {
            window.webkit.messageHandlers.shareCaseFile.postMessage(
              JSON.stringify({
                filename: `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} 《本院认为》`,
                url: window.encodeURI(`https://view.officeapps.live.com/op/view.aspx?src=${res.data.data.path}`),
              })
            )
          } else if (ua === 'fb-flutter-Webview') {
            try {
              window.shareCaseFile.postMessage(
                JSON.stringify({
                  filename: `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} 《本院认为》`,
                  url: window.encodeURI(`https://view.officeapps.live.com/op/view.aspx?src=${res.data.data.path}`),
                })
              )
            } catch (error) {
              console.log('shareCaseFile.postMessage:', error)
            }
          } else {
            const text = res.data.data.path
            const transfer = document.createElement('input')
            document.body.appendChild(transfer)
            transfer.value = text // 这里表示想要复制的内容
            transfer.select()
            if (document.execCommand('copy')) {
              document.execCommand('copy')
            }
            transfer.blur()
            document.body.removeChild(transfer)
            this.$notify({
              type: 'success',
              duration: 1000,
              message: '已复制链接，粘贴发送给他人',
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 采用
    use(text) {
      const content = text
      this.$axios({
        method: 'post',
        url: `${caseBase}/farbun/temp/add`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        data: qs.stringify({
          userId: this.$store.state.user.userInfo.id,
          content,
        }),
      }).then((res) => {
        if (res.data.code === 200) {
          this.$toast('采用成功')
        } else {
          this.$toast('采用失败')
        }
      })
    },
    slide(index) {
      // 控制展开和收起
      console.log(index)
      this.selectlist[index].showflag = !this.selectlist[index].showflag
    },
    // 查看原文
    yuanwen(id, title) {
      this.$emit('preDetail', id, title, this.selfKey)
    },
    calcArray(array) {
      console.log(array)
      this.selectlist = this.selectlist.concat(array)
      this.selectlist.forEach((item, index, array) => {
        if (typeof item.flag === 'boolean') {
          return false
        }
        this.$set(this.selectlist, index, {
          showflag: false,
          doc_id: item.doc_id,
          text: item.text,
          flag: false,
          case_name: item.case_name,
          case_id: item.case_id,
        })
      })
    },
    async viewMore() {
      if (this.showIndex < this.selectedArray.length) {
        this.showIndex = this.showIndex + 4
        return true
      } else if (this.selectedArray.length) {
        return false
      }
    },
    // 选中计数
    chooseItem(flag, item) {
      const arr = [...this.currentReportArr]
      if (arr.length) {
        const index = arr.findIndex((val) => val.doc_id === item.doc_id)
        if (index !== -1) {
          if (!flag) {
            arr.splice(index, 1)
          } else {
            console.log('yiyi')
          }
        } else {
          if (flag) {
            arr.push({
              type: 'deem',
              title: item.text,
              case_name: item.case_name,
              text: item.text,
              case_id: item.case_id,
              anaArr: [{}],
              id: `deem-${item.doc_id}`,
              doc_id: item.doc_id,
            })
          }
        }
      } else {
        if (flag) {
          arr.push({
            type: 'deem',
            title: item.text,
            case_name: item.case_name,
            text: item.text,
            case_id: item.case_id,
            anaArr: [{}],
            id: `deem-${item.doc_id}`,
            doc_id: item.doc_id,
          })
        }
      }
      const otherArr = this.anaReports.filter((v) => v.type !== 'deem')
      if (this.$store.state.user.userInfo.id) {
        this.setStampReport({
          userId: this.$store.state.user.userInfo.id,
          type: 'anaReports',
          reportData: [...otherArr, ...arr],
        })
      } else {
        this.SET_ANA_REPORT([...otherArr, ...arr])
      }
    },
    // 初始化选中
    initSelect() {
      const currentReportArrIds = this.currentReportArr.map((v) => v.doc_id)
      this.selectlist.forEach((item) => {
        if (currentReportArrIds.indexOf(item.doc_id) !== -1) {
          console.log(item)
          item.flag = true
        } else {
          item.flag = false
        }
      })
    },
  },
  created() {
    this.setUserCurrentObj(this)
  },
  watch: {
    optflag(val) {
      if (val) {
        document.querySelectorAll('*').forEach((item) => {
          item.style['-webkit-overflow-scrolling'] = 'auto'
        })
      } else {
        document.querySelectorAll('*').forEach((item) => {
          item.style['-webkit-overflow-scrolling'] = ''
        })
      }
    },
    selectedArray(val) {
      this.calcArray(val)
    },
    selectlist: {
      deep: true,
      handler(val) {
        if (this.Loaded) {
          return false
        }
        this.selectArr = []
        val.forEach((item) => {
          if (item.flag) {
            this.selectArr.push({
              // docid: item.doc_id,
              // name: item.text || '',
              // text: item.text || '',
              // case_name: item.case_name,
              // case_id: item.case_id,
              // type: 0,
              // children: [],
              text: item.text || '',
            })
          }
        })
        this.length = this.selectArr.length
        if (this.selectArr.length > 0) {
          this.optflag = true
          const ua = navigator.userAgent
          if (ua === 'fb-Android-Webview') {
            // eslint-disable-next-line no-undef
            AND2JS.showBottomTab('hide')
          }
        } else {
          this.optflag = false
          const ua = navigator.userAgent
          if (ua === 'fb-Android-Webview') {
            // eslint-disable-next-line no-undef
            AND2JS.showBottomTab('display')
          }
        }
        console.log('deem watch')
        if (val && val.length) {
          this.initSelect()
        }
      },
    },
  },
}
</script>
<style scoped lang="stylus">
#fb-ana, .analyse-tab
  .appeal
    .cell
      padding 15px 10px
      margin 5px 0
      position relative
      .box
        position absolute
        top 20px
      .text
        color #000
        font-size 100%
        width 90%
        margin-left 17px
        text-overflow ellipsis
        overflow hidden
        -webkit-line-clamp 4
        -webkit-box-orient vertical
      .footer
        margin-top 10px
        text-align right
        .use
          background-color #3EB3F0
          color white
          display inline-block
          padding 3px 30px
          border-radius 15px
        .see
          color #3EB3F0
.options
  width 100%
  height 40px
  position fixed
  z-index 1000
  top 0px
  left 0px
  background-color white
  display flex
  justify-content flex-end
  background-color #f6f6f6
  transition all 0.5s
  div
    height 20px
    line-height 10px
    display inline-block
    font-size 14px
    margin-right 10px
    background linear-gradient(#4B9EFB, #1983FB)
    color white
    text-align center
    box-sizing border-box
    padding 5px 10px 5px 10px
    border-radius 99px
    align-self center
.options
  width 100%
  height 40px
  position fixed
  z-index 1000
  top 0px
  left 0px
  background-color white
  background-color #f6f6f6
  transition all 0.5s
  line-height 40px
  display block
  div
    display inline-block
    height 20px
    line-height 10px
    display inline-block
    font-size 14px
    margin-right 10px
    background linear-gradient(#4B9EFB, #1983FB)
    color white
    text-align center
    box-sizing border-box
    padding 5px 10px 5px 10px
    border-radius 99px
    align-self center
    float right
    margin-top 10px
  .speciall
    background transparent !important
    color #333
    float left
</style>
<style scope>
.deem-tit-div {
  margin-left: 20px;
  font-size: 12px;
  color: #cccccc;
  display: flex;
  font-size: 12px;
}
.deem-tit-casename {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50%;
}
.deem-tit-caseid {
  margin-right: 20px;
  width: 50%;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.see {
  position: absolute;
  bottom: 38px;
  right: 0;
}
</style>
