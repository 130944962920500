var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "singlePie" }, [
    _c(
      "div",
      {
        ref: "container",
        staticClass: "container",
        on: { click: _vm.caseCellClick },
      },
      [
        _c("div", { staticClass: "title" }, [
          _c("span", [_vm._v(" " + _vm._s(_vm.option.summary_name) + " ")]),
        ]),
        _c(
          "div",
          { staticClass: "ve-pie-hull" },
          [
            _c("ve-pie", {
              ref: "vpPieDOm",
              attrs: {
                "judge-width": true,
                height: `${_vm.height}px`,
                settings: _vm.chartSettings,
                tooltip: _vm.tooltip,
                legend: _vm.legend,
                series: _vm.chartData,
              },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }