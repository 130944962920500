<template>
  <div>
    <transition name="van-slide-down">
      <fb-notify :msg="actionNotifyMsg" :visible="actionNotifyVisible" @linkClick="onNotifyLinkClick" />
    </transition>
    <div class="law">
      <van-tabs v-if="selectedArray.length && youxiaoData.length" v-model="tabIndex" type="card" color="#3eb3f0" :lazy-render="true" @change="changeTab">
        <van-tab v-for="(items, index) in filterType === '筛选有效法条' ? youxiaoData : selectedArray" :key="index" :title="items.name">
          <div class="law-filter">
            <span :style="filterType === '筛选有效法条' ? 'color:#FF9A02' : ''" @click="changeFilterType('筛选有效法条')">筛选有效法条</span>
            <span> | </span>
            <span :style="filterType === '全部' ? 'color:#FF9A02' : ''" @click="changeFilterType('全部')">全部</span>
          </div>
          <div v-for="(item, index) in items.values" :key="index">
            <law-card
              :filter-type="filterType"
              :card="item"
              :current-report-arr="currentReportArr"
              :index="index"
              :select-law-index="selectLawIndex"
              :select-action-hash="selectActionHash"
              :law-key="items.key"
              @select="handleLawCardSelect"
              @toLaw="showLawStart"
              @toLawDetail="showLawDetail"
              @jumpToList="jumpToList"
            />
          </div>
        </van-tab>
      </van-tabs>
      <div class="list">
        <appeal-items ref="appeal_detail" :options="options" :law-text="lawText" :law-title="lawTitle" @showDetail="showDetail" @HideAppealDetail="hideList" />
      </div>
      <div v-show="selectLawLength > 0" class="ana-top-actions">
        <div class="wrap">
          <div class="special" @click="actionCancel">取消({{ selectLawLength }})</div>
          <div class="action-btn" @click="actionFav">收藏</div>
          <div class="action-btn" @click="actionShare">分享</div>
          <div class="action-btn" @click="actionInsert" v-if="!$route.query.isMiniApp">插入</div>
          <div class="action-btn" @click="actionDownload" v-if="!$route.query.isMiniApp">下载</div>
        </div>
      </div>
    </div>
    <div v-if="skyDriveVisible" class="skyDrive">
      <skyDrive
        :listid="selectLawForAction.length > 0 ? selectLawForAction : null"
        :type="'LAW_FRAGMENT'"
        :modulename="'判决依据'"
        :listname="1"
        :summary="$store.state.ana.summary"
        @CancelCollect="cancelFav"
        @getId="openActionNotify"
      />
    </div>
    <div v-if="preview" class="pre">
      <preview :id="numid" :user-id="$store.state.user.userInfo.id" @clearpl="clearpl" />
    </div>
  </div>
</template>
<script>
// import VePie from 'v-charts/lib/ring.common'
// import elCheckBox from '@/pages/precedentDetail/tabs/checkBox'
import { caseBase, pydataBase } from '~api-config'
import { mapMutations } from 'vuex'
import appealItems from './appeal_items'
import preview from '@/pages/preview/index'
import LawCard from './lawCard'

export default {
  name: 'Law',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    skyDrive: () => import('@/pages/skyDrive/index'),
    appealItems,
    preview,
    LawCard,
  },
  props: ['selectedArray', 'viewMoreFlag', 'currentKey', 'selfKey', 'pageInfo', 'currentReportArr'],
  data() {
    return {
      state: '', // 控制台报错
      numid: 0,
      preview: false,
      skyDriveVisible: false,
      options: {},
      lawText: '',
      lawTitle: '',
      datalist: [],
      showIndex: 4,
      tabIndex: 0,
      height: '0px',
      optflag: false,
      chartSettings: {
        radius: ['55%', '75%'],
        offsetY: 0,
        label: {
          position: 'outside',
          fontSize: 12,
          formatter: '{b}: {d}%',
        },
      },
      selectArray: [],
      lengh: 0,
      tabname: '',
      appeallist: null,
      // tab数据复制
      tabDataCopy: [],
      // law-card选中的数组下标
      selectLawIndex: null,
      // law-card选中的子级数据数组
      selectLawList: [],
      // 选择操作的时间戳 用于law-card组件清除选中
      selectActionHash: 0,
      // 显示操作成功
      actionNotifyVisible: false,
      timer: null,
      actionNotifyMsg: {
        text: '收藏成功',
        link: '点击查看',
      },
      filterType: '全部',
      youxiaoData: [],
    }
  },
  computed: {
    // 当前tab页需要渲染的法律数据
    renderArray() {
      return this.selectedArray[this.tabIndex].values || []
    },
    // 选中法条的条数
    selectLawLength() {
      let num = 0
      this.selectLawList.forEach((item) => {
        num += 1
        if (item.selectedChildren && item.selectedChildren.length) {
          num += item.selectedChildren.length
        }
      })
      return num
    },
    // TODO 转换数据用于顶部工具栏操作
    selectLawForAction() {
      const data = this.selectLawList.map((law) => {
        const { law_id: lawid, name, checked: flag } = law
        return {
          flag,
          lawid,
          name,
          children: law.selectedChildren.map((item) => {
            const { category, law_id: lawid, title: name, flag, text } = item
            return { category, lawid, name, flag, text }
          }),
        }
      })
      return data
    },
    ua() {
      return navigator.userAgent
    },
  },
  methods: {
    ...mapMutations('user', ['setUserCurrentObj']),
    // 切换筛选有效法条和全部
    changeFilterType(type) {
      this.filterType = type
    },
    // 格式化时间 年-月-日 时：分：秒
    formatDate(fmt, date) {
      let ret
      const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      }
      for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
          fmt = fmt.replace(ret[1], ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
        }
      }
      return fmt
    },
    loadet() {
      const date1 = this.formatDate('YYYY年mm月dd日HH点MM分SS', new Date())
      const date = new Date()
      this.$axios
        .post(`${caseBase}/document/user/${this.$store.state.user.userInfo.id}/fragment`, {
          name: `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} 《法律依据》`,
          children: this.selectArray,
        })
        .then((res) => {
          // this.$toast('网址为：' + res.data.data.path)
          if (this.ua === this.$ua.a) {
            // eslint-disable-next-line
            AND2JS.showFilePreview(res.data.data.path)
          } else if (this.ua === 'fb-flutter-Webview') {
            // eslint-disable-next-linetodo0927
            try {
              // 接口请求返回数据的
              // 之前的的方法
              // window.fbDownloadFile.postMessage(res.data.data.path)
              // 新改的方法
              window.showFilePreview.postMessage(
                JSON.stringify({
                  filePath: res.data.data.path,
                  fileName: date1,
                })
              )
            } catch (error) {
              // console.log(error, 'fbDownloadFile.postMessage')
              console.log(error, 'showFilePreview.postMessage')
            }
          } else if (this.ua === this.$ua.i) {
            // ios环境
            // eslint-disable-next-line
            window.webkit.messageHandlers.fbDownloadFile.postMessage(res.data.data.path)
          }
        })
        .catch((err) => {
          this.$notify('分享失败')
          Promise.reject(err)
        })
    },
    give() {
      const ua = navigator.userAgent
      let content = ''
      for (var i = 0; i < this.selectLawForAction.length; i++) {
        content = content + '《' + this.selectLawForAction[i].name + '》'
        for (var j = 0; j < this.selectLawForAction[i].children.length; j++) {
          content = content + this.selectLawForAction[i].children[j].name + '"' + this.selectLawForAction[i].children[j].text + '"' + '，'
        }
        content = content.substring(0, content.length - 1)
        content = content + '，'
      }
      content = content.substring(0, content.length - 1)
      if (ua === this.$ua.a) {
        // eslint-disable-next-line
        AND2JS.toWrite(['中华人民共和国婚姻法', '第一条', '法条内容'])
      } else if (ua === 'fb-flutter-Webview') {
        // eslint-disable-next-line
        try {
          window.toWrite.postMessage(content)
        } catch (error) {
          console.log(error, 'fbDownloadFile.postMessage')
        }
      } else if (ua === this.$ua.i) {
        window.webkit.messageHandlers.JumpToDocument.postMessage(this.Iter(this.selectArray))
      }
    },
    Iter(arr) {
      let str = ''
      arr.forEach((item) => {
        if (item.name) {
          str += item.name
          str += '\n'
        }
        if (item.text) {
          str += item.text
          str += '\n'
        }
        if (item.children && item.children.length > 0) {
          str += this.Iter(item.children)
        }
      })
      return str
    },
    changechilden(flag, index) {
      if (flag) {
        console.log('已经选中')
        this.renderArray[index].data.forEach((item, num) => {
          this.$set(this.renderArray[index].data[num], 'flag', true)
        })
      } else {
        const Index = this.renderArray[index].data.findIndex((item) => {
          return !item.flag
        })
        if (Index === -1) {
          this.renderArray[index].data.forEach((item, num) => {
            this.$set(this.renderArray[index].data[num], 'flag', false)
          })
        } else {
          setTimeout(() => {
            this.$set(this.renderArray[index], 'flag', true)
          })
        }
      }
    },
    changeparent(index, num, flag) {
      if (flag) {
        this.$set(this.renderArray[index], 'flag', true)
      } else {
        const Index = this.renderArray[index].data.findIndex((item) => {
          return item.flag
        })
        if (Index === -1) {
          this.$set(this.renderArray[index], 'flag', false)
        }
      }
    },
    clearpl() {
      this.preview = false
    },
    getId(id) {
      this.cancel()
      this.actionNotifyMsg = {
        text: '收藏成功',
        link: '点击查看收藏',
      }
      this.actionNotifyVisible = true
      // this.$notify('收藏成功,点击查看收藏').addEventListener(() => {
      //   this.numid = id
      //   this.preview = true
      // })
    },
    close(flag) {
      this.skyDriveVisible = false
      if (flag) {
        setTimeout(() => {
          document.querySelector('.van-notify').style.zIndex = '10000000'
        }, 100)
      } else {
        this.$notify({
          background: 'rgb(25, 137, 250)',
          duration: 1000,
          message: '已取消收藏',
        })
        setTimeout(() => {
          document.querySelector('.van-notify').style.zIndex = '10000000'
        }, 100)
      }
    },
    save() {
      // 收藏
      this.skyDriveVisible = true
    },
    map(arr, callback) {
      arr.forEach((item, index) => {
        this.$set(item, 'flag', false)
        if (item.data) {
          this.map(item.data)
        }
      })
    },
    cancel() {
      if (this.$refs.appeal_detail.$el.style.top === '0px') {
        this.hideList()
      } else if (this.selectArray.length > 0) {
        this.loaded = true
        this.map(this.selectedArray[this.tabname].values)
        this.loaded = false
        const ua = navigator.userAgent
        if (ua === 'fb-Android-Webview') {
          // eslint-disable-next-line no-undef
          AND2JS.showBottomTab('hide')
        }
      } else {
        const ua = navigator.userAgent
        if (ua === 'fb-Android-Webview') {
          // eslint-disable-next-line
          AND2JS.back()
        } else if (ua === 'fb-flutter-Webview') {
          // eslint-disable-next-line
          try {
            window.back.postMessage('{}')
          } catch (error) {
            console.log('back.postMessage', error)
          }
        } else {
          window.webkit.messageHandlers.back.postMessage({})
        }
      }
    },
    download() {
      // 下载
      const date = new Date()
      this.$axios
        .post(`${caseBase}/document/user/${this.$store.state.user.userInfo.id}/fragment`, {
          name: `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} 《法律依据》`,
          children: this.selectArray,
        })
        .then((res) => {
          const ua = navigator.userAgent
          if (ua === 'fb-Android-Webview') {
            // eslint-disable-next-line no-undef
            AND2JS.shareCaseFile(
              `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} 《法律依据》`,
              window.encodeURI(`https://view.officeapps.live.com/op/view.aspx?src=${res.data.data.path}`)
            )
          } else {
            window.webkit.messageHandlers.shareCaseFile.postMessage(
              JSON.stringify({
                filename: `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} 《法律依据》`,
                url: window.encodeURI(`https://view.officeapps.live.com/op/view.aspx?src=${res.data.data.path}`),
              })
            )
          }
        })
        .catch((err) => {
          this.$notify('分享失败')
          Promise.reject(err)
        })
    },
    notify(message) {
      this.$notify({
        message: message,
        background: '#07c160',
        className: 'TOSAVELIST',
      })
      return {
        addEventListener(callback) {
          setTimeout(() => {
            document.querySelector('.TOSAVELIST').onclick = callback
          })
        },
      }
    },
    JumpToLawDetail(index, num, lawname, title) {
      this.changeflag(index, num, lawname, title)
    },
    showDetail(docid, words) {
      this.$emit('preDetail', docid, words, '')
    },
    hideList() {
      this.setUserCurrentObj(this)
      document.querySelectorAll('*').forEach((item) => {
        item.style['-webkit-overflow-scrolling'] = ''
      })
      this.$refs.appeal_detail.$el.style.top = '-100%'
      this.$refs.appeal_detail.$el.style.left = '-100%'
    },
    // 点击百分比
    jumpToList({ offset, text, lawTitle, lawText }) {
      document.querySelector('.appeal_list').style.zIndex = 10000
      // 点击判例列表
      document.querySelectorAll('*').forEach((item) => {
        item.style['-webkit-overflow-scrolling'] = 'auto'
      })
      this.lawTitle = lawTitle
      this.lawText = lawText
      // options  post 参数
      this.$set(this.options, 'offset', offset)
      this.$set(this.options, 'text', text)
      this.$set(this.options, 'key', this.selectedArray[this.tabIndex].key)
      this.$set(this.options, 'token', this.$store.state.ana.token)
      this.$set(this.options, 'summary', this.$store.state.ana.summary)
      this.$set(this.options, 'offset_child', 1)
      this.$refs.appeal_detail.$el.style.top = '0px'
      this.$refs.appeal_detail.$el.style.left = '0px'
    },
    ToDetail(text) {
      this.$toast({
        mask: true,
        type: 'loading',
        message: '加载中...',
      })
      this.$axios.get(`${pydataBase}/api/law/content?law=${text}`).then((res) => {
        this.$toast.clear()
        res = res.data
        this.$emit('lawDetail', res.law_id, res.result)
      })
    },
    async viewMore() {
      if (this.selectedArray[this.tabIndex].values) {
        if (this.showIndex < this.selectedArray[this.tabIndex].values.length) {
          this.showIndex = this.showIndex + 4
          return true
        } else {
          return false
        }
      } else if (this.selectedArray.length) {
        return false
      }
    },
    changeflag(index, num, lawname, title) {
      const load = this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中',
      })
      this.$axios.get(`${pydataBase}/api/law/content?law=${lawname}`).then((res) => {
        this.$set(this.renderArray[index].data[num], 'text', res.data.result)
        this.renderArray[index].data[num].showflag = !this.renderArray[index].data[num].showflag
        this.$emit('lawDetail', res.data.law_id, res.data.result, title, this.renderArray[index].name)
        load.clear()
      })
    },
    changeTab(index, name) {
      this.showIndex = 4
      this.$emit('resetScroll')
    },
    // 计算数据
    calcData(item) {
      const a = {}
      a.columns = ['name', 'value']
      a.rows = []
      let array = []
      if (item && item.series[0]) {
        array = item.series[0].data
      } else if (item && item.series) {
        array = item.series.data
      }
      array.forEach((element) => {
        const b = {}
        b.name = element.name
        b.value = element.value
        a.rows.push(b)
      })
      return a
    },
    // 获取v-charts图标位置
    getPosition() {
      const width = this.$el.clientWidth * 1.1
      this.height = width * 0.5 + 'px'
      this.chartSettings.offsetY = width * 0.285
      this.chartSettings.label.fontSize = width / 30
    },
    // #法律卡片#选择触发事件
    handleLawCardSelect(payload) {
      const { index, checked } = payload

      // 其他下标的数据
      const o = this.selectLawList.filter((item) => item.index !== index)

      if (checked) {
        this.selectLawList = [...o, payload]
      } else {
        this.selectLawList = [...o]
      }
    },
    // 获取法律数据 - 根据法律名称
    async getLawDataByName(name) {
      const { data: res } = await this.$axios.get(`${pydataBase}/api/law/content?law=${name}`)
      return res
    },
    // 显示法律开头  lawDetail(id, text, catlog, title) {
    async showLawStart(card) {
      const { name, law_id: id } = card
      this.$emit('lawDetail', id, name, name, name)
    },
    showLawDetail(payload) {
      const { id, catlog, title, text, topic } = payload
      this.$emit('lawDetail', id, text, catlog, title, topic)
    },
    // #顶部操作栏-取消#
    actionCancel() {
      this.clearSelection()
    },
    // #顶部操作栏-收藏#
    actionFav() {
      this.skyDriveVisible = true
    },
    cancelFav() {
      this.actionCancel()
      this.skyDriveVisible = false
    },
    // #顶部操作栏-分享#
    actionShare() {
      console.log(this.selectLawForAction)
      const ua = this.ua
      // if (ua !== this.$ua.a && ua !== this.$ua.i) {
      //   this.$notify('非原生端不支持 分享')
      //   return
      // }
      const date = new Date()
      this.$axios
        .post(`${caseBase}/document/user/${this.$store.state.user.userInfo.id}/fragment`, {
          name: `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} 《法律依据》`,
          children: this.selectLawForAction,
        })
        .then((res) => {
          const name = `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} 《法律依据》`
          if (ua === 'fb-flutter-Webview') {
            // eslint-disable-next-line
            try {
              window.shareCaseFile.postMessage(
                JSON.stringify({
                  filename: name,
                  url: window.encodeURI(`https://view.officeapps.live.com/op/view.aspx?src=${res.data.data.path}`),
                })
              )
            } catch (error) {
              console.log(error, 'fbDownloadFile.postMessage')
            }
          } else if (ua === this.$ua.a) {
            // eslint-disable-next-line no-undef
            AND2JS.shareCaseFile(name, window.encodeURI(`https://view.officeapps.live.com/op/view.aspx?src=${res.data.data.path}`))
          } else if (ua === this.$ua.i) {
            window.webkit.messageHandlers.shareCaseFile.postMessage(
              JSON.stringify({
                filename: name,
                url: window.encodeURI(`https://view.officeapps.live.com/op/view.aspx?src=${res.data.data.path}`),
              })
            )
          } else {
            const text = res.data.data.path
            const transfer = document.createElement('input')
            document.body.appendChild(transfer)
            transfer.value = text // 这里表示想要复制的内容
            transfer.select()
            if (document.execCommand('copy')) {
              document.execCommand('copy')
            }
            transfer.blur()
            document.body.removeChild(transfer)
            this.$notify({
              type: 'success',
              duration: 1000,
              message: '已复制链接，粘贴发送给他人',
            })
          }
        })
        .catch((err) => {
          this.$notify('分享失败')
          Promise.reject(err)
        })
    },
    // #顶部操作栏-插入#
    actionInsert() {
      const ua = navigator.userAgent
      let content = ''
      for (var i = 0; i < this.selectLawForAction.length; i++) {
        content = content + '《' + this.selectLawForAction[i].name + '》'
        for (var j = 0; j < this.selectLawForAction[i].children.length; j++) {
          content = content + this.selectLawForAction[i].children[j].name + '"' + this.selectLawForAction[i].children[j].text + '"' + '，'
        }
        content = content.substring(0, content.length - 1)
        content = content + '，'
      }
      content = content.substring(0, content.length - 1)
      if (ua === this.$ua.a) {
        // eslint-disable-next-line
        AND2JS.toWrite([content])
      } else if (ua === 'fb-flutter-Webview') {
        // eslint-disable-next-line
        try {
          window.toWrite.postMessage(content)
        } catch (error) {
          console.log(error, 'fbDownloadFile.postMessage')
        }
      } else if (ua === this.$ua.i) {
        window.webkit.messageHandlers.JumpToDocument.postMessage(this.Iter(this.selectLawForAction))
      }
    },
    // #顶部操作栏-下载#
    actionDownload() {
      console.log(this.selectLawForAction)
      // if (this.ua !== this.$ua.a && this.ua !== this.$ua.i) {
      //   this.$notify('非原生端不支持 下载')
      //   return
      // }
      const date = new Date()
      // console.log('下载接口', this.selectLawForAction)
      const params = {
        name: `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}《法律依据》`,
        children: this.selectLawForAction,
      }
      if (this.$store.state.user.userInfo.organization && this.$store.state.user.userInfo.organization.name) {
        params.subTitle = this.$store.state.user.userInfo.organization.name
      }
      this.$axios
        .post(`${caseBase}/document/user/${this.$store.state.user.userInfo.id}/fragment`, params)
        .then((res) => {
          const url = res.data.data.path
          const name = url.substring(url.lastIndexOf('/') + 1)
          const pathUrl = url.substring(0, url.lastIndexOf('/'))
          // this.$toast('网址为：' + res.data.data.path)
          const ua = navigator.userAgent
          if (ua === this.$ua.a) {
            // eslint-disable-next-line
            AND2JS.showFilePreview(res.data.data.path)
          } else if (ua === 'fb-flutter-Webview') {
            // eslint-disable-next-linetodo0927
            try {
              // 之前的方法
              // window.fbDownloadFile.postMessage(res.data.data.path)
              // 新改的方法
              window.showFilePreview.postMessage(
                JSON.stringify({
                  filePath: pathUrl,
                  fileName: name,
                })
              )
            } catch (error) {
              // console.log(error, 'fbDownloadFile.postMessage')
              console.log(error, 'showFilePreview.postMessage')
            }
          } else if (ua === this.$ua.i) {
            // ios环境
            // eslint-disable-next-line
            window.webkit.messageHandlers.fbDownloadFile.postMessage(res.data.data.path)
          } else {
            this.$notify('异常，暂无法使用')
          }
          // this.$notify({
          //   message: '文件保存到手机内存/Download路径下',
          //   background: '#1989fa'
          // })
        })
        .catch((err) => {
          this.$notify('下载失败')
          Promise.reject(err)
        })
    },
    // 清除选择状态数据
    clearSelection() {
      this.selectLawList = []
      this.selectActionHash = Date.now()
    },
    openActionNotify(id) {
      this.actionNotifyMsg = {
        text: '收藏成功',
        link: '点击查看收藏',
      }
      this.numid = id
      this.actionNotifyVisible = true
      this.timer = setTimeout(() => {
        this.actionNotifyVisible = false
      }, 3e3)
    },
    onNotifyLinkClick() {
      this.actionNotifyVisible = false
      this.preview = true
    },
  },
  watch: {
    skyDrive(val) {
      if (val) {
        document.querySelectorAll('*').forEach((item) => {
          item.style['-webkit-overflow-scrolling'] = 'auto'
        })
      } else {
        document.querySelectorAll('*').forEach((item) => {
          item.style['-webkit-overflow-scrolling'] = ''
        })
      }
    },
    optflag(val) {
      if (val) {
        const u = navigator.userAgent
        if (u === 'fb-Android-Webview') {
          // eslint-disable-next-line
          AND2JS.setStateBarAndSysNavigationBarVisibility('invisible')
        }
        document.querySelectorAll('*').forEach((item) => {
          item.style['-webkit-overflow-scrolling'] = 'auto'
        })
      } else {
        const u = navigator.userAgent
        if (u === 'fb-Android-Webview') {
          // eslint-disable-next-line
          AND2JS.setStateBarAndSysNavigationBarVisibility('visible')
        }
        document.querySelectorAll('*').forEach((item) => {
          item.style['-webkit-overflow-scrolling'] = ''
        })
      }
    },
    tabIndex(val) {
      this.tabname = val
      // this.clearSelection()
    },
    // 如果有选择的法条则进入全屏模式
    selectLawLength(nv) {
      if (nv > 0) {
        if (this.ua === this.$ua.a) {
          // eslint-disable-next-line
          AND2JS.setStateBarAndSysNavigationBarVisibility('invisible')
        }
      } else {
        if (this.ua === this.$ua.a) {
          // eslint-disable-next-line
          AND2JS.setStateBarAndSysNavigationBarVisibility('visible')
        }
      }
    },
    selectedArray: {
      deep: true,
      immediate: true,
      handler(val) {
        val.forEach((data) => {
          if (!data.values && !data.values?.length) return
          if (data) {
            data.values.forEach((item) => {
              item.data.forEach((iter, index) => {
                const wordIndex = iter.percent.indexOf('篇')
                iter.pieceNum = parseInt(iter.percent.substr(0, wordIndex))
              })
              const list = item.data.slice(0)
              // 外层循环，控制趟数，每一次找到一个最大值
              for (var i = 0; i < list.length - 1; i++) {
                // 内层循环,控制比较的次数，并且判断两个数的大小
                for (var j = 0; j < list.length - 1 - i; j++) {
                  // 白话解释：如果前面的数大，放到后面(当然是从小到大的冒泡排序)
                  if (list[j].pieceNum < list[j + 1].pieceNum) {
                    var temp = list[j]
                    list[j] = list[j + 1]
                    list[j + 1] = temp
                  }
                }
              }
              item.data2 = list.splice(0, 10)
            })
          }
        })

        this.youxiaoData = []
        val.forEach((item, index) => {
          // 数据不是法律依据就退出
          if (!item.name && !item.key) return
          // 没有相关法条的就添加name和key
          if (!item.values.length) {
            this.youxiaoData[index] = { name: item.name, key: item.key }
            return
          }
          // 循环法条，筛选出现行有效
          item.values.forEach((ite) => {
            if (ite.timeliness === '现行有效') {
              if (this.youxiaoData[index]?.name) {
                this.youxiaoData[index].values.push(ite)
              } else {
                this.youxiaoData[index] = { name: item.name, key: item.key, values: [ite] }
              }
            }
          })
          // 当一个类别下所有的法条都不是现行有效的话，加上缺少的类别
          this.youxiaoData.map((ele, i, arr) => {
            if (!arr[index]) {
              arr[index] = { name: item.name, key: item.key }
            }
          })
        })
      },
    },
    /*
    selectedArray: {
      deep: true,
      handler(val) {
        if (this.loaded) {
          return false
        }
        let testData = val.length > 0 ? val[this.tabIndex].values : []
        this.selectArray = []
        testData.forEach(item => {
          let obj = {}
          if (item.flag) {
            obj = {
              lawid: item.law_id,
              name: item.name,
              text: '',
              type: 0,
              children: []
            }
          }
          item.data.forEach(itemr => {
            if (itemr.flag) {
              if (Object.keys(obj).length === 0) {
                obj = {
                  lawid: item.law_id,
                  name: item.name,
                  text: '',
                  type: 0,
                  children: []
                }
              }
              obj.children.push({
                name: itemr.title,
                lawid: item.law_id,
                type: 4,
                text: itemr.text,
                children: []
              })
            }
          })
          if (Object.keys(obj).length > 0) {
            this.selectArray.push(obj)
          }
        })
        if (this.selectArray.length > 0) {
          this.lengh = getlen(this.selectArray)
          this.optflag = true
          const ua = navigator['userAgent']
          if (ua === 'fb-Android-Webview') {
            // eslint-disable-next-line no-undef
            AND2JS.showBottomTab('hide')
          }
        } else {
          this.optflag = false
          const ua = navigator['userAgent']
          if (ua === 'fb-Android-Webview') {
            // eslint-disable-next-line no-undef
            AND2JS.showBottomTab('display')
          }
        }
        function getlen(arr) {
          let len = 0
          len += arr.length
          arr.forEach(item => {
            if (item.children.length) {
              len += getlen(item.children)
            }
          })
          return len
        }
      }
    }
    */
  },
  created() {
    this.$nextTick(() => {
      this.tabname = this.tabIndex
      this.getPosition()
      this.setUserCurrentObj(this)
    })
  },
}
</script>
<style scoped lang="stylus">
.law
  padding 10px 0
  & .law-filter
    font-size: 14px;
    color: #999999;
    padding 10px 0 0 0
    box-sizing border-box
    display flex
    justify-content flex-end
  & .ana-top-actions
    width 100%
    height 40px
    position fixed
    z-index 1000
    top 0
    left 0px
    background-color white
    background-color #f6f6f6
    transition all 0.5s
    display flex
    align-items center
    & .wrap
      height 24px
      flex 1
      display flex
      align-items center
      justify-content space-around
      & .action-btn
        height 24px
        line-height 24px
        padding 0 6px
        font-size 14px
        margin-right 10px
        background linear-gradient(#4B9EFB, #1983FB)
        color white
        text-align center
        box-sizing border-box
        border-radius 4px
        align-self center
        &:last-child
          margin-right 0
        & .special
          background transparent
          color #569af9
          float left
.pre
  position fixed
  top 0px
  background #ffffff
  z-index 10000000
  width 100%
  left 0px
  height 100%
  overflow hidden
.skyDrive
  position fixed
  top 0px
  background #ffffff
  z-index 10000000
  width 100%
  left 0px
  height 100%
  overflow hidden
.TOSAVELIST
  z-index 10000001
</style>
