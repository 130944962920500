<template>
  <div class="lawyer">
    <div v-for="(item,index) in renderArray"
         :key="index"
         class="border-bottom cell">
      <span>{{item.name}}</span>
      <span>{{item.num}}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'lawyer',
  props: ['selectedArray', 'viewMoreFlag', 'currentKey', 'selfKey', 'pageInfo'],
  data () {
    return {
      showIndex: 10
    }
  },
  computed: {
    renderArray () {
      console.log(this.selectedArray)
      return this.selectedArray.filter((item, index) => {
        return true
      })
    }
  },
  methods: {
    async viewMore () {
      if (this.showIndex < this.selectedArray.length) {
        this.showIndex = this.showIndex + 10
        return true
      } else if (this.selectedArray.length) {
        return false
      }
    }
  },
  mounted () {
    console.log(this.selfKey)
  }
}
</script>
<style scoped lang="stylus">
.cell
  font-size 15px
  padding 10px
  display flex
  align-items center
  justify-content space-between
</style>
