<template>
  <div class="fb-document">
    <document-goods v-if="type === 0" @openFocus="openFocus" @openPay="openPay" @close="close" />
    <focus v-else :summary="summary" :sentence="sentence" :input_keyword="input_keyword" :condition="condition || []" :documentObj="documentObj" @back="back" />
  </div>
</template>
<script>
import documentGoods from './document-goods'
import focus from './focus'
export default {
  name: 'fb-document',
  components: {
    documentGoods,
    focus,
  },
  props: ['summary', 'sentence', 'input_keyword', 'condition'],
  data() {
    return {
      type: 0,
      documentObj: {},
    }
  },
  computed: {},
  methods: {
    // 关闭遮罩层
    close() {
      this.$emit('close')
    },
    // 返回文书商品
    back() {
      this.type = 0
    },
    // 打开争议焦点
    openFocus(documentObj) {
      this.documentObj = documentObj
      this.type = 1
    },
    // 原生端打开支付
    openPay(item) {
      console.log('原始数据====>', item)
      const params = JSON.stringify(item)
      console.log('JSON字符串化以后的数据====>', params)
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.documentPay(params)
      } else if (ua === 'fb-flutter-Webview') {
        // eslint-disable-next-linetodo0927
        try {
          window.documentPay.postMessage(params)
        } catch (error) {
          console.log(error, 'fbDownloadFile.postMessage')
        }
      }
    },
    // 支付成功以后接受的原生端的回调
    documentPayResultCallBack(data) {
      console.log('接受到的原生端的返回数据====>', data)
      this.documentObj = JSON.parse(data)
      this.type = 1
    },
  },
  mounted() {},
  created() {
    // 关联组件内方法ocrCaseResultCallback 至 JSBridge/uploadFiles
    this.$hook(this.$bridge.documentPaySuccess, this.documentPayResultCallBack)
    // 组件销毁的时候释放桥接方法
    this.$once('hook:destroyed', () => {
      this.$unhook(this.$bridge.documentPaySuccess)
    })
  },
}
</script>
<style lang="stylus" scoped>
.fb-document
  position: relative
  width 100%
  height 100%
</style>
