var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "appeal" },
    [
      _vm._l(this.selectlist, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "cell", attrs: { div: index } },
          [
            _c(
              "div",
              { staticClass: "box" },
              [
                _c("el-checkBox", {
                  on: {
                    change: function ($event) {
                      return _vm.chooseItem($event, item)
                    },
                  },
                  model: {
                    value: item.flag,
                    callback: function ($$v) {
                      _vm.$set(item, "flag", $$v)
                    },
                    expression: "item.flag",
                  },
                }),
              ],
              1
            ),
            _c("div", {
              staticClass: "text",
              style: { display: item.showflag ? "" : "-webkit-box" },
              domProps: { innerHTML: _vm._s(item.text) },
              on: {
                click: function ($event) {
                  return _vm.yuanwen(item.doc_id, item.text)
                },
              },
            }),
            _c("div", { staticClass: "footer" }, [
              _c(
                "span",
                {
                  staticClass: "see",
                  on: {
                    click: function ($event) {
                      return _vm.slide(index)
                    },
                  },
                },
                [_vm._v(_vm._s(item.showflag ? "收起" : "展开"))]
              ),
            ]),
            _c("div", { staticClass: "deem-tit-div" }, [
              _c("span", { staticClass: "deem-tit-casename" }, [
                _vm._v(_vm._s(item.case_name)),
              ]),
              _c("span", { staticClass: "deem-tit-caseid" }, [
                _vm._v(_vm._s(item.case_id)),
              ]),
            ]),
          ]
        )
      }),
      _c(
        "div",
        {
          staticClass: "options",
          style: { top: _vm.optflag ? "0px" : "-100%" },
        },
        [
          _c("div", { staticClass: "speciall", on: { click: _vm.cancel } }, [
            _vm._v("取消(" + _vm._s(_vm.length) + ")"),
          ]),
          _c("div", { on: { click: _vm.save } }, [_vm._v("收藏")]),
          _c("div", { on: { click: _vm.download } }, [_vm._v("分享")]),
          !_vm.$route.query.isMiniApp
            ? _c("div", { on: { click: _vm.give } }, [_vm._v("插入")])
            : _vm._e(),
          !_vm.$route.query.isMiniApp
            ? _c("div", { on: { click: _vm.loadet } }, [_vm._v("下载")])
            : _vm._e(),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }