<template>
  <div class="twoDetail">
    <div class="options" :style="{ top: optflag ? '0px' : '-100%' }">
      <div @click="cancel" class="special">取消({{ lengh }})</div>
      <div @click="save">收藏</div>
      <div @click="download">分享</div>
      <div @click="give" v-if="!$route.query.isMiniApp">插入</div>
      <div @click="loadet" v-if="!$route.query.isMiniApp">下载</div>
    </div>
    <!-- <two-pie v-for="(item,index) in randerArray"
             :key="'twoPie'+index"
             :option="item"
             :selfKey="selfKey"
             :currentKey="currentKey"
             :summary="summary"
             :token="token"
             :tabIndex="tabIndex"
             :pageInfo="pageInfo" /> -->
    <div v-for="(item, index) in dataList" :key="index" :div="index" class="info">
      <div v-for="(itemval, indexval) in item.case" :key="indexval" v-show="item.showflag || indexval === 0">
        <elCheckBox v-model="itemval.flag" v-show="indexval === 0" @change="chooseItem($event, item)"></elCheckBox>
        &nbsp;
        <div class="information" @click="toDetail(itemval.text, itemval.doc_id)">
          <b>{{ itemval.text }}</b>
          <div class="focus-tit-div">
            <span class="focus-tit-caseid">{{ itemval.case_id }} </span>
            <span class="focus-tit-casename"> {{ itemval.case_name }}</span>
          </div>
          <div v-show="item.showflag" class="line"></div>
        </div>
      </div>
      <div class="loaing-panel" v-show="item.loadingMore">
        <van-loading />
      </div>
      <div class="show">
        <div class="focus-tit-div">
          <span class="focus-tit-caseid">{{ item.case_id }} </span>
          <span class="focus-tit-casename"> {{ item.case_name }}</span>
        </div>
        <div class="percet" style="float: left">
          <i class="iconfont icon-fenxi"></i>
          {{ item.percent }}
        </div>
        <span @click="showmore(item, index)" style="float: right" v-if="Array.isArray(item.case) ? item.case.length > 0 : false">{{ item.showflag ? '收起' : '查看更多' }}</span>
      </div>
      <div v-show="!item.showflag" class="line"></div>
    </div>
  </div>
</template>
<script>
// import twoPie from './twoPie'
import { caseBase, pydataBase } from '~api-config'
import { mapMutations, mapState, mapActions } from 'vuex'
import elCheckBox from '@/pages/precedentDetail/tabs/checkBox'
export default {
  name: 'twoDetail',
  props: ['selectedArray', 'viewMoreFlag', 'currentKey', 'selfKey', 'summary', 'token', 'tabIndex', 'pageInfo', 'moreParam', 'currentReportArr'],
  components: {
    // twoPie
    elCheckBox,
  },
  data() {
    return {
      showIndex: 4,
      dataList: [],
      optflag: false,
      selectArr: [],
      loaded: 0,
      lengh: 0,
      Loaded: false,
    }
  },
  computed: {
    ...mapState('searchReport', ['anaReports']),
    morelist() {
      return this.give_data()
    },
  },
  methods: {
    ...mapMutations('user', ['setUserCurrentObj']),
    ...mapMutations('searchReport', ['SET_ANA_REPORT']),
    ...mapActions('searchReport', ['setStampReport']),
    // 选中计数
    chooseItem(flag, item) {
      const arr = [...this.currentReportArr]
      if (arr.length) {
        const index = arr.findIndex((val) => val.doc_id === item.case[0].doc_id)
        if (index !== -1) {
          if (!flag) {
            arr.splice(index, 1)
          }
        } else {
          if (flag) {
            arr.push({
              type: 'focus',
              title: item.case[0].text,
              text: item.case[0].text,
              id: `focus-${item.case[0].doc_id}`,
              doc_id: item.case[0].doc_id,
              anaArr: [{}],
            })
          }
        }
      } else {
        if (flag) {
          arr.push({
            type: 'focus',
            title: item.case[0].text,
            text: item.case[0].text,
            id: `focus-${item.case[0].doc_id}`,
            doc_id: item.case[0].doc_id,
            anaArr: [{}],
          })
        }
      }
      const otherArr = this.anaReports.filter((v) => v.type !== 'focus')
      if (this.$store.state.user.userInfo.id) {
        this.setStampReport({
          userId: this.$store.state.user.userInfo.id,
          type: 'anaReports',
          reportData: [...otherArr, ...arr],
        })
      } else {
        this.SET_ANA_REPORT([...otherArr, ...arr])
      }
    },
    // 初始化选中
    initSelect() {
      const currentReportArrIds = this.currentReportArr.map((v) => v.doc_id)
      this.dataList.forEach((item) => {
        if (item.case && item.case.length) {
          const docid = item.case[0].doc_id
          if (currentReportArrIds.indexOf(docid) !== -1) {
            console.log(item)
            item.flag = true
            item.case[0].flag = true
          } else {
            item.flag = false
          }
        }
      })
    },
    loadet() {
      const date = new Date()
      const data = {
        name: `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} 《${this.selfKey === 'evt' ? '证据名称' : '争议焦点'}》`,
        children: this.selectArr,
      }
      console.log('下载入参是===>>>', data)
      this.$axios
        .post(`${caseBase}/document/user/${this.$store.state.user.userInfo.id}/fragment`, data)
        .then((res) => {
          console.log(res.data, '====>>> 接口返参05')
          const url = res.data.data.path
          const name = url.substring(url.lastIndexOf('/') + 1)
          const pathUrl = url.substring(0, url.lastIndexOf('/'))
          const ua = navigator.userAgent
          if (ua === 'fb-Android-Webview') {
            // eslint-disable-next-line
            AND2JS.showFilePreview(res.data.data.path)
          } else if (ua === 'fb-iOS-Webview') {
            // ios环境
            // eslint-disable-next-line
            window.webkit.messageHandlers.fbDownloadFile.postMessage(res.data.data.path)
          } else if (ua === 'fb-flutter-Webview') {
            // eslint-disable-next-line

            try {
              // 接口请求返回数据的
              // 之前写的
              // window.fbDownloadFile.postMessage(res.data.data.path)
              // 新改的方法
              window.showFilePreview.postMessage(
                JSON.stringify({
                  filePath: pathUrl,
                  fileName: name,
                })
              )
            } catch (error) {
              // console.log('fbDownloadFile.postMessage', error)
              console.log('showFilePreview.postMessage', error)
            }
          } else {
            this.$toast('网址为：' + res.data.data.path, res)
          }
        })
        .catch((err) => {
          this.$notify('下载失败')
          Promise.reject(err)
        })
    },
    give() {
      const ua = navigator.userAgent
      console.log('selfKey 争议焦点', this.selfKey, this.selectArr)
      let content = ''
      if (this.selfKey === 'focus') {
        this.selectArr.forEach((item) => {
          content += item.text + '，'
        })
        content = content.substring(0, content.length - 1)
      }
      if (ua === 'fb-Android-Webview') {
        // eslint-disable-next-line
        AND2JS.toWrite([content])
      } else if (ua === 'fb-flutter-Webview') {
        // eslint-disable-next-line
        try {
          window.toWrite.postMessage(content)
        } catch (error) {
          console.log(error, 'fbDownloadFile.postMessage')
        }
      } else {
        window.webkit.messageHandlers.JumpToDocument.postMessage(this.Iter(this.selectArr))
      }
    },
    Iter(arr) {
      let str = ''
      arr.forEach((item) => {
        if (item.name) {
          str += item.name
          str += '\n'
        }
        if (item.text) {
          str += item.text
          str += '\n'
        }
        if (item.children && item.children.length > 0) {
          str += this.Iter(item.children)
        }
      })
      return str
    },
    map(arr) {
      arr.forEach((item, index) => {
        this.$set(item, 'flag', false)
        if (item.title) {
          this.map(item.title)
        }
      })
    },
    cancel() {
      this.Loaded = true
      this.map(this.dataList)
      this.lengh = 0
      setTimeout(() => {
        this.Loaded = false
        this.optflag = false
        // eslint-disable-next-line no-undef
        AND2JS.showBottomTab('display')
      })
    },
    save() {
      // this.next()
      this.$emit('saveOptions', this.selectArr, this.selfKey === 'evt' ? '证据名称' : '争议焦点')
    },
    // 暂未使用的方法
    next() {
      this.$toast({
        mask: true,
        type: 'loading',
        message: '获取参数中...',
      })
      this.selectArr.forEach((item, index) => {
        this.$axios
          .post(
            `${pydataBase}/api/web/cal_similitude/more?sentence=${this.$store.state.ana.caseName}&summary=${this.$store.state.ana.summary}&key=${this.selfKey}&offset=1&condition=&text=${item.text}`
          )
          .then((res) => {
            res = res.data.result.datas[0]
            this.selectArr[index].docid = res ? res.doc_id : ''
            if (
              this.selectArr.findIndex((itemr) => {
                return itemr.text.trim().length === 0
              }) === -1
            ) {
              const arr = []
              this.selectArr.forEach((item) => {
                arr.push({
                  docid: item.docid,
                  name: `${item.name}`,
                  text: item.text,
                })
              })
              this.$toast.clear()
              this.$emit('saveOptions', arr, this.selfKey === 'evt' ? '证据名称' : '争议焦点')
            }
          })
      })
    },
    download() {
      const date = new Date()
      this.$axios
        .post(`${caseBase}/document/user/${this.$store.state.user.userInfo.id}/fragment`, {
          name: `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} 《${this.selfKey === 'evt' ? '证据名称' : '争议焦点'}》`,
          children: this.selectArr,
        })
        .then((res) => {
          const ua = navigator.userAgent
          if (ua === 'fb-Android-Webview') {
            // eslint-disable-next-line no-undef
            AND2JS.shareCaseFile(
              `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} 《${this.selfKey === 'evt' ? '证据名称' : '争议焦点'}》`,
              window.encodeURI(`https://view.officeapps.live.com/op/view.aspx?src=${res.data.data.path}`)
            )
          } else {
            if (this.$route.query.isMiniApp) {
              const text = res.data.data.path
              const transfer = document.createElement('input')
              document.body.appendChild(transfer)
              transfer.value = text // 这里表示想要复制的内容
              transfer.select()
              if (document.execCommand('copy')) {
                document.execCommand('copy')
              }
              transfer.blur()
              document.body.removeChild(transfer)
              this.$notify({
                type: 'success',
                duration: 1000,
                message: '已复制链接，粘贴发送给他人',
              })
            } else {
              window.webkit.messageHandlers.shareCaseFile.postMessage(
                JSON.stringify({
                  filename: `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} 《${
                    this.selfKey === 'evt' ? '证据名称' : '争议焦点'
                  }》`,
                  url: window.encodeURI(`https://view.officeapps.live.com/op/view.aspx?src=${res.data.data.path}`),
                })
              )
            }
          }
          // eslint-disable-next-line no-undef
        })
    },
    toDetail(text, docid) {
      this.$toast({
        mask: true,
        type: 'loading',
        message: '加载中...',
      })
      this.$emit('preDetail', docid, text, this.selfKey)
    },
    async viewMore() {
      if (this.showIndex < this.selectedArray.length) {
        this.showIndex += 4
        return true
      } else if (this.selectedArray.length) {
        return false
      }
    },
    give_data() {
      // this.dataList = this.selectedArray.filter((item, index) => {
      //   return index < this.showIndex
      // })
      this.dataList = this.selectedArray
      this.dataList.forEach((item, index) => {
        this.$set(this.dataList[index], 'showflag', false)
      })
      return this.dataList
    },
    showmore(item, index) {
      if (item.showflag || item.case.length > 1) {
        this.dataList = this.dataList.map((v, i) => (i === index ? { ...v, showflag: !v.showflag } : v))
      } else {
        const text = item.case[0] && item.case[0].text
        const data = {
          ...this.moreParam,
          key: 'focus',
          text: text,
        }
        this.$set(this.dataList[index], 'loadingMore', true)
        this.$axios
          .post(`${pydataBase}/api/ai/semantic/more`, data)
          .then((res) => {
            console.log(res, 'res.data=====')
            if (res.data && res.data.code === 200) {
              this.dataList = this.dataList.map((v, i) => {
                if (i === index) {
                  const { percent } = v
                  return {
                    percent,
                    case: [v.case[0], ...res.data.result],
                    showflag: !v.showflag,
                  }
                } else {
                  return v
                }
              })
              this.initSelect()
            }
            this.$set(this.dataList[index], 'loadingMore', false)
          })
          .catch((err) => {
            this.$set(this.dataList[index], 'loadingMore', false)
            console.log(err)
          })
      }
    },
    created() {
      this.setUserCurrentObj(this)
    },
  },
  watch: {
    selectedArray: {
      handler(val) {
        if (!val.length) return
        if (this.loaded > 1 && this.loaded < 3) {
          this.loaded++
          return false
        } else {
          this.loaded++
        }
        if (this.Loaded) {
          return false
        }
        this.dataList = val
        this.dataList.forEach((item, index) => {
          if (typeof item.showflag === 'boolean') {
            return
          }
          this.$set(this.dataList[index], 'showflag', false)
          if (item.case) {
            item.case.forEach((itemr, num) => {
              this.$set(this.dataList[index].case, num, {
                text: itemr.text,
                doc_id: itemr.doc_id,
                flag: false,
              })
            })
          }
        })
      },
      immediate: true,
    },
    dataList: {
      deep: true,
      immediate: true,
      handler(val) {
        if (this.Loaded) {
          return false
        }
        this.selectArr = []
        val.forEach((item) => {
          if (item.case) {
            item.case.forEach((item) => {
              if (item.flag) {
                this.selectArr.push({
                  text: item.text,
                  docid: '',
                  name: this.selfKey === 'focus' ? '争议焦点' : '证据名称',
                  children: [],
                  type: 0,
                })
              }
            })
          }
        })
        this.lengh = this.selectArr.length
        if (val && val.length) {
          this.initSelect()
        }
        if (this.selectArr.length > 0) {
          this.optflag = true
          const ua = navigator.userAgent
          if (ua === 'fb-Android-Webview') {
            // eslint-disable-next-line no-undef
            AND2JS.showBottomTab('hide')
          }
        } else {
          this.optflag = false
          const ua = navigator.userAgent
          if (ua === 'fb-Android-Webview') {
            // eslint-disable-next-line no-undef
            AND2JS.showBottomTab('display')
          }
        }
      },
    },
    optflag(val) {
      if (val) {
        document.querySelectorAll('*').forEach((item) => {
          item.style['-webkit-overflow-scrolling'] = 'auto'
        })
      } else {
        document.querySelectorAll('*').forEach((item) => {
          item.style['-webkit-overflow-scrolling'] = ''
        })
      }
    },
  },
}
</script>
<style scoped lang="stylus">
.twoDetail
  padding-top 10px
.info
  width 100%
  overflow hidden
  margin-bottom 20px
  position relative
  .information
    width 100%
    -webkit-line-clamp 3
    -webkit-box-orient vertical
    display inline
    vertical-align 7px
    overflow hidden
  .line
    width 90%
    margin 10px 0 20px
    height 0
    border-top 1px solid #ccc
  .show
    margin-top 10px
    width 100%
    text-align right
    overflow hidden
    .percet
      color #999999
    span
      color #4B9EFB
      font-size 12px
.loaing-panel
  position absolute
  left 0
  top 0
  bottom 0
  right 0
  z-index 9
  display flex
  background-color rgba(255,255,255,0.5)
  justify-content center
  align-items center
.options
  width 100%
  height 40px
  position fixed
  z-index 1000
  top 0px
  left 0px
  background-color white
  background-color #f6f6f6
  transition all 0.5s
  line-height 40px
  div
    display inline-block
    height 20px
    line-height 10px
    display inline-block
    font-size 14px
    margin-right 10px
    background linear-gradient(#4B9EFB, #1983FB)
    color white
    text-align center
    box-sizing border-box
    padding 5px 10px 5px 10px
    border-radius 99px
    align-self center
    float right
    margin-top 10px
  .special
    background transparent !important
    color #333
    float left
.focus-tit-div {
  font-size: 12px;
  color: #cccccc;
  display: flex;
  font-size: 12px;
}
.focus-tit-casename {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50%;
}
.focus-tit-caseid {
  margin-right: 20px;
  width: 50%;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
