var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "topCase" },
    _vm._l(_vm.array, function (item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass: "cell",
          on: {
            click: function ($event) {
              return _vm.goDetail(item.doc_id)
            },
          },
        },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v(" " + _vm._s(item.case_name) + " "),
          ]),
          _c("div", { staticClass: "content" }, [
            _vm._v(" " + _vm._s(item.result) + " "),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }