var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "one", staticClass: "one" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "container", class: { paddingAdd: _vm.inScrollMenu } },
        [
          _vm.analysisData.caseList && _vm.analysisData.caseList.length
            ? _c(
                "div",
                _vm._l(
                  _vm.calcArray(_vm.analysisData.caseList),
                  function (item, index) {
                    return _c(
                      "div",
                      { key: "singlePie" + index },
                      [
                        _c("single-pie", {
                          attrs: {
                            memberNum: _vm.memberNum,
                            option: item,
                            index: index,
                          },
                          on: { caseCellClick: _vm.caseCellClick },
                        }),
                      ],
                      1
                    )
                  }
                ),
                0
              )
            : _vm._e(),
          !_vm.isWechat
            ? _c("div", { staticClass: "container-no" }, [
                !_vm.analysisData.caseList || !_vm.analysisData.caseList.length
                  ? _c("div", { staticStyle: { "margin-bottom": "14px" } }, [
                      _vm._v(
                        "暂未匹配到相关内容,请调整输入内容或者切换案件类型"
                      ),
                    ])
                  : _c(
                      "div",
                      {
                        staticStyle: {
                          "text-align": "left",
                          "margin-bottom": "14px",
                        },
                      },
                      [_vm._v("以上都不是，找其他类型案件")]
                    ),
                _c("div", { staticClass: "ana-case-type" }, [
                  _c(
                    "div",
                    { staticClass: "ana-case-type-list" },
                    _vm._l(_vm.typeOptions, function (item) {
                      return _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.value !== _vm.is_type,
                              expression: "item.value !== is_type",
                            },
                          ],
                          key: item.value,
                          staticClass: "ana-case-type-name",
                          style: `background:${item.background}`,
                          on: {
                            click: function ($event) {
                              return _vm.changeTypeValue(item)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.label) + " ")]
                      )
                    }),
                    0
                  ),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _c("fb-nonmember", {
        attrs: { showLimit: _vm.showLimit },
        on: {
          "update:showLimit": function ($event) {
            _vm.showLimit = $event
          },
          "update:show-limit": function ($event) {
            _vm.showLimit = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ana-header" }, [
      _c("span", [
        _c("span", [_vm._v("系统匹配最相似")]),
        _c("span", { staticClass: "bigBlue" }, [_vm._v("案由")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }