var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "law-item-comp" }, [
    _c("div", { staticClass: "law-card" }, [
      _c("div", { staticClass: "law-card__cell" }, [
        _c(
          "div",
          {
            staticClass: "cell-checkbox",
            class: { active: _vm.hasChildSelect || _vm.meSelect },
            on: { click: _vm.select },
          },
          [_c("i", { class: _vm.iconString })]
        ),
        _c(
          "div",
          {
            staticClass: "cell-title van-ellipsis-3",
            on: { click: _vm.handleLawTitleClick },
          },
          [_vm._v(" " + _vm._s(_vm.card.name) + " ")]
        ),
        _c(
          "div",
          {
            staticClass: "cell-pr",
            on: {
              click: function ($event) {
                return _vm.jumpToList(1, "")
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm.card.percent) + " ")]
        ),
      ]),
      _vm.hasLawItem
        ? _c(
            "div",
            _vm._l(_vm.lawChildren, function (item, num) {
              return _c(
                "div",
                { key: num },
                [
                  _c("law-item", {
                    attrs: { law: item, index: num, parentIndex: _vm.index },
                    on: {
                      select: _vm.handleChildSelect,
                      toLawDetail: _vm.handleChildToLawDetail,
                      jumpToList: _vm.jumpToList,
                      more: _vm.more,
                    },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }