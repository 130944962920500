<template>
  <div
    class="law-item"
    v-show="
      (law.relevancyKey && law.show) ||
      (law.relevancyKey && law.i === 0) ||
      !law.relevancyKey
    "
  >
    <div class="law-item__cell">
      <div class="cell-checkbox" @touchend.stop="select(index, $event)">
        <check-box :value="law.checked" />
      </div>
      <div class="cell-title van-multi-ellipsis--l3">
        <!--<span v-show="law.showOldTag">旧 </span>-->
        <span
          @touchend.stop="jumpToLawDetail"
          :style="law.colorType ? 'color: rgb(255, 153, 0) ' : ''"
          >{{ law.title }}</span
        >
      </div>
      <div class="cell-pr" @touchend.stop="jumpToList">{{ law.percent }}</div>
    </div>
    <div :class="{ 'van-multi-ellipsis--l3': ellipsis }" @click="ellipsis = false">
      {{ law.text }}
    </div>
    <div
      class="name"
      style="color: rgb(255, 153, 0); margin-left: 10px; cursor: pointer"
      v-if="law.relevancyKey && law.i === 0 && law.len > 1 && !law.show"
      @click="more(law.relevancyKey, true)"
    >
      更多关联法条
    </div>
    <div
      class="name"
      style="color: rgb(255, 153, 0); margin-left: 10px; cursor: pointer"
      v-if="law.relevancyKey && law.i === law.len - 1 && law.len > 1 && law.show"
      @click="more(law.relevancyKey, false)"
    >
      收起关联法条
    </div>
  </div>
</template>

<script>
import CheckBox from './checkBox'
// import addition from './addition'
export default {
  name: 'law-item',
  props: {
    index: Number,
    law: Object,
    parentIndex: Number,
  },
  components: {
    CheckBox,
  },
  data() {
    return {
      ellipsis: true,
      show: false,
    }
  },
  computed: {},
  methods: {
    // lawShow() {
    //   this.$parent.handleLawTitleClick()
    // },
    more(relevancyKey, flag) {
      this.$emit('more', relevancyKey, flag)
    },
    selectChild(i, index, name, flag) {
      if (this.law[name][i].child[index].checked) {
        this.$set(this.law[name][i].child[index], 'checked', false)
      } else {
        this.$set(this.law[name][i].child[index], 'checked', true)
      }
      this.$emit('selectChild')
    },
    // 选中
    select(index) {
      const payload = {
        index,
        parentIndex: this.parentIndex,
        checked: !this.law.checked,
      }
      this.$emit('select', payload)
    },
    // 向上层传递事件 跳转到法律
    jumpToLawDetail() {
      if (this.law.colorType) {
        this.$emit('toLawDetail', {
          text: this.law.text,
          title: this.law.title2,
          id: this.law.law_id,
        })
      } else {
        this.$emit('toLawDetail', {
          text: this.law.text,
          title: this.law.title,
          id: this.law.law_id,
        })
      }
    },
    jumpToList() {
      this.$emit('jumpToList', 1, this.law.title)
    },
  },
  created() {},
  mounted() {},
}
</script>

<style lang="stylus" scoped>
& .law-item
  border-radius 10px
  margin 10px 0
  & .law-item__cell
    position relative
    display flex
    align-items center
    justify-content baseline
    margin-bottom 10px
    & .cell-title
      font-weight bold
      flex 1
      font-size 13px
      margin-right 4px
    & .cell-checkbox
      margin-right 6px
      & i
        font-size 14px
    & .cell-pr
      font-size 12px
</style>
