var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "twoDetail" },
    [
      _c(
        "div",
        {
          staticClass: "options",
          style: { top: _vm.optflag ? "0px" : "-100%" },
        },
        [
          _c("div", { staticClass: "special", on: { click: _vm.cancel } }, [
            _vm._v("取消(" + _vm._s(_vm.lengh) + ")"),
          ]),
          _c("div", { on: { click: _vm.save } }, [_vm._v("收藏")]),
          _c("div", { on: { click: _vm.download } }, [_vm._v("分享")]),
          !_vm.$route.query.isMiniApp
            ? _c("div", { on: { click: _vm.give } }, [_vm._v("插入")])
            : _vm._e(),
          !_vm.$route.query.isMiniApp
            ? _c("div", { on: { click: _vm.loadet } }, [_vm._v("下载")])
            : _vm._e(),
        ]
      ),
      _vm._l(_vm.dataList, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "info", attrs: { div: index } },
          [
            _vm._l(item.case, function (itemval, indexval) {
              return _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: item.showflag || indexval === 0,
                      expression: "item.showflag || indexval === 0",
                    },
                  ],
                  key: indexval,
                },
                [
                  _c("elCheckBox", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: indexval === 0,
                        expression: "indexval === 0",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        return _vm.chooseItem($event, item)
                      },
                    },
                    model: {
                      value: itemval.flag,
                      callback: function ($$v) {
                        _vm.$set(itemval, "flag", $$v)
                      },
                      expression: "itemval.flag",
                    },
                  }),
                  _vm._v("   "),
                  _c(
                    "div",
                    {
                      staticClass: "information",
                      on: {
                        click: function ($event) {
                          return _vm.toDetail(itemval.text, itemval.doc_id)
                        },
                      },
                    },
                    [
                      _c("b", [_vm._v(_vm._s(itemval.text))]),
                      _c("div", { staticClass: "focus-tit-div" }, [
                        _c("span", { staticClass: "focus-tit-caseid" }, [
                          _vm._v(_vm._s(itemval.case_id) + " "),
                        ]),
                        _c("span", { staticClass: "focus-tit-casename" }, [
                          _vm._v(" " + _vm._s(itemval.case_name)),
                        ]),
                      ]),
                      _c("div", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.showflag,
                            expression: "item.showflag",
                          },
                        ],
                        staticClass: "line",
                      }),
                    ]
                  ),
                ],
                1
              )
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: item.loadingMore,
                    expression: "item.loadingMore",
                  },
                ],
                staticClass: "loaing-panel",
              },
              [_c("van-loading")],
              1
            ),
            _c("div", { staticClass: "show" }, [
              _c("div", { staticClass: "focus-tit-div" }, [
                _c("span", { staticClass: "focus-tit-caseid" }, [
                  _vm._v(_vm._s(item.case_id) + " "),
                ]),
                _c("span", { staticClass: "focus-tit-casename" }, [
                  _vm._v(" " + _vm._s(item.case_name)),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "percet", staticStyle: { float: "left" } },
                [
                  _c("i", { staticClass: "iconfont icon-fenxi" }),
                  _vm._v(" " + _vm._s(item.percent) + " "),
                ]
              ),
              (Array.isArray(item.case) ? item.case.length > 0 : false)
                ? _c(
                    "span",
                    {
                      staticStyle: { float: "right" },
                      on: {
                        click: function ($event) {
                          return _vm.showmore(item, index)
                        },
                      },
                    },
                    [_vm._v(_vm._s(item.showflag ? "收起" : "查看更多"))]
                  )
                : _vm._e(),
            ]),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !item.showflag,
                  expression: "!item.showflag",
                },
              ],
              staticClass: "line",
            }),
          ],
          2
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }