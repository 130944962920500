var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "lawyer" },
    _vm._l(_vm.renderArray, function (item, index) {
      return _c("div", { key: index, staticClass: "border-bottom cell" }, [
        _c("span", [_vm._v(_vm._s(item.name))]),
        _c("span", [_vm._v(_vm._s(item.num))]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }