<template>
  <div class="focus">
    <treeSelect
      v-model="TreeSelect"
      v-if="Object.keys(TreeSelect.data).length > 0"
      :keywords="[]"
      parent="ana"
      @search="pushFilterCondition"
    ></treeSelect>
    <!-- 头部插槽 -->
    <fb-header slot="header" title="选择争议焦点" fixed>
      <fb-button slot="left" icon="back" @click="back" size="small" type="primary">
        返回
      </fb-button>
      <div slot="right">
        <van-button
          type="info"
          :disabled="selectList.length > 0 ? false : true"
          size="mini"
          @click="openFile"
          >下一步编写代理词</van-button
        >
      </div>
    </fb-header>
    <div>
      <div class="focus-header">
        <div style="display: flex; align-items: center">
          <div class="cell-checkbox">
            <i
              v-if="selectList.length > 0 && selectList.length < len"
              class="iconfont icon-minus"
              @click="checkedAll(false)"
            ></i>
            <i
              v-if="selectList.length > 0 && selectList.length === len"
              class="iconfont icon-check-2"
              @click="checkedAll(false)"
            ></i>
            <span
              @click="checkedAll(true)"
              class="span"
              v-if="selectList.length === 0"
            ></span>
          </div>
          <span style="margin-left: 10px">已选{{ selectList.length }}</span>
        </div>
        <div style="display: flex; align-items: center">
          <div class="keyword-input-box">
            <i class="van-icon van-icon-search"></i>
            <input
              type="text"
              v-model="keyword"
              @input="keywordChange"
              placeholder="搜索"
            />
          </div>
          <i class="iconfont icon-shaixuan21" @click="showTreeSelect"></i>
        </div>
      </div>
      <div class="focus-body">
        <div class="focus-list">
          <div v-for="(item, index) in list" :key="index">
            <div
              class="focus-box"
              v-for="(childItem, i) in item.case"
              v-show="childItem.show"
              :key="i"
            >
              <div class="focus-box-checkbox">
                <van-checkbox
                  shape="square"
                  @click="chooseItem(index, i)"
                  v-model="childItem.flag"
                ></van-checkbox>
              </div>
              <div class="focus-box-content">
                <div class="focus-box-content_ellipsis" @click="openAll(index, i)">
                  {{ childItem.text }}
                </div>
                <div class="focus-box-content_all" v-show="childItem.isAll">
                  {{ childItem.text }}
                  <span
                    @click="closeAll(index, i)"
                    style="margin-left: 10px; color: #1989fa"
                    >收起</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import treeSelect from '@/pages/Search/compoments/treeSelect.vue'
import { mapMutations } from 'vuex'
export default {
  name: 'focus',
  components: {
    treeSelect,
  },
  props: [
    'summary',
    'sentence',
    'input_keyword',
    'condition',
    'documentObj',
    'currentReportArr',
  ],
  data() {
    return {
      list: [],
      keyword: '',
      selectList: [],
      len: 0,
      conditions: [],
      TreeSelect: {
        flag: false,
        data: {},
        title: '',
      },
    }
  },
  computed: {},
  methods: {
    ...mapMutations('ana', ['SET_FOCUS_TEXT']),
    // 关闭遮罩层
    back() {
      this.$emit('back')
    },
    // 点击复选框
    chooseItem(index, i) {
      // this.list[index].case[i].flag = !this.list[index].case[i].flag
      this.list = this.list.slice(0)
      this.getSelectList()
    },
    // 获取选中的数据
    getSelectList() {
      this.selectList = []
      this.list.forEach((item) => {
        item.case.forEach((itemChild) => {
          if (itemChild.flag && itemChild.show) {
            this.selectList.push(itemChild.text)
          }
        })
      })
    },
    // 获取争议焦点列表
    getList(condition) {
      const data = {
        summary: this.summary, // 案由名称
        sentence: this.sentence, // 输入框文字
        input_keyword: this.input_keyword, // 用户输入的关键词
        key: 'focus', // 分类的key
        condition: condition, //  筛选条件
        offset: '1', // 页码
      }
      this.$axios
        .post(`${this.$pydataBase}/api/ai/semantic/category`, data)
        .then((res) => {
          if (res.data.code === 200) {
            this.list = res.data.result.datas
            let len = 0
            this.list.forEach((item) => {
              item.case.forEach((itemChild) => {
                itemChild.flag = false
                itemChild.isAll = false
                if (itemChild.text.indexOf(this.keyword) === -1) {
                  itemChild.show = false
                } else {
                  itemChild.show = true
                  len++
                }
              })
            })
            this.len = len
            this.getSelectList()
          }
        })
      this.$axios.post(`${this.$pydataBase}/api/ai/semanticTree`, data).then((res) => {
        this.conditions = res.data.result
      })
    },
    // 显示详情
    openAll(index, i) {
      this.list[index].case[i].isAll = true
      this.list = this.list.slice(0)
    },
    closeAll(index, i) {
      this.list[index].case[i].isAll = false
      this.list = this.list.slice(0)
    },
    // 全选
    checkedAll(flag) {
      this.list.forEach((item) => {
        item.case.forEach((iter) => {
          iter.flag = flag
        })
      })
      this.list = this.list.slice(0)
      this.getSelectList()
    },
    // 关键词改变的时候，做一个本地检索
    keywordChange() {
      let len = 0
      this.list.forEach((item) => {
        item.case.forEach((itemChild) => {
          if (itemChild.text.indexOf(this.keyword) === -1) {
            itemChild.show = false
          } else {
            itemChild.show = true
            len++
          }
        })
      })
      this.len = len
      this.getSelectList()
    },
    // 打开筛选
    showTreeSelect() {
      const title = []
      this.conditions.map((item) => {
        title.push(item.title)
        this.screenConditionDataFomt(item.data)
      })
      this.TreeSelect.flag = true
      this.TreeSelect.data = this.conditions
      this.TreeSelect.title = title
    },
    screenConditionDataFomt(data) {
      data.map((item) => {
        item.label = item.value
        if (item.child && item.child.length > 0) {
          this.screenConditionDataFomt(item.child)
        }
      })
    },
    // 重新检索
    pushFilterCondition(payload) {
      var list = []
      payload.map((item) => {
        list.push({
          key: item.key,
          value: item.value,
        })
      })
      this.getList(list)
    },
    // 点击编写代理词，打开文书
    openFile() {
      this.$axios
        .get(
          `${this.$base}/lts/case/defaultCreateCase?documentId=${this.documentObj.documentId}`
        )
        .then((res) => {
          if (res.data.code === '200') {
            const caseId = res.data.data
            const text = this.selectList.toString()
            this.SET_FOCUS_TEXT(text)
            const path = `/editor/${caseId}?caseDocumentId=${this.documentObj.documentId}&fromType=NOCASE&fromAna=true&focus=true`
            this.$router.push({ path })
          }
        })
    },
  },
  mounted() {},
  created() {
    this.getList(this.condition)
  },
  watch: {},
}
</script>
<style lang="stylus" scoped>
.focus
  position: relative
  width 100%
  height 100%
  padding-top 44px
  box-sizing border-box
  overflow auto
  & .focus-header
    padding 10px
    box-sizing border-box
    display flex
    align-items center
    justify-content space-between
    & .keyword-input-box
      display flex
      background #eee
      align-items center
      padding 5px 10px
      border-radius 20px
      & i
        color #cccccc
        font-size 14px
        margin-right 10px
      & input
        border none
        background #eee
        width 100px
    & .icon-shaixuan21
      margin-left 10px
      color #cccccc
    & .cell-checkbox
      background #ffffff
      border-radius 2px
      border 1px solid #c8c9cc
      color #ffffff
      width 18px
      height 18px
      display inline-block
      text-align center
      & .iconfont
        background #409eff
        width: 100%;
        height: 100%;
        display: inline-block;
        font-size 14px
      & .span
        width: 100%;
        height: 100%;
        display: inline-block;
  & .focus-body
    padding 10px
    box-sizing border-box
    & .focus-list
      & .focus-box
        display flex
        border-bottom 1px solid #999999
        padding 10px 0
        box-sizing border-box
        & .focus-box-content
          flex 1
          margin-left 10px
          & .focus-box-content_ellipsis
            font-size 14px
            display -webkit-box
            -webkit-box-orient vertical
            -webkit-line-clamp 1
            overflow hidden
          & .focus-box-content_all
            font-size 12px
            color #cccccc
</style>
