<template>
  <span class="ana-checkbox" :class="{ active: value }">
    <i class="iconfont icon-check-2"></i>
  </span>
</template>

<script>
export default {
  name: 'check-box',
  props: {
    value: Boolean
  },
  computed: {}
}
</script>

<style lang="stylus">
.ana-checkbox
  background #ffffff
  border-radius 2px
  border 1px solid #eeeeee
  color #ffffff
  width 18px
  height 18px
  display inline-block
  text-align center
  &.active
    background #409eff
    border 1px solid #409eff
  & .iconfont
    padding 0
    font-size 14px
</style>
