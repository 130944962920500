var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "document-goods" },
    [
      _c(
        "fb-header",
        { attrs: { slot: "header", fixed: "" }, slot: "header" },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: { click: _vm.close },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
        ],
        1
      ),
      _c("div", [
        _c("div", { staticClass: "fb-document-header" }, [
          _c("div", { staticClass: "member" }, [
            _c("i"),
            _c("span", { staticStyle: { color: "#666666" } }, [
              _vm._v("开通年卡VIp每年可省"),
            ]),
            _c("span", { staticStyle: { color: "#ed3f3e" } }, [
              _vm._v("631.4元"),
            ]),
            _c(
              "span",
              {
                staticClass: "member-open",
                staticStyle: { color: "#a67a13" },
                on: { click: _vm.showMemberDialog },
              },
              [_vm._v("立即开通")]
            ),
          ]),
          _c("div", { staticClass: "fb-document-header-title" }, [
            _vm._v("智能起草法律文书"),
          ]),
        ]),
        _c("div", { staticClass: "fb-document-body" }, [
          _c(
            "div",
            { staticClass: "document-list" },
            _vm._l(_vm.list, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "document-box",
                  on: {
                    click: function ($event) {
                      return _vm.openPay(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "document-info" }, [
                    _c("div", { staticClass: "document-info-title" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                    _c("div", { staticClass: "document-info-content" }, [
                      _vm._v(" " + _vm._s(item.remarks) + " "),
                    ]),
                  ]),
                  _c("div", { staticClass: "document-price" }, [
                    _c("div", [
                      _c("span", { staticClass: "document-price-real" }, [
                        _vm._v("¥" + _vm._s(item.realPrice)),
                      ]),
                      _vm._v("/" + _vm._s(item.unit) + " "),
                    ]),
                    _c("span", [_vm._v("(会员免费)")]),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }