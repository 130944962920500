var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "ana-checkbox", class: { active: _vm.value } },
    [_c("i", { staticClass: "iconfont icon-check-2" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }