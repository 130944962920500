var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "analysis",
      style: _vm.inScrollMenu ? "padding-top:0px" : "",
    },
    [
      !_vm.inScrollMenu && !_vm.isIOS
        ? _c("div", { staticClass: "back" }, [
            _c("i", {
              staticClass: "iconfont icon-back",
              on: { click: _vm.backToSearch },
            }),
          ])
        : _vm._e(),
      !_vm.inScrollMenu
        ? _c(
            "div",
            {
              ref: "header",
              staticClass: "ana-header",
              attrs: { id: "header" },
            },
            [
              _c(
                "div",
                { staticClass: "text-caontainer" },
                [
                  _c("van-field", {
                    ref: "focusTextarea",
                    staticClass: "text",
                    staticStyle: { height: "100px" },
                    attrs: {
                      id: "textareaId",
                      placeholder:
                        "请详细描述你的基本案情，可以使用完整的句子，也可以使用一个或多个词语。",
                      type: "textarea",
                      autosize: "",
                      border: false,
                    },
                    model: {
                      value: _vm.analysisData.textData,
                      callback: function ($$v) {
                        _vm.$set(_vm.analysisData, "textData", $$v)
                      },
                      expression: "analysisData.textData",
                    },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "xiangji",
                      staticStyle: { "text-align": "right" },
                    },
                    [
                      _c("span", { staticClass: "numTotal" }, [
                        _vm._v(_vm._s(_vm.analysisData.textData.length) + "字"),
                      ]),
                      _c(
                        "van-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.analysisData.textData.trim().length > 0,
                              expression:
                                "analysisData.textData.trim().length > 0",
                            },
                          ],
                          staticClass: "buttonDefault",
                          attrs: { size: "mini", type: "default" },
                          on: { click: _vm.emptyData },
                        },
                        [_vm._v("清空")]
                      ),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.analysisData.textData.trim(),
                            expression: "!analysisData.textData.trim()",
                          },
                        ],
                        staticClass: "iconfont icon-xiangji1",
                        on: { click: _vm.openCaseCamera },
                      }),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.analysisData.textData.trim(),
                            expression: "!analysisData.textData.trim()",
                          },
                        ],
                        staticClass: "iconfont icon-wordpdf",
                        staticStyle: { "margin-left": "10px" },
                        on: { click: _vm.openFile },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "button-container" },
                [
                  _c(
                    "van-button",
                    {
                      staticClass: "analysis-button",
                      attrs: { disabled: _vm.loading, type: "default" },
                      on: { click: _vm.texAanalysisTwo },
                    },
                    [
                      _vm._v("案情分析机器人"),
                      _vm.userInfo &&
                      _vm.userInfo.memberStatus !== 1 &&
                      _vm.memberNum !== -1 &&
                      _vm.isWechat == false
                        ? _c("span", [
                            _vm._v(
                              "（剩余" + _vm._s(_vm.memberNum || 0) + "次）"
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      !_vm.isAnalysisIng
        ? _c("div", [
            _c(
              "div",
              { staticClass: "history_title" },
              [
                _vm._v("历史记录 "),
                _c("van-icon", {
                  attrs: { name: "delete-o" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteTheme(true, "")
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "van-list",
                  _vm._l(_vm.themeList, function (item, index) {
                    return _c("van-cell", {
                      key: index,
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _c("span", { staticClass: "custom-title" }, [
                                  _vm._v(_vm._s(item.date)),
                                ]),
                                _c("van-icon", {
                                  attrs: { name: "delete-o" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteTheme(
                                        false,
                                        item.text_token
                                      )
                                    },
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "right-icon",
                            fn: function () {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "re_analysis",
                                    on: {
                                      click: function ($event) {
                                        return _vm.reAnalysis(item.text)
                                      },
                                    },
                                  },
                                  [_vm._v("重新分析")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "label",
                            fn: function () {
                              return [_vm._v(" " + _vm._s(item.text) + " ")]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  }),
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.showRefresh
        ? _c(
            "div",
            {
              staticStyle: { "text-align": "center" },
              on: { click: _vm.texAanalysisTwo },
            },
            [
              _vm._v("加载超时，"),
              _c("span", { staticStyle: { color: "#409eff" } }, [
                _vm._v("点击刷新"),
              ]),
              _vm._v("。"),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          ref: "container",
          staticClass: "container",
          staticStyle: { position: "relative" },
        },
        [
          false ? _c("fb-member", { attrs: { left: -15 } }) : _vm._e(),
          _c("one", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.step === 1,
                expression: "step === 1",
              },
            ],
            ref: "one",
            attrs: {
              memberNum: _vm.memberNum,
              analysisData: _vm.analysisData,
              showIndex: _vm.oneShowIndex,
              pageInfo: _vm.pageInfo,
              token: _vm.analysisData.token,
              inScrollMenu: _vm.inScrollMenu,
              scrollDocument: _vm.scrollDocument,
              is_type: _vm.is_type,
              isWechat: _vm.isWechat,
            },
            on: {
              "update:showIndex": function ($event) {
                _vm.oneShowIndex = $event
              },
              "update:show-index": function ($event) {
                _vm.oneShowIndex = $event
              },
              caseCellClick: _vm.caseCellClick,
              emptyData: _vm.emptyData,
              resetFinishedText: _vm.resetFinishedText,
              showHeader: _vm.showHeader,
              changeIsType: _vm.changeIsType,
            },
          }),
          _vm.step === 2
            ? _c("two", {
                ref: "two",
                attrs: {
                  is_type: _vm.is_type,
                  analysisData: _vm.analysisData,
                  selectedIndex: _vm.oneSelectedIndex,
                  tabData: _vm.analysisData.tabData,
                  updataTitleIndex: _vm.updataTitleIndex,
                  showIndex: _vm.twoShowIndex,
                  pageInfo: _vm.pageInfo,
                  inScrollMenu: _vm.inScrollMenu,
                  scrollDocument: _vm.scrollDocument,
                  causeName: _vm.causeName,
                  fileId: _vm.fileId,
                  memberNum: _vm.memberNum,
                },
                on: {
                  "update:selectedIndex": function ($event) {
                    _vm.oneSelectedIndex = $event
                  },
                  "update:selected-index": function ($event) {
                    _vm.oneSelectedIndex = $event
                  },
                  "update:showIndex": function ($event) {
                    _vm.twoShowIndex = $event
                  },
                  "update:show-index": function ($event) {
                    _vm.twoShowIndex = $event
                  },
                  resetScrollDocument: _vm.resetScrollDocument,
                  resetLoading: _vm.resetLoading,
                  resetFinished: function ($event) {
                    return _vm.$emit("resetFinished", false)
                  },
                  sliderSide: _vm.sliderSide,
                  showHeader: _vm.showHeader,
                  show: function ($event) {
                    _vm.isShow = true
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.userinfo.id
        ? _c("myWarp", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  _vm.isShow &&
                  _vm.userinfo &&
                  _vm.userinfo.memberStatus !== 1 &&
                  _vm.memberNum < 0,
                expression:
                  "isShow && userinfo && userinfo.memberStatus !== 1 && memberNum < 0",
              },
            ],
            on: {
              closeDialog: function ($event) {
                _vm.isShow = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }