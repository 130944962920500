var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return Array.isArray(_vm.renderArray) &&
    _vm.renderArray[1] &&
    _vm.renderArray[2]
    ? _c("div", { staticClass: "court" }, [
        _c("div", { staticClass: "source" }, [
          _c(
            "label",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.flag.curts,
                  expression: "flag.curts",
                },
              ],
            },
            [
              _c(
                "div",
                { ref: "container", staticClass: "ctn" },
                [
                  _c("ve-ring", {
                    attrs: {
                      data: _vm.chartData,
                      settings: _vm.chartSettings,
                      tooltip: _vm.tooltip,
                      "legend-visible": false,
                      colors: ["#4B9EFB", "skyblue", "#2D43E8", "#2DC3E9"],
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "courts" },
                [
                  _vm._l(
                    _vm.flag.moreshow
                      ? _vm.renderArray[1].court
                      : _vm.renderArray[1].court.slice(0, 3),
                    function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "court_item" },
                        [
                          _c("span", [_vm._v(_vm._s(item.num))]),
                          _c("span", [_vm._v(_vm._s(item.name))]),
                        ]
                      )
                    }
                  ),
                  _vm.renderArray[1].court.length > 3
                    ? _c(
                        "div",
                        {
                          staticClass: "more",
                          on: {
                            click: function ($event) {
                              _vm.flag.moreshow = !_vm.flag.moreshow
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.flag.moreshow ? "收起" : "查看更多")
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]
          ),
        ]),
        _c("div", { staticClass: "source" }, [
          _vm._m(0),
          _c(
            "label",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.flag.curts,
                  expression: "flag.curts",
                },
              ],
            },
            [
              _c(
                "div",
                { staticClass: "courts faguan" },
                [
                  _vm._l(
                    _vm.flag.faguanflag
                      ? _vm.renderArray[2].judge
                      : _vm.renderArray[2].judge.slice(0, 3),
                    function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "court_item" },
                        [
                          _c("span", [_vm._v(_vm._s(item.num))]),
                          _c("span", [_vm._v(_vm._s(item.judge))]),
                          _c("span", [_vm._v(_vm._s(item.name))]),
                        ]
                      )
                    }
                  ),
                  _vm.renderArray[1].court.length > 3
                    ? _c(
                        "div",
                        {
                          staticClass: "more",
                          on: {
                            click: function ($event) {
                              _vm.flag.faguanflag = !_vm.flag.faguanflag
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.flag.faguanflag ? "收起" : "查看更多")
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "team" }, [
      _c("span", { staticStyle: { "font-weight": "700" } }, [
        _vm._v("承办法官"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }