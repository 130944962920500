<template>
  <div class="one" ref="one">
    <div class="ana-header">
      <span>
        <span>系统匹配最相似</span>
        <span class="bigBlue">案由</span>
      </span>
      <!-- <span class="topCase"
            @click="topCase">查看前100相似案列</span> -->
    </div>
    <div class="container" :class="{ paddingAdd: inScrollMenu }">
      <div v-if="analysisData.caseList && analysisData.caseList.length">
        <div v-for="(item, index) in calcArray(analysisData.caseList)" :key="'singlePie' + index">
          <single-pie :memberNum="memberNum" :option="item" :index="index" @caseCellClick="caseCellClick" />
        </div>
      </div>
      <div class="container-no" v-if="!isWechat">
        <!-- 暂未匹配到相似判例，请修改内容后重试。 -->
        <div v-if="!analysisData.caseList || !analysisData.caseList.length" style="margin-bottom: 14px">暂未匹配到相关内容,请调整输入内容或者切换案件类型</div>
        <div v-else style="text-align: left; margin-bottom: 14px">以上都不是，找其他类型案件</div>
        <div class="ana-case-type">
          <div class="ana-case-type-list">
            <div class="ana-case-type-name" v-for="item in typeOptions" :key="item.value" :style="`background:${item.background}`" v-show="item.value !== is_type" @click="changeTypeValue(item)">
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <fb-nonmember :showLimit.sync="showLimit" />
  </div>
</template>
<script>
/* eslint-disable */

import { mapMutations, mapState } from 'vuex'
import singlePie from '../components/singlePie'

export default {
  data() {
    return {
      showLimit: false, // 是否显示团购订单二维码
      freeze: false, // 是否有团购订单
      typeOptions: [
        {
          value: 0,
          label: '民事',
          background: '#3399FF',
        },
        {
          value: 1,
          label: '刑事',
          background: '#FF6633',
        },
        {
          value: 2,
          label: '行政',
          background: '#16DEEC',
        },
        {
          value: 4,
          label: '赔偿',
          background: '#F52F2D',
        },
      ],
    }
  },
  name: 'one',
  props: ['analysisData', 'showIndex', 'pageInfo', 'token', 'inScrollMenu', 'scrollDocument', 'emptyData', 'is_type', 'memberNum', 'isWechat'],
  components: {
    singlePie,
  },
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapState('nonmemberLimit', ['pid', 'hasGetGroupinfo']),
  },
  methods: {
    ...mapMutations('ana', ['SET_SUMMARY']),
    ...mapMutations('nonmemberLimit', ['SET_GROUP_INFO']),
    // 获取当前用户团购情况
    async getGroupInfo() {
      try {
        this.SET_GROUP_INFO(null)
        const res = await this.$axios.post(`${this.$base}/management/order/queryGroupOrder`)
        if (res.data && res.data.code === 200) {
          if (res.data.data) {
            this.freeze = true
            this.SET_GROUP_INFO(res.data.data)
          } else {
            this.freeze = false
          }
        } else {
          this.freeze = false
        }
      } catch (err) {
        console.log(err)
      }
    },
    // 点击查看更多
    async viewMore() {
      if (this.showIndex < this.analysisData.caseList.length) {
        this.$emit('update:showIndex', this.showIndex + 4)
        return true
      } else {
        return false
      }
    },
    // 点击任意一个案列
    caseCellClick(index) {
      // 点击"案情分析机器人"时更新的用户信息
      // if (this.userInfo && this.userInfo.memberStatus !== 1 && this.memberNum === -1) {
      //   if (this.freeze) {
      //     this.showLimit = true
      //   } else {
      //     // 直接跳转到原生拼团页面
      //     const ua = navigator.userAgent
      //     if (ua === this.$ua.a) {
      //       // eslint-disable-next-line
      //       AND2JS.toVipRechargeView()
      //     } else {
      //       this.$notify('该设备暂不支持此功能')
      //     }
      //   }
      //   return
      // }
      this.$emit('caseCellClick', index)
    },
    // 查看前100相似案列
    topCase() {
      this.pageInfo.page = 'topCase'
      this.pageInfo.token = this.token
      this.$emit('resetFinishedText', '')
    },
    calcArray(array) {
      return array.filter((element, index) => {
        return index < this.showIndex
      })
    },
    // 改变案件类型
    changeTypeValue(item) {
      this.$emit('changeIsType', item.value)
    },
  },
  async mounted() {
    await this.getGroupInfo()
  },
  watch: {
    analysisData: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val.textData === '(null)') {
          this.$emit('emptyData')
        }
        if (this.$store.state.ana.summary && val.caseList.length > 0) {
          this.caseCellClick(
            this.analysisData.caseList.findIndex((item) => {
              if (item.option) {
                return item.option.title.text === this.$store.state.ana.summary
              }
            })
          )
          this.SET_SUMMARY('')
        }
      },
    },
  },
}
</script>
<style scoped lang="stylus">
.one
  padding-top 15px
  .ana-header
    display flex
    justify-content space-between
    align-items center
    .topCase
      color #999999
    .bigBlue
      color #3EB3F0
      // font-size 1.4em
  .container
    padding-top 10px
    .bottom
      text-align right
    & .container-no
      width 100%
      text-align center
      margin-top 40px
  .paddingAdd
    padding-right 10px
    padding-left 10px
.ana-case-type {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
}
.ana-case-type-list {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 20px;
}
.ana-case-type-name {
  width: 31%;
  height: 80px;
  line-height: 80px;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  margin-right: 1%;
  cursor: pointer;
  margin-bottom: 10px;
}
</style>
