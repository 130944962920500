var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "fb-document" },
    [
      _vm.type === 0
        ? _c("document-goods", {
            on: {
              openFocus: _vm.openFocus,
              openPay: _vm.openPay,
              close: _vm.close,
            },
          })
        : _c("focus", {
            attrs: {
              summary: _vm.summary,
              sentence: _vm.sentence,
              input_keyword: _vm.input_keyword,
              condition: _vm.condition || [],
              documentObj: _vm.documentObj,
            },
            on: { back: _vm.back },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }