var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "appeal", on: { touchend: _vm.selectTextHandle } },
    [
      _c(
        "div",
        {
          staticClass: "options",
          style: { top: _vm.optflag ? "0px" : "-100%" },
        },
        [
          _c("div", { staticClass: "special", on: { click: _vm.cancel } }, [
            _vm._v("取消(" + _vm._s(_vm.length) + ")"),
          ]),
          _c("div", { on: { click: _vm.save } }, [_vm._v("收藏")]),
          _c("div", { on: { click: _vm.download } }, [_vm._v("分享")]),
          !_vm.$route.query.isMiniApp
            ? _c("div", { on: { click: _vm.give } }, [_vm._v("插入")])
            : _vm._e(),
          !_vm.$route.query.isMiniApp
            ? _c("div", { on: { click: _vm.loadet } }, [_vm._v("下载")])
            : _vm._e(),
        ]
      ),
      _vm.selectList.length
        ? _c(
            "label",
            _vm._l(_vm.selectList, function (item, index) {
              return _c(
                "div",
                {
                  key: "appeal" + index,
                  staticClass: "cell",
                  on: {
                    click: function ($event) {
                      return _vm.yuanwen(item.doc_id)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "top" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "box",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                            },
                          },
                        },
                        [
                          _c("el-checkBox", {
                            on: {
                              change: function ($event) {
                                return _vm.chooseItem($event, item)
                              },
                            },
                            model: {
                              value: item.flag,
                              callback: function ($$v) {
                                _vm.$set(item, "flag", $$v)
                              },
                              expression: "item.flag",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._l(item.data, function (iter, num) {
                        return _c(
                          "div",
                          {
                            key: num,
                            staticClass: "middle",
                            class:
                              iter.data && iter.data.length == 0 ? "empty" : "",
                          },
                          [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: iter.data && iter.data.length,
                                    expression: "iter.data && iter.data.length",
                                  },
                                ],
                              },
                              [_vm._v(_vm._s(iter.title))]
                            ),
                            _c(
                              "div",
                              { staticClass: "content" },
                              _vm._l(iter.data, function (message, index) {
                                return _c("p", { key: "left" + index }, [
                                  _vm._v(" " + _vm._s(message) + " "),
                                ])
                              }),
                              0
                            ),
                          ]
                        )
                      }),
                      _c("div", { staticClass: "deem-tit-div" }, [
                        _c("span", { staticClass: "deem-tit-casename" }, [
                          _vm._v(_vm._s(item.case_name)),
                        ]),
                        _c("span", { staticClass: "deem-tit-caseid" }, [
                          _vm._v(_vm._s(item.case_id)),
                        ]),
                      ]),
                    ],
                    2
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      !_vm.selectList.length
        ? _c(
            "div",
            {
              staticClass: "no_list",
              staticStyle: { visibility: "hidden" },
              attrs: { "data-fx": "fx" },
              on: { click: _vm.jump },
            },
            [_vm._v("为了获得更好的分析效果，请完善案情。")]
          )
        : _vm._e(),
      _c("div", { staticClass: "circular" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }