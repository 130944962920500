var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "two" },
    [
      Object.keys(_vm.TreeSelect.data).length > 0
        ? _c("treeSelect", {
            attrs: { keywords: _vm.save_keyword, parent: "ana" },
            on: { search: _vm.pushFilterCondition },
            model: {
              value: _vm.TreeSelect,
              callback: function ($$v) {
                _vm.TreeSelect = $$v
              },
              expression: "TreeSelect",
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "two" },
        [
          !_vm.inScrollMenu
            ? _c("div", { ref: "slider", staticClass: "first-tab" }, [
                _c(
                  "div",
                  { staticClass: "addCase-box" },
                  [
                    _c("van-icon", {
                      staticClass: "menuEllipsis",
                      attrs: {
                        name: "ellipsis",
                        size: "0.6rem",
                        color: "#CCCCCC",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.showDrawMenu = true
                        },
                      },
                    }),
                    _vm.showDrawMenu
                      ? _c("div", { staticClass: "drawMenu" }, [
                          _c(
                            "ul",
                            [
                              _c(
                                "li",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.commitDrawMene("qisu")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.is_type === 0
                                          ? "写起诉书"
                                          : "刑事辩护词"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                              _vm._l(_vm.drawMenuArray, function (item, index) {
                                return _c(
                                  "li",
                                  {
                                    key: index,
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.commitDrawMene(item.key)
                                      },
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(item.name) + " ")]
                                )
                              }),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c("div", { staticClass: "title-tab" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.analysisData.caseList[_vm.titleIndex].summary_name
                      ) +
                      " "
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "dot-container" },
                  _vm._l(_vm.analysisData.caseList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "dot-cell",
                        style: {
                          margin: `0 ${
                            _vm.analysisData.caseList.length <= 10 ? 7 : 3
                          }px`,
                        },
                      },
                      [
                        _c("span", {
                          staticClass: "dot",
                          class: { selectedDot: index === _vm.titleIndex },
                          style: {
                            background:
                              _vm.dotBackGroundIndex === index ? "#3399FF" : "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changeTitle(index)
                            },
                          },
                        }),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _c(
            "van-tabs",
            {
              staticClass: "touchEventStop setVanTabs",
              staticStyle: { position: "relative" },
              attrs: {
                swipeable: !_vm.inScrollMenu,
                color: "#3399ff ",
                "title-active-color": "#3399ff",
              },
              on: {
                change: _vm.tabChange,
                click: function ($event) {
                  return _vm.noVip(_vm.tabIndex)
                },
              },
              model: {
                value: _vm.tabIndex,
                callback: function ($$v) {
                  _vm.tabIndex = $$v
                },
                expression: "tabIndex",
              },
            },
            [
              _vm.userinfo.id &&
              _vm.userinfo.memberStatus !== 1 &&
              _vm.memberNum < 0
                ? _c("div", {
                    staticClass: "warp",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.noVip.apply(null, arguments)
                      },
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                { ref: "component", staticClass: "component" },
                _vm._l(_vm.tabData, function (item, index) {
                  return _c(
                    "van-tab",
                    {
                      key: "tab2" + index,
                      staticClass: "chart-pane",
                      attrs: { title: item.category },
                    },
                    [
                      _c(
                        "div",
                        {
                          ref: "scroll",
                          refInFor: true,
                          staticClass: "scroll",
                          on: { click: _vm.pull },
                        },
                        [
                          _c(
                            "keep-alive",
                            { attrs: { include: _vm.cacheArray } },
                            [
                              _c(_vm.precedentComponent, {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.results[item.key + "_results"],
                                    expression:
                                      "results[item.key + '_results']",
                                  },
                                ],
                                ref: "tabs",
                                refInFor: true,
                                tag: "component",
                                attrs: {
                                  viewMoreFlag: _vm.viewMoreFlag,
                                  selectedArray: _vm.results[
                                    item.key + "_results"
                                  ]
                                    ? _vm.results[item.key + "_results"]
                                    : [],
                                  currentKey: _vm.tabData[_vm.tabIndex].key,
                                  currentReportArr: _vm.currentReportArr,
                                  selfKey: item.key,
                                  moreParam: _vm.moreParam,
                                  summary: _vm.summary,
                                  token: _vm.analysisData.token,
                                  tabIndex: _vm.tabIndex,
                                  pageInfo: _vm.pageInfo,
                                  litigationdata:
                                    _vm.litigationdata[_vm.tabIndex],
                                },
                                on: {
                                  preDetail: _vm.preDetail,
                                  Source: _vm.SetSource,
                                  lawDetail: _vm.lawDetail,
                                  resetScroll: function ($event) {
                                    return _vm.$emit(
                                      "resetScrollDocument",
                                      false
                                    )
                                  },
                                  saveOptions: _vm.save,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.results[item.key + "_results"] &&
                                    _vm.semanticCategoryLoading === false &&
                                    _vm.semanticCategoryError === false,
                                  expression:
                                    "!results[item.key + '_results'] && semanticCategoryLoading === false && semanticCategoryError === false",
                                },
                              ],
                            },
                            [_vm._v("暂未统计到相应数据。")]
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_vm.results[item.key + "_results"] &&
                                    _vm.semanticCategoryLoading === false &&
                                    _vm.semanticCategoryError,
                                  expression:
                                    "!results[item.key + '_results'] && semanticCategoryLoading === false && semanticCategoryError",
                                },
                              ],
                            },
                            [_vm._v("请求超时，请稍后重试。")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                1
              ),
            ]
          ),
          _vm.loading === 0
            ? _c(
                "div",
                { staticClass: "loading" },
                [_c("span", [_vm._v("加载中")]), _c("van-loading")],
                1
              )
            : _vm.loading === 1
            ? _c("div", { staticClass: "loading" }, [_c("span")])
            : _vm.loading === 2
            ? _c("div", { staticClass: "loading" }, [
                _c("span", [_vm._v("没有更多了")]),
              ])
            : _vm._e(),
          _vm.precedentflag
            ? _c(
                "div",
                { staticClass: "detail_area" },
                [
                  _c("precedent-detail", {
                    attrs: {
                      id: _vm.precedentOptions.doc_id,
                      words: _vm.precedentOptions.words,
                      titleName: _vm.precedentOptions.titleName,
                      forword: _vm.precedentOptions.forword,
                      toZYJD: true,
                    },
                    on: { changeflag: _vm.CloseDetail },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.skyDrive
        ? _c(
            "div",
            { staticClass: "skyDrive" },
            [
              _c("skyDrive", {
                attrs: {
                  listid: _vm.arret,
                  Type: "LEGAL_PRECEDENT_FRAGMENT",
                  listname: 1,
                  summary: _vm.summary,
                  modulename: _vm.modulename,
                },
                on: { CancelCollect: _vm.close, getId: _vm.getId },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.preview
        ? _c(
            "div",
            { staticClass: "pre" },
            [
              _c("preview", {
                attrs: {
                  id: _vm.numid,
                  userId: _vm.$store.state.user.userInfo.id,
                },
                on: { clearpl: _vm.clearpl },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showReport
        ? _c(
            "div",
            { staticClass: "collect-report" },
            [
              _c("searchReport", {
                attrs: { from: "ana" },
                on: { close: _vm.closeReport },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "van-popup",
        {
          style: { height: "100%", width: "100%" },
          attrs: { position: "right" },
          model: {
            value: _vm.reportPopupVisible,
            callback: function ($$v) {
              _vm.reportPopupVisible = $$v
            },
            expression: "reportPopupVisible",
          },
        },
        [
          _c("ana-report", {
            attrs: { isType: _vm.is_type },
            on: {
              back: function ($event) {
                _vm.reportPopupVisible = false
              },
            },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          style: { height: "100%", width: "100%" },
          attrs: { position: "top" },
          model: {
            value: _vm.fbDocumentDialogVisible,
            callback: function ($$v) {
              _vm.fbDocumentDialogVisible = $$v
            },
            expression: "fbDocumentDialogVisible",
          },
        },
        [
          _c("fb-document", {
            attrs: {
              summary: _vm.summary,
              sentence: _vm.analysisData.textData,
              input_keyword: _vm.anaTwoKeyDown || [],
              condition: _vm.screenSelect || [],
            },
            on: {
              close: function ($event) {
                _vm.fbDocumentDialogVisible = false
              },
            },
          }),
        ],
        1
      ),
      _c(
        "van-popup",
        {
          style: { height: "100%" },
          attrs: { "get-container": "#outter", position: "right" },
          model: {
            value: _vm.lawDetailPopupVisible,
            callback: function ($$v) {
              _vm.lawDetailPopupVisible = $$v
            },
            expression: "lawDetailPopupVisible",
          },
        },
        [
          _c("law-detail", {
            attrs: { entry: _vm.lawDetailProp },
            on: { back: _vm.closeLawDetailPopup },
          }),
        ],
        1
      ),
      _vm.reportCount > 0
        ? _c(
            "div",
            { staticClass: "collect", on: { click: _vm.goCollect } },
            [
              _c("transition", { attrs: { name: "slide-fade" } }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showAdd,
                        expression: "showAdd",
                      },
                    ],
                    staticClass: "badge-box",
                  },
                  [_vm._v(" +1 ")]
                ),
              ]),
              _c("transition", { attrs: { name: "slide-fade" } }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showReduce,
                        expression: "showReduce",
                      },
                    ],
                    staticClass: "badge-box",
                  },
                  [_vm._v(" -1 ")]
                ),
              ]),
              _c("span", { staticClass: "collect-text" }, [_vm._v("集")]),
              _c("span", { staticClass: "count" }, [
                _vm._v(_vm._s(this.reportCount)),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }