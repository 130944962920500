<template>
  <div class="singlePie">
    <div class="container" @click="caseCellClick" ref="container">
      <div class="title">
        <span>
          {{ option.summary_name }}
        </span>
        <!-- <div class="generateCase"
             :style="{fontSize:generateCaseAxisSize}"
             @click.stop>一键生成按键轴</div> -->
      </div>
      <div class="ve-pie-hull">
        <!-- <div
          style="width:100%;height:100%;position:absolute;display:flex;align-items: center;
justify-content: center;"
        >
          <div
            class="ve-pie-hull-div"
            :style="
              `width: ${this.height * 0.65}px;
        height: ${this.height * 0.65}px;
        border-radius: ${(this.height * 0.65) / 2}px;
        font-size: ${this.width / 8}px`
            "
          >
            <div
              v-for="(item, i) in list"
              :key="i"
              :style="`color:${item.color}`"
            >
              {{ item.name }}:{{ item.proportion }}%
            </div>
          </div>
        </div> -->
        <ve-pie ref="vpPieDOm" :judge-width="true" :height="`${height}px`" :settings="chartSettings" :tooltip="tooltip" :legend="legend" :series="chartData" />
      </div>
    </div>
  </div>
</template>
<script>
import VePie from 'v-charts/lib/ring.common'
export default {
  name: 'singlePie',
  props: ['option', 'index'],
  components: {
    VePie,
  },
  computed: {
    chartData() {
      const a = {}
      a.columns = ['name', 'value']
      a.rows = []
      const array = []
      // if (this.option.series[0]) {
      //   array = this.option.series[0].data
      // } else {
      //   array = this.option.series.data
      // }
      this.option.datas.forEach((item, index) => {
        array.push({
          name: this.option.title[index],
          value: item,
        })
      })
      array.forEach((element) => {
        const b = {}
        b.name = element.name
        b.value = element.value
        a.rows.push(b)
      })
      const series = this.series
      series[0].data = a.rows
      return series
    },
  },
  data() {
    return {
      list: [],
      // 图表样式参数
      chartSettings: {
        radius: ['0%', '60%'],
        center: ['90%', '50%'],
        offsetY: 0,
        label: {
          fontSize: 10,
          formatter: '{d}%',
          // padding: [-15, -20, 0, 0]
        },
        labelLine: {
          smooth: true,
          length: 10,
          length2: 7,
        },
      },
      series: [
        {
          type: 'pie',
          radius: ['0%', '60%'],
          center: ['65%', '50%'],
          data: [],
          label: {
            fontSize: 10,
            formatter: '{d}%',
            // padding: [-15, -20, 0, 0]
          },
        },
      ],
      legend: {
        orient: 'vertical',
        left: 'left',
      },
      // 悬浮标签位置
      tooltip: {
        position: function (point, params, dom, rect, size) {
          if (point[0] < size.viewSize[0] / 2) {
            // 在左侧
            return [point[0] + 10, point[1] + 10]
          } else {
            // 在右侧
            return [point[0] - dom.clientWidth - 10, point[1] + 10]
          }
        },
      },
      height: '0',
      width: '0',
      clientWidth: 0,
      generateCaseAxisSize: '0',
    }
  },
  created() {
    let count = 0
    for (let i = 0; i < this.option.datas.length; i++) {
      count = count + this.option.datas[i]
    }
    for (let i = 0; i < this.option.title.length; i++) {
      var obj = {
        name: this.option.title[i],
        proportion: ((this.option.datas[i] / count) * 100).toFixed(2),
      }
      if (this.option.title[i] === '胜诉') {
        obj.color = '#19d4ae'
      } else if (this.option.title[i] === '败诉') {
        obj.color = '#5ab1ef'
      } else if (this.option.title[i] === '部分') {
        obj.color = '#fa6e86'
      } else {
        obj.color = '#19d4ae'
      }
      this.list.push(obj)
    }
  },
  methods: {
    getPosition() {
      // 获取容器宽度
      const width = this.width
      // 容器高度
      this.height = width + 90
      // 环形图表偏移量
      this.chartSettings.offsetY = width / 2 + 45
      // 图表上的字体大小
      this.chartSettings.label.fontSize = width / 8
      // 一键生成按键轴的字体大小
      this.generateCaseAxisSize = width / 9 + 'px'
    },
    // 点击任意一个案列
    caseCellClick() {
      this.$emit('caseCellClick', this.index)
    },
  },
  mounted() {
    // 会为零
    this.width = this.$refs.container.clientWidth / 3 || 240
    this.clientWidth = document.body.clientWidth || 240
    this.getPosition()
  },
}
</script>
<style scoped lang="stylus">
.singlePie
  position relative
  & .container
    box-shadow 0px 1px 8px 1px rgba(64, 180, 233, 0.23)
    border-radius 3px
    margin-bottom 10px
    & .title
      padding 10px 10px 0 10px
      font-size 15px
      display flex
      justify-content space-between
      align-items center
    & .ve-pie-hull
      position relative
      & .ve-pie-hull-div
        // position absolute
        // z-index 99998
        display flex
        flex-direction column
        justify-content center
        align-items center
        & > div
          font-size 18px
  & .generateCase
    color #569BFA
    border 1px solid #569BFA
    border-radius 5px
    padding 5px 15px
</style>
