<template>
  <div class="document-goods">
    <!-- 头部插槽 -->
    <fb-header slot="header" fixed>
      <fb-button slot="left" icon="back" @click="close" size="small" type="primary"> 返回 </fb-button>
    </fb-header>
    <div>
      <div class="fb-document-header">
        <div class="member">
          <i></i>
          <span style="color: #666666">开通年卡VIp每年可省</span>
          <span style="color: #ed3f3e">631.4元</span>
          <span style="color: #a67a13" class="member-open" @click="showMemberDialog">立即开通</span>
        </div>
        <div class="fb-document-header-title">智能起草法律文书</div>
      </div>
      <div class="fb-document-body">
        <div class="document-list">
          <div class="document-box" v-for="(item, index) in list" :key="index" @click="openPay(item)">
            <div class="document-info">
              <div class="document-info-title">{{ item.name }}</div>
              <div class="document-info-content">
                {{ item.remarks }}
              </div>
            </div>
            <div class="document-price">
              <div>
                <span class="document-price-real">¥{{ item.realPrice }}</span
                >/{{ item.unit }}
              </div>
              <span>(会员免费)</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'document-goods',
  props: [],
  data() {
    return {
      list: [],
    }
  },
  computed: {
    ...mapState('user', ['userInfo']),
  },
  methods: {
    // 关闭遮罩层
    close() {
      this.$emit('close')
    },
    // 获取商品列表
    getDoucumentList() {
      this.$axios.get(`${this.$base}/management/commodity?type=DOCUMENT`).then((res) => {
        if (res.data && res.data.code === 200) {
          this.list = res.data.data
        }
      })
    },
    // 点击打开会员弹框
    showMemberDialog() {
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.toVipRechargeView()
      } else if (ua === 'fb-flutter-Webview') {
        // eslint-disable-next-line
        console.log('ua...flutter')
        window.toVipRechargeView.postMessage('{}')
      }
    },
    // 点击打开付款
    async openPay(item) {
      if (this.userInfo && this.userInfo.memberStatus === 1) {
        // 如果当前用户是会员，直接去选择争议焦点
        // 先根据商品id查询文书id
        const res = await this.$axios.post(`${this.$base}/management/documentCommodity/queryByDocumentIdCommodityId.do`, { commodityId: item.id })
        if (res.data.code === 200) {
          const documentId = res.data.data.documentId
          this.$emit('openFocus', {
            documentId: documentId,
            groupId: res.data.data.documentGroupId,
          })
        }
      } else if (this.userInfo && this.userInfo.memberStatus !== 1) {
        // 如果当前用户不是会员，先查询有没有次数，没有次数去付款页面，有次数去选择争议焦点
        // 先根据商品id查询文书id
        const res = await this.$axios.post(`${this.$base}/management/documentCommodity/queryByDocumentIdCommodityId.do`, { commodityId: item.id })
        if (res.data.code === 200) {
          const documentId = res.data.data.documentId
          item.documentId = documentId
          item.groupId = res.data.data.documentGroupId
          // 在根据文书id查询有没有次数
          const data = await this.$axios.post(`${this.$base}/management/documentCommodity/queryBalanceCount.do`, { documentId: documentId })
          if (data.data.code === 200) {
            if (res.data.data.balanceCount > 0) {
              // 如果次数大于0，就去选择争议焦点
              this.$emit('openFocus', {
                documentId: documentId,
                groupId: res.data.data.documentGroupId,
              })
            } else {
              // 如果没有次数，就去付钱
              this.$emit('openPay', item)
            }
          }
        }
      }
    },
  },
  mounted() {},
  created() {
    this.getDoucumentList()
  },
}
</script>
<style lang="stylus" scoped>
.document-goods
  position relative
  width 100%
  height 100%
  padding-top 44px
  box-sizing border-box
  overflow auto
  & .fb-document-header
    padding 10px 10px
    box-sizing border-box
    & .fb-document-header-title
      font-size 18px
      font-weight: 600
      color: #333333
      margin-top 10px
    & .member
      background #FFF5DE
      height: 30px;
      display flex
      align-items center
      border-radius: 33px
      font-size 12px
      padding 0 20px
      & .member-open
        text-decoration-line underline
        margin-left 10px
        cursor: pointer;
  & .fb-document-body
    padding 0 0 40px 0
    & .document-list
      width: 100%;
      padding 10px 10px
      box-sizing border-box
      & .document-box
        width: 100%;
        display flex
        padding 10px 10px
        box-sizing border-box
        align-items center
        justify-content space-between
        background #F5F5F5
        border-radius 3px
        margin-bottom 10px
        cursor: pointer;
        & .document-info
          & .document-info-title
            color #333333
            font-size 14px
            font-weight 600
          & .document-info-content
            color #999999
            font-size 12px
        & .document-price
          font-size 12px
          color #999999
          display: flex
          flex-direction: column
          align-items: flex-end
          & .document-price-real
            color #ED3F3E
          & .document-price-free
            color #FF9A02
</style>
